import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../config/styles';

const TextLink = styled(Link)`
  color: ${colors.mainGreen};
`;

export default TextLink;
