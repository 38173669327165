import React, { useEffect, useState } from 'react';
import Image from '../../../../assets/images/image_computer.png';

const Tutorial = ({ setIsNextDisabled , nextStep}) => {
  const [umounted, setUmounted] = useState(false);

  const identifyPressKey = ({ key }) => {
    if(!umounted){
      const pressedKey = key.toUpperCase();
      document.removeEventListener('keydown', identifyPressKey);
      setIsNextDisabled(false);
      nextStep();
    }
  };


  useEffect(() => {
    document.addEventListener('keydown', identifyPressKey);
    
    setIsNextDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      setUmounted(true);
      document.removeEventListener('keydown', identifyPressKey);
    }
  }, []);

  return (
    <div className="game-instruction-content--with-image">
      <div className="game-instruction-text">
      Make sure you place your index and middle fingers on the C, V, N, and M keys like you practiced and exactly as shown below.
        <br />
        Press any of those keys to start the practice round.
      </div>
      <img src={Image} alt="instructions" className="instructions-image" />
    </div>
  )
};

export default Tutorial;
