import React from "react";
import "./style.css";
import whiteLogo from "../../assets/icons/logo-white.svg";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Header from "../../container/LandingPage/header";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import aboutusHero from "../../assets/images/landingpage/AboutUs-Hero.svg";
import titleImg from "../../assets/images/landingpage/AboutUs-title-design.svg";
import aboutUsCircle from "../../assets/images/landingpage/AboutUs-circle.svg";
import aboutUsdesign from "../../assets/images/landingpage/about-design.svg";
import { Link } from "react-router-dom";

const pages = [
  "Home",
  "About Us",
  "Service",
  "Insights",
  "Contact",
  "Partner with us",
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const CopyRightSection = () => {
  return (
    <div className="copyrightSection">
      <div>Copyright © Niji Corp.</div>

      <div className = "flex-grow"></div>

      <div className="allLinks">
          <div><Link to="/privacy-policy" style={{color :"white", textDecoration: "none"}} className="link">Privacy Policy</Link></div>
          <div><Link to="/terms-of-service" style={{color :"white", textDecoration: "none"}}  className="link" >Terms of Service</Link></div>
      </div>

    </div>
  );
};
export default CopyRightSection;
