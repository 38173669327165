import React, { useState, useEffect } from 'react';
import Game from '../Game';
import sequence from '../../practice-sequence.json';
import Flasher from '../Flasher';
import './style.css';

const gameConfig = {
  // gameColor: 'blue', // Background color
  // feedbackColor: 'yellow', // Box color
  roundsAmount: sequence.length,
  // triesAmount: 2,
  timeToRespond: 2000,
  // timeToRespond: 0,
  timeBetweenRound: 800, // Time between responses
  boxesAmount: 4, // Number of boxes
  letterHint: false,
  possibleKeys: ['C', 'V', 'N', 'M'],
  // letterHint: true, // If the letter is shown on the box
  letterPerBox: sequence,
};

const PracticeRound = ({ setIsNextDisabled, nextStep }) => {
  const [screenFlashed, setScreenFlashed] = useState(false);
  const [isPracticeFinished, setIsPracticeFinished] = useState(false);
  const [umounted, setUmounted] = useState(false);
  const flashScreen = () => {
    setScreenFlashed(true);
  };

  const endGame = () => {
    setIsNextDisabled(false);
    setIsPracticeFinished(true);
    // //click the next button : 
    // nextStep();
  };

  useEffect(() => {
    setIsNextDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      setUmounted(true);
    }
  }, []);
  return (
    <>
      <Game
        gameConfig={gameConfig}
        endGame={endGame}
        flashScreen={flashScreen}
        isPracticeRound={true}
      />
      {isPracticeFinished && (
        <p className="practice-round-text">
          You did great!
        </p>
      )}
      <Flasher
        messageDurationSeconds={1}
        screenFlashed={screenFlashed}
        setScreenFlashed={setScreenFlashed}
      />
    </>
  );
};

export default PracticeRound;
