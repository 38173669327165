import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { FaSignOutAlt , FaUserAlt} from 'react-icons/fa';

import './style.css';
import Loader from '../Loader';
import UserAvatar from '../../assets/images/girl-avatar.png';
import Logo from '../../assets/images/logo_v2.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
// import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heart-beat.svg';
import nijiLogo from '../../assets/images/Logo.png';
import api from '../../api';
import { persistor } from '../../redux';
import Feedback from '../../components/Feedback';

const Dashboard: React.FunctionComponent = () => {
  const history = useHistory();
  const passedGamesCount = useQuery('passedGamesCount', api.game.getPassedGamesCount);
  const quizResult = useQuery('quizResult', api.quiz.getQuizResults);
  const [userStatus , setUserStatus] = useState(true);
  const [show, setShow] = useState(true);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  if (passedGamesCount.status === 'loading' && quizResult.status === 'loading') {
    return <Loader image={nijiLogo} />;
  }

  const navigate = (url) => {
    history.push(url);
  };

  const logoutAction = () => {
    persistor.purge()
      .then(() => {
        window.location.reload();
      });
  };
  
  if(userStatus != profileQuery.data.data.isActive){
    setUserStatus(profileQuery.data.data.isActive);
  }
  
  return (<>
    <div className="dashboard-container">
      <div className="sidebar">
        <img alt="user" src={Logo} className="" style={{width : "70%"}}/>
        
        <div className="sidebar-menu">
          <UserIcon className={(userStatus)?"sidebar-menu-icon pointer": "sidebar-menu-icon disablePointer"} onClick={() => {if(userStatus)navigate('quiz')}} />
          <HeartIcon className={(userStatus)?"sidebar-menu-icon pointer": "sidebar-menu-icon disablePointer"} onClick={() => {if(userStatus)navigate('past-games')}} />
          {/* <SettingsIcon className="sidebar-menu-icon" /> */}
          <FaSignOutAlt className={(userStatus)?"sidebar-menu-icon sign-out pointer":"sidebar-menu-icon sign-out pointer"} onClick={() => {logoutAction()}} />
        </div>
      </div>

      <div className="dashboard-content" style={{ display :"flex", flexDirection :"column"}}>
      {(profileQuery.data.status == 200 && typeof(profileQuery.data.data) !== "undefined"  && profileQuery.data.data.isActive )?(<>
        <div>
            <h2 style={{"display":"flex","justifyContent":"center","flexDirection":"column"}}>
              <span style={{ textAlign :"center"}}>Welcome to Niji! </span>
              <br />
              <span>
                Please complete questions about your health and then perform a brain health activity.
              </span>
              </h2>
        </div>
        <div style={{"display":"flex","width":"100%","alignItems":"center","justifyContent":"center"}}>
            <div className="dashboard-card">
              <div className="dashboard-card-title">
                {(  typeof(quizResult) !== 'undefined'  && quizResult.status != 'loading' && typeof(quizResult.data) !== 'undefined' && typeof(quizResult.data.data) !== 'undefined' && quizResult.data.data?.results != null &&  Object.keys(quizResult.data.data?.results).length > 1)?(
                <>Add more information</>):(
                  <>Answer a few survey questions</>)}
              </div>
              {/* <div className="dashboard-card-subtitle">Lorem ipsum dolor amet</div> */}
              <div onClick={() => navigate('quiz')} className="link-button">
                {(  typeof(quizResult) !== 'undefined'  && quizResult.status != 'loading' && typeof(quizResult.data) !== 'undefined' && typeof(quizResult.data.data) !== 'undefined' &&  quizResult.data.data?.results != null && Object.keys(quizResult.data.data?.results).length > 1)?(
                <>Edit Questionnaire</>):(
                  <>Go to Questionnaire</>)}
              </div>
            </div>

            <div className="dashboard-card">
              <div className="dashboard-card-title">
                { passedGamesCount?.data?.data?.count  >= 2 && <div style={{ fontSize : "20px"}}>Congratulations on completing the brain game twice! </div>}
                {passedGamesCount?.data?.data?.count >= 1 && passedGamesCount?.data?.data?.count < 2 && 'You can play the game one more time. Start now!'}
                {passedGamesCount?.data?.data?.count === 0 && 'You can play the game two more times. Start now!'}
              </div>
              {/* <div className="dashboard-card-subtitle">Lorem ipsum dolor amet</div> */}
              <div onClick={() => navigate('game')} className={`${passedGamesCount?.data?.data?.count >= 2 ? 'link-button--disabled' : ''} link-button`}>
                Go to Game
              </div>
            </div>
            <Feedback profileQuery={profileQuery} show={show}/>
          
      </div></>):(<div>
                      Thank you for verifying your email. At this time a limited number of beta testers will be activated. We will notify you if your account is activated.
                  </div>)}
        </div>
    </div>
  </>
  );
};

export default Dashboard;
