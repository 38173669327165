import React, { FormEvent, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import { FaArrowLeft , FaArrowRight} from 'react-icons/fa';

import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { colors } from '../../config/styles';
import Card from '../../components/Card';
import Progress from '../../components/Progress';
import { H1, P } from '../../components/Typography';
import Spacer from '../../components/Spacer';
import Button from '../../components/Button';
import ButtonComponent from '../../components/Button';
import Modal from '../../components/Modal';
import { Redirect } from 'react-router-dom';
import check from '../../assets/icons/Checkmark.svg';
import { InputVariants, Question, QuestionVariants } from '../../typings/questions';
import Input from '../../components/Input';
import api from '../../api';
import Page from '../../components/Page';
import Header from '../../components/Header';
import { RootState } from '../../redux';
import UserActions from '../../redux/user/actions';
import Finish from './components/Finish';
import { BlockLike } from 'typescript';
import Stack from '@mui/material/Stack';
import ButtonMaterial from '@mui/material/Button';
import Feedback from '../../components/Feedback';

const Background = styled(Page)<{ finished?: boolean }>`
  background-color: ${colors.background};

  ${({ finished }) => (finished
    ? `
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 100px);
    overflow: hidden;
  `
    : '')}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
  height: 35px;
`;

interface HeaderCellProps {
  justify?: string;
}

const HeaderCell = styled.div<HeaderCellProps>`
  display: flex;
  flex: 1;
  ${({ justify }) => (justify ? `justify-content: ${justify};` : '')}
`;

const MetaContainer = styled(Spacer)``;

const BackButton = styled.button`
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }
  
  .back-button {
    font-size: 2.5rem;
  }
`;

const VariantCheck = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out;
  height: 31px;
  min-width: 31px;
  margin-left: 10px;
  border-radius: 17px;
  border: 2px solid #b0b0b2;

  img {
    width: 12px;
  }
`;

const Variant = styled.div<{ active: boolean }>`
  min-height: 84px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  ${({ active }) => (active
    ? `${VariantCheck} {
    background-color: ${colors.mainGreen};
    border-color: ${colors.mainGreen};
  }`
    : '')}

  ${P} {
    max-width: calc(100% - 46px);
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;

    ${VariantCheck} {
      background-color: ${colors.mainGreen};
      border-color: ${colors.mainGreen};
    }
  }
`;

const VariantsContainer = styled.div`
  margin-bottom: -30px;

  ${Variant}:last-child {
    border-bottom: none;
  }
`;

const CustomInputsContainer = styled.div`
  > * + * {
    margin-top: 15px;
  }
`;

const Quiz: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const { data: response, status } = useQuery('quiz', api.quiz.getQuizQuestions);
  const { data: quizResultsData, status: quizResultsStatus } = useQuery('quiz-results', api.quiz.getQuizResults);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  const [mutate] = useMutation(api.quiz.saveQuizResults);

  const questions = response?.data || [];
  const [currentQuizQuestion, setCurrentQuizQuestion] = useState(0);
  const [finished, setFinished] = useState(false);
  const [currentAnswerString, setCurrentAnswerString] = useState("");
  const [showErrorMessageText, setShowErrorMessageText] = useState("Invalid value");
  const [askForConfirmation, setAskForConfirmation] = useState(false);
  const [showConfirmationMessageText, setShowConfirmationMessageText] = useState("Are you sure ?");
  const [showErrorMessageStatus, setShowErrorMessageStatus] = useState(false);
  const userAlreadyFinished = useSelector((state: RootState) => state.user.passedQuiz);
  const [values, setValues] = useState<{ [key: string]: string | string[] | boolean }>({});

  const max = questions?.length || 0;

  useEffect(() => {
    const save = async () => {
      try {
        await mutate(values);
      } catch (err) {
        console.log(err);
      }
    };

    if (finished) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      save();
      dispatch(UserActions.setQuizPassed(true));
    }
  }, [dispatch, mutate, values, finished, userAlreadyFinished]);

  useEffect(() => {
    if (quizResultsData?.data?.results) {
      setValues(quizResultsData.data.results);
    }
  }, [quizResultsData]);

  const changeValue = (name: string, value: string | string[] | boolean): void => {
    
    setValues({
      ...values,
      [name]: value,
    });
  };
  const currentQuestion = questions[currentQuizQuestion];
  const moveTonextQuestion = () => {
    const nextQuestion = currentQuizQuestion + 1;
    setAskForConfirmation(false);
    if (nextQuestion < max) {
      setCurrentQuizQuestion(nextQuestion);
    } else {
      setFinished(true);
    }
  }
  const showNextQuestion = ( confirmationFromUserFlag : boolean = false ): void => {
    const nextQuestion = currentQuizQuestion + 1;
    if(currentQuestion['type'] == "custom"){
      for (let index = 0; index < currentQuestion['payload'].length; index++) {
        const element = currentQuestion['payload'][index];
        console.log()
        let indexName = currentQuestion['payload'][index]['name'];
        setCurrentAnswerString(values[indexName]+" "+currentQuestion['payload'][index]['placeholder']);
        if(element["lowerlimit"] != ""){
          if(+values[indexName] < +element["lowerlimit"]){
            if(currentQuestion['payload'][index]['errorMessage'] != ""){
              setShowErrorMessageText(currentQuestion['payload'][index]['errorMessage']);
            }
            setShowErrorMessageStatus(true);

            return ;  
          }
        }
        if(element["upperlimit"] != ""){
          if(+values[indexName] > +element["upperlimit"]){
            if(currentQuestion['payload'][index]['errorMessage'] != ""){
              setShowErrorMessageText(currentQuestion['payload'][index]['errorMessage']);
            }
            setShowErrorMessageStatus(true);
            return ;  
          }
        }
        if(element["commonlyAcceptedLowerLimit"] != "" ){
          if(+values[indexName] < +element["commonlyAcceptedLowerLimit"]){
            if(currentQuestion['payload'][index]['confirmationMessage'] != ""){
              setShowConfirmationMessageText(currentQuestion['payload'][index]['confirmationMessage']);
            }
            setAskForConfirmation(true);
            return ;  
          }
        }
        if(element["commonlyAcceptedUpperLimit"] != "" ){
          if(+values[indexName] > +element["commonlyAcceptedUpperLimit"]){
            if(currentQuestion['payload'][index]['confirmationMessage'] != ""){
              setShowConfirmationMessageText(currentQuestion['payload'][index]['confirmationMessage']);
            }
            setAskForConfirmation(true);
            return ;  
          }
        }
        
      }
      moveTonextQuestion(); 
    }else{
      moveTonextQuestion();
    }
    return ;
    if(!confirmationFromUserFlag){
      if(currentQuestion['name'] == "weight"){
        let indexName = currentQuestion['payload'][0]['name'];
        setCurrentAnswerString(values[indexName]+" pounds");
        if(+values[indexName] < 100 || +values[indexName] > 350){
          setAskForConfirmation(true);
          return ;
        }else{
          if (nextQuestion < max) {
            setCurrentQuizQuestion(nextQuestion);
          } else {
            setFinished(true);
          }
        }
      }else if(currentQuestion['name'] == "height"){
          let feetIndex = currentQuestion['payload'][0]['name'];
          let inchIndex = currentQuestion['payload'][1]['name'];

          var feetInString: string = String(values[feetIndex]);
          var inchInString: string = String(values[inchIndex]);
          feetInString = feetInString.substring(0,1);
          inchInString = inchInString.substring(0,1);
          var feetInNumber : number = parseInt(feetInString);
          var inchInNumber : number = parseInt(inchInString);
          setCurrentAnswerString(values[feetIndex]+" "+values[inchIndex]);
          if((feetInNumber <= 4 && inchInNumber < 10) || (feetInNumber >= 6 && inchInNumber < 4) ){
            setAskForConfirmation(true);
            return ;
          }else{
            if (nextQuestion < max) {
              setCurrentQuizQuestion(nextQuestion);
            } else {
              setFinished(true);
            }
          }

          // var feetInNumber = values[feetIndex].substring
      }else{
        setAskForConfirmation(false)
        if (nextQuestion < max) {
          setCurrentQuizQuestion(nextQuestion);
        } else {
          setFinished(true);
        }
      }
      
    }else{
      setAskForConfirmation(false)
      if (nextQuestion < max) {
        setCurrentQuizQuestion(nextQuestion);
      } else {
        setFinished(true);
      }
    }
    
    
  };

  const showPrevQuestion = (): void => {
    const nextQuestion = currentQuizQuestion - 1;

    if (nextQuestion >= 0) {
      setCurrentQuizQuestion(nextQuestion);
    }
  };

  const handleTypesWithoutButtonClick = (
    name: string,
    value: string | boolean,
  ): void => {
    changeValue(name, value);
    // showNextQuestion();
  };

  const handleCustomInputChange = (
    name: string,
    e: FormEvent<HTMLInputElement> | string,
  ) => {
    if (typeof e === 'string') {
      changeValue(name, e);
    } else {
      changeValue(name, e.currentTarget.value);
    }
  };

  const handleMultipleChoiceClick = (name: string, value: string): void => {
    // to handle previously saved choices that were saved as a string and not an array
    if (typeof values[name] === 'string') {
      const newValues = value === values[name] ? [value] : [value, values[name] as string];
      changeValue(name, newValues);
    }
    // First time filling out the form
    if (!values[name]) {
      changeValue(name, [value]);
    }
    if (Array.isArray(values[name])) {
      let newValues;
      if ((values[name] as string[]).includes(value)) {
        if((values[name] as string[]).includes("None")){
          newValues = [...(values[name]) as string[]].filter((v) => v !== "None");
          newValues = [...(newValues as string[]), value];
        }else{
          newValues = [...(values[name]) as string[]].filter((v) => v !== value);
        }
      } else {
        if((values[name] as string[]).includes("None") && value != "None"){
          newValues = [...(values[name]) as string[]].filter((v) => v !== "None");
          newValues = [...(newValues as string[]), value];
        }else if(value == "None"){
          newValues = ["None"];
        }else{
          newValues = [...(values[name] as string[]), value];
        }
      }
      changeValue(name, newValues);
    }
  };

  const isMultipleChoiceOptionSelected = (name: string, option: string): boolean => {
    if (typeof values[name] === 'string') {
      return values[name] === option;
    }
    if (Array.isArray(values[name])) {
      ;
      
      return (values[name] as string[]).includes(option);
    }
    return false;
  };

  if (status === 'loading' || quizResultsStatus === 'loading') {
    return null;
  }

  

  const renderQuestionBody = (current: Question): React.ReactElement | null => {
    switch (current.type) {
      case QuestionVariants.CUSTOM:
        return (
          <CustomInputsContainer>
            {current.payload.map((props) => (
              <Input
                {...props}
                value={values[props.name] as string}
                onChange={(e) => handleCustomInputChange(props.name, e)}
              />
            ))}
          </CustomInputsContainer>
        );
      case QuestionVariants.SINGLE_VARIANT:
        return (
          <VariantsContainer>
            {current.payload.map((variant) => (
              <Variant
                key={variant}
                onClick={(): void => changeValue(current.name, variant)}
                active={values[current.name] === variant}
              >
                <P size={24}>{variant}</P>
                <VariantCheck>
                  <img src={check} alt="Check" />
                </VariantCheck>
              </Variant>
            ))}
          </VariantsContainer>
        );
      case QuestionVariants.MULTIPLE_CHOICE:
        return (
          <VariantsContainer>
            {current.payload.map((variant) => (
              <Variant
                key={variant}
                onClick={(): void => handleMultipleChoiceClick(current.name, variant)}
                active={isMultipleChoiceOptionSelected(current.name, variant)}
              >
                <P size={24}>{variant}</P>
                <VariantCheck>
                  <img src={check} alt="Check" />
                </VariantCheck>
              </Variant>
            ))}
            {current.isnoneenable?(
                <Variant
                key={current.nonedisplaytext}
                onClick={(): void => handleMultipleChoiceClick(current.name, "None")}
                active={isMultipleChoiceOptionSelected(current.name, "None")}
              >
                <P size={24}>{current.nonedisplaytext}</P>
                <VariantCheck>
                  <img src={check} alt="Check" />
                </VariantCheck>
              </Variant>
            ):"" }
          </VariantsContainer>
        );
      case QuestionVariants.YES_NO:
        return (
          <Row>
            <Col sm={8} offset={{ sm: 2 }}>
              <Spacer bottom={30}>
                <Button
                  outlined={values[current.name] !== false}
                  onClick={() => handleTypesWithoutButtonClick(current.name, false)}
                >
                  NO
                </Button>
              </Spacer>
              <Button
                outlined={values[current.name] !== true}
                onClick={() => handleTypesWithoutButtonClick(current.name, true)}
              >
                YES
              </Button>
            </Col>
          </Row>
        );
      default:
        return null;
    }
  };

  const getCurrentStepButtonDisabled = (): boolean => {
    if (currentQuestion.type === QuestionVariants.CUSTOM) {
      const inputs = currentQuestion.payload.map((customInput) => [
        customInput.name,
        customInput.type,
      ]);

      return !inputs.every((input) => {
        const value = values[input[0]] as string;

        switch (input[1]) {
          case InputVariants.DATE:
            return value?.length === 10;
          default:
            return !!values[input[0]];
        }
      });
    }

    if (currentQuestion.type === QuestionVariants.SINGLE_VARIANT) {
      return !values[currentQuestion.name];
    }
    if (currentQuestion.type === QuestionVariants.MULTIPLE_CHOICE) {
      return !values[currentQuestion.name];
    }

    return false;
  };

  if (finished) {
    return (
      <Background finished>
        <Finish />
      </Background>
    );
  }
  if(!profileQuery.data.data.isActive){
    return <Redirect to="/dashboard" />;
  }
  return (<>
    <Background>
      <Header forceWhiteLogo showBackButton={false} />
      <Container>
      <Modal visible={askForConfirmation}>
          <H1>{(showConfirmationMessageText.length > 1)?showConfirmationMessageText:"Are you sure ?"}</H1>
          <Spacer top={10} />
          <P>Your answer is {currentAnswerString}.</P>
          <Spacer top={30} />
          <div style={{ display :"flex", justifyContent :"flex-start" }}>
            <ButtonMaterial variant="contained"  size="large" color="secondary" onClick={() => {setAskForConfirmation(false)}} style={{ borderRadius :"4rem" , backgroundColor :"#838AB4"}}>
              Let me check
            </ButtonMaterial>
            <ButtonMaterial variant="contained"  size="large" color="success" onClick={() => {moveTonextQuestion()}} style={{ borderRadius :"4rem", marginLeft :"9%", backgroundColor :"#7DD09F"}}>
              Yes, that's right
            </ButtonMaterial>
          </div>
        </Modal>
      <Modal visible={showErrorMessageStatus}>
          <H1> { (showErrorMessageText.length > 1)?showErrorMessageText:"Invalid values, Please check the value again"}</H1>
          <Spacer top={10} />
          <P>Your answer is {currentAnswerString}.</P>
          <Spacer top={30} />
          <div style={{ display :"flex", justifyContent :"flex-start" }}>
            <ButtonMaterial variant="contained"  size="large" color="secondary" onClick={() => {setShowErrorMessageStatus(false)}} style={{ borderRadius :"4rem" , backgroundColor :"#838AB4"}}>
              Let me check
            </ButtonMaterial>
          </div>
        </Modal>
        <Row>
          <Col md={8} offset={{ md: 2 }}>
            <Card>
              <HeaderContainer>
                <HeaderCell justify="flex-start">
                  {currentQuizQuestion > 0 && false && (
                    <BackButton onClick={showPrevQuestion}>
                      <FaArrowLeft className="back-button" />
                    </BackButton>
                  )}
                </HeaderCell>
                <HeaderCell>
                  <Progress current={currentQuizQuestion + 1} total={max} />
                </HeaderCell>
                <HeaderCell justify="flex-end">
                  <P colorKey="placeholder" size={20}>
                    {currentQuizQuestion + 1}
                    /
                    {max}
                  </P>
                </HeaderCell>
                <HeaderCell justify="flex-end">
                  {currentQuizQuestion > 0 && false &&  ((currentQuizQuestion+1) < max) && !getCurrentStepButtonDisabled() &&  (
                    <BackButton onClick={()=>{showNextQuestion()}}>
                      <FaArrowRight className="back-button" />
                    </BackButton>
                  )}
                </HeaderCell>
              </HeaderContainer>
              <MetaContainer bottom={35}>
                <H1 align="center">
                  {currentQuestion.title.replace('{{name}}', user?.name)}
                </H1>
                {!!currentQuestion.subtitle && (
                  <Spacer top={15}>
                    <P size={20} align="center">
                      {currentQuestion.subtitle}
                    </P>
                  </Spacer>
                )}
              </MetaContainer>
              {renderQuestionBody(currentQuestion)}
              <div style={{"display":"flex","justifyContent":"space-around","padding":"2rem 3rem"}}>
              {( currentQuizQuestion > 0 && ( currentQuestion.type === QuestionVariants.YES_NO || currentQuestion.type === QuestionVariants.CUSTOM || currentQuestion.type === QuestionVariants.SINGLE_VARIANT || currentQuestion.type === QuestionVariants.MULTIPLE_CHOICE)) && (
                
                      <ButtonMaterial
                        onClick={()=>{showPrevQuestion()}}
                        variant="contained"  
                        size="large" 
                        color="success" 
                        style={{ backgroundColor :"#838AB4" , padding : "1rem 5rem" , borderRadius :"4rem"}}
                      >
                        Back
                      </ButtonMaterial>
                    
              )}
              {(currentQuestion.type === QuestionVariants.YES_NO || currentQuestion.type === QuestionVariants.CUSTOM || currentQuestion.type === QuestionVariants.SINGLE_VARIANT || currentQuestion.type === QuestionVariants.MULTIPLE_CHOICE) && (
                
                      <ButtonMaterial
                          disabled={getCurrentStepButtonDisabled()}
                          onClick={()=>{showNextQuestion()}}
                          variant="contained"  
                          size="large" 
                          style={{ padding : "1rem 5rem" , borderRadius :"4rem" ,  backgroundColor :"#7DD09F"}}
                        > 
                          Next
                      </ButtonMaterial>
              )}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Background>
        <Feedback profileQuery={profileQuery} show={true} />
    </>
  );
};

export default Quiz;
