import styled from 'styled-components';
import { smallerThan } from '../../utils/grid';

const Card = styled.div`
  width: calc(100% - 200px);
  padding: 105px 100px 100px;
  background: white;
  border-radius: 25px;
  box-shadow: 0px 25px 50px 0 rgba(0,0,0,0.24);
  min-height: 495px;
  
  ${smallerThan('md')} {
    padding: 60px;
    width: calc(100% - 120px);
  }
  
  ${smallerThan('sm')} {
    padding: 30px;
    width: calc(100% - 60px);
  }
`;

export default Card;
