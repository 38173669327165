import React from 'react';
import styled from 'styled-components';
import { colors } from '../../config/styles';

const ProgressContainer = styled.div`
  width: 200px;
  height: 20px;
  border-radius: 10px;
  background-color: rgba(233,124,117,0.1);
`;

interface ProgressBarProps {
  width: number;
}

const ProgressBar = styled.div<ProgressBarProps>`
  transition: .1s ease-in-out;
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background-color: ${colors.error};
  border-radius: 10px;
  min-width: 20px;
`;

interface Props {
  current: number;
  total: number;
}

const Progress: React.FunctionComponent<Props> = (props) => {
  const { current, total } = props;

  const calculatedWidth = (current / total) * 100;

  return (
    <ProgressContainer>
      <ProgressBar width={calculatedWidth} />
    </ProgressContainer>
  );
};

export default Progress;
