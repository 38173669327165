import { User } from '../../typings/api';

export const SAVE_USER = 'user/SAVE_USER';
export const REMOVE_USER = 'user/REMOVE_USER';
export const SET_QUIZ_PASSED = 'user/SET_QUIZ_PASSED';

export interface UserPayload extends Omit<User, '_id'> {
  id: string;
}

export interface SaveUserAction {
  type: typeof SAVE_USER;
  payload: UserPayload;
}

export interface RemoveUserAction {
  type: typeof REMOVE_USER;
}

export interface SetQuizPassedActionAction {
  type: typeof SET_QUIZ_PASSED;
  payload: boolean;
}

export type UserActionType = SaveUserAction | RemoveUserAction | SetQuizPassedActionAction;
