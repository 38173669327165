import axios from 'axios';

import { store } from '../redux';
import appConfig from '../config';

const client = axios.create({ baseURL: appConfig.backendBaseUrl });

client.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getState().auth.token}`;

  return config;
});

export default client;
