import React, { useEffect } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import CopyRightSection from "../../components/Footer/copyrightSection"
import HeaderV2 from '../../components/HeaderV2';

const TermsOfService = () => {

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  return ( 
    <>
    <HeaderV2 />
    <div className="main">
        <p className="path">
        <b><a style={{color :"#000000"}} href={process.env.REACT_APP_APPLICATION_URL} className="link">Home</a></b> / Terms of Service
        </p>

      <h1>Niji Corp. Terms Of Service</h1>

      <div className="content">
        <div>
          <p>Last Modified: 01/Feb/2022</p>

          <p>Acceptance of the Terms of Service</p>

          <p>
          These Terms of Service are entered into by and between you and Niji Corp. (“Niji Corp.”, “we”, or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, “Terms of Service”) govern (1) your access to and use of www.nijineuro.com (the “Site”), including any content, functionality, and services offered on or through the Site, and any other Niji Corp. sites that link to these Terms of Service, whether you are a visitor or a registered user, and (2) your participation in, access to, or use of Niji Corp. activities, surveys, Brain Health Exercises, or other research opportunities (collectively, the “Services”).
          </p>

          <p>
            Please read these Terms of Service carefully before you start to use
            the Services. By using the Services or by clicking to accept or
            agree to the Terms of Service when this option is made available to
            you, you accept and agree to be bound and abide by these Terms of
            Service and our Privacy Policy, found at  <Link to="/privacy-policy" >
            Privacy Policy</Link>,
            incorporated herein by reference. If you do not want to agree to
            these Terms of Service or the Privacy Policy, you must not access or
            use the Services.
          </p>

          <p>
            The Services are offered and available to users who are at least the
            age of majority in the jurisdiction in which they reside (if you
            reside in the United States, you must be at least 18 years of age or
            older). By using the Services, you represent and warrant that you
            are of legal age to form a binding contract with Niji Corp. and meet
            all of the foregoing eligibility requirements. If you do not meet
            all of these requirements, you must not access or use the Services.
          </p>
        </div>

        <div>
          <b>Changes to the Terms of Service</b>

          <p>
            We may revise and update these Terms of Service from time to time in
            our sole discretion. All changes are effective immediately when we
            post them, and apply to all access to and use of the Services
            thereafter. Your continued use of the Services after we post revised
            Terms of Service means that you accept and agree to the changes. You
            should check this page from time to time so you are aware of any
            changes, as they will apply to you.
          </p>
        </div>

        <div>
          <b>Niji Corp. Services</b>

          <p>
            The Services include access to the Site; brain health exercises,
            surveys, and tests (“Brain Health Exercises”); and assessments and
            reports based on the results of Brain Health Exercises (“Brain
            Health Reports”). The Services are intended solely for
            informational, educational, and research purposes and are not
            intended to be a substitute for professional medical advice,
            diagnosis, or treatment. You must obtain professional medical advice
            from a licensed health care provider if you have any questions
            regarding diagnosis, treatment, or prevention of any medical
            condition, disease, or other impairment related to your personal
            health. By using the Services, you acknowledge and agree that you
            are voluntarily accessing the Services, and you assume exclusive
            responsibility and all risk for any decisions you make in reliance
            on the Services.
          </p>
        </div>

        <div>
          <b>Accessing the Services and Account Security</b>

          <p>
            We reserve the right to withdraw or change the Services in our sole
            discretion at any time. We will not be liable if for any reason all
            or any part of the Services are unavailable at any time or for any
            period. From time to time, we may restrict access to any part of the
            Services for any reason or no reason.
          </p>

          <p>
            To access the Services or some of the resources it offers, you may
            be asked to provide certain registration details or other
            information. It is a condition of your use of the Services that all
            the information you provide on or to the Services is correct,
            current, and complete. You agree that all information you provide to
            register with the Services or otherwise, including, but not limited
            to, through the use of any interactive features on the Services, is
            governed by our <Link to="/privacy-policy" >Privacy Policy</Link>, and you
            consent to all actions we take with respect to your information
            consistent with our Privacy Policy.
          </p>

          <p>
            If you choose, or are provided with, a user name, password, or any
            other piece of information as part of our security procedures, you
            must treat such information as confidential, and you must not
            disclose it to any other person or entity. You also acknowledge that
            your account is personal to you and agree not to provide any other
            person with access to the Services or portions of it using your user
            name, password, or other security information. You agree to notify
            us immediately of any unauthorized access to or use of your user
            name or password or any other breach of security. You also agree to
            ensure that you exit from your account at the end of each session.
            You should use particular caution when accessing your account from a
            public or shared computer so that others are not able to view or
            record your password or other personal information.
          </p>

          <p>
            We have the right to disable any user name, password, or other
            identifier, whether chosen by you or provided by us, at any time in
            our sole discretion for any or no reason, including if, in our
            opinion, you have violated any provision of these Terms of Service.
          </p>
        </div>

        <div>
          <b>Intellectual Property Rights</b>

          <p>
            The Services and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by Niji Corp., its licensors, or
            other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret, and
            other intellectual property or proprietary rights laws.
          </p>

          <p>
            These Terms of Service permit you to use the Services for your
            personal, non-commercial use only. You must not reproduce,
            distribute, modify, create derivative works of, publicly display,
            publicly perform, republish, download, store, or transmit any of the
            material on our Services, except that if we provide desktop, mobile,
            or other applications for download, you may download a single copy
            to your computer or mobile device solely for your own personal,
            non-commercial use, provided you agree to be bound by our end user
            license agreement for such applications.
          </p>

          <p>
            You also must not (i) modify copies of any materials from the
            Services; (ii) delete or alter any copyright, trademark, or other
            proprietary rights notices from copies of materials from the
            Services; or (iii) access or use for any commercial purposes any
            part of the Services.
          </p>

          <p>
            No right, title, or interest in or to the Services or any content on
            the Services is transferred to you, and all rights not expressly
            granted are reserved by Niji Corp. Any use of the Services not
            expressly permitted by these Terms of Service is a breach of these
            Terms of Service and may violate copyright, trademark, and other
            laws. If you violate these Terms of Service, your right to use the
            Services will stop immediately.
          </p>

          <p>
            The Niji Corp. name, the term “Niji Corp.”, “Niji Neuro,” our logo,
            and all related names, logos, product and service names, designs,
            and slogans are trademarks of Niji Corp. or its affiliates or
            licensors. You must not use our marks without our prior written
            permission. All other names, logos, product and service names,
            designs, and slogans on the Services are the trademarks of their
            respective owners.
          </p>
        </div>

        <div>
          <b>Prohibited Uses</b>

          <p>
            You may use the Services only for lawful purposes and in accordance with these Terms of Service. You also agree not to use the Services: (i) in any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries); (ii) for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise; (iii) to send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms of Service; (iv) to transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation; (v) to impersonate or attempt to impersonate us, any of our employees, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing); (vi) to engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm us or users of the Services, or expose them to liability.
          </p>


          <p>
            Additionally, you agree not to: (i) use the Services in any manner that could disable, overburden, damage, or impair the Site or interfere with any other party's use of the Services, including their ability to engage in real time activities through the Services; (ii) use any robot, spider, or other automatic device, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services; (iii) use any manual process to monitor or copy any of the material on the Services, or for any other purpose not expressly authorized in these Terms of Service, without our prior written consent; (iv) use any device, software, or routine that interferes with the proper working of the Services; (v) introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (vi) attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, the server on which the Services is stored, or any server, computer, or database connected to the Services; (vii) attack the Services via a denial-of-service attack or a distributed denial-of-service attack; or (viii) otherwise attempt to interfere with the proper working of the Services.
          </p>
        </div>

        <div>
          <b>Promotions and Surveys</b>

          <p>
            From time to time, we may offer financial incentives for participating in surveys or promotions via the Services (“Paid Surveys”). To participate in a Paid Survey and qualify for a reward, you must satisfy all requirements in the offer (including, without limitation, responding to required prompts and submitting valid contact information for delivery of any rewards). Paid Surveys and promotions are limited in number and are governed by these Terms of Service, and may also be governed by additional terms and conditions relating to eligibility, payment, and how your personal information may be used (“Additional Terms”), which will be communicated with the Paid Survey. You are responsible for reading these Terms of Service and any Additional Terms. By participating in any Paid Survey, you agree to be subject to those Additional Terms and these Terms of Service. 
          </p>

          <p>
            Each individual is permitted to participate only once in any Paid Survey. We have no obligation to accept or compensate any Paid Survey responses that we deem duplicative, fraudulent, incomplete, or otherwise invalid. No substitution, transfer, or cash redemption of any reward will be permitted. Niji Corp. reserves the right, in its sole discretion and at any time without prior notice, to (i) change, suspend, or cancel any Paid Survey or (ii) limit, suspend, or terminate your ability to participate in any Paid Survey, including if we believe you have violated the terms and conditions applicable to the Paid Survey. 
          </p>

          <p>
            Non-cash rewards (e.g., a gift card) may be issued by a third party (e.g., the gift card issuer) and may be subject to additional terms applicable to the specific non-cash reward, including, without limitation, how and where the reward may be used. You are solely responsible for complying with any additional terms governing your use of any non-cash reward. 
          </p>

          <p>
            Use of your personally identifiable information in connection with a Paid Survey is further described in our <Link to="/privacy-policy"  >Privacy Policy</Link>
          </p>
        </div>

        <div>
          <b>User Contributions; Content Standards</b>

          <p>
            These content standards apply to any and all content, information, or materials that you provide to the Services (“User Contributions”). User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not: (i) contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable; (ii) promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age; (iii) infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person; (iv) violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms of Service and our <Link to="/privacy-policy" >Privacy Policy</Link>; (v) be likely to deceive any person; (vi) promote any illegal activity, or advocate, promote, or assist any unlawful act; (vii) cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person; (viii) impersonate any person, or misrepresent your identity or affiliation with any person or organization; (ix) involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising; or (x) give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
          </p>

          <p>
            We have the right to: (i) remove or refuse to post any User Contributions for any or no reason in our sole discretion; (ii) take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Service, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Services or the public, or could create liability for Niji Corp.; (iii) disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy; (iv) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Services; or (v) terminate or suspend your access to all or part of the Services for any or no reason, including without limitation, any violation of these Terms of Service. However, we cannot review material before it is posted on the Services, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section. 
          </p>

          <p>
            Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS NIJI CORP. AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
          </p>
        </div>

        <div>
          <b>Reliance on Information Posted</b>

          <p>
            The information presented by the Services is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Services, or by anyone who may be informed of any of its contents.
          </p>

          <p>
            This Services may include content provided by third parties, including materials provided by other users and/or third-party licensors, syndicators, aggregators, and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by Niji Corp., are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of Niji Corp.. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
          </p>
        </div>

        <div>
          <b>Changes to the Services</b>

          <p>
            We may update the content on the Services from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Services may be out of date at any given time, and we are under no obligation to update such material. 
          </p>
        </div>

        <div>
          <b>Data Privacy</b>

          <p>
            All information we collect on the Services is subject to our <Link to="privacy-policy" >Privacy Policy</Link>. By using and submitting information to the Services, you consent to all actions taken by us with respect to your information in compliance with our Privacy Policy. 
          </p>
        </div>

        <div>
          <b>Research Consent</b>

          <p>
            Before you participate in, complete, or interact with a Brain Health Exercise, you must give your consent for the cognitive data collected by the Brain Health Exercise (“Brain Health Exercise Data”), in addition to any other self-reported brain health, medical, or other related information (“Supplementary Data”) that you provide in connection with the Brain Health Exercise, to be used in research that Niji Corp. conducts (“Niji Corp. Research”), as described in the applicable Consent Document. 
          </p>

          <p>
            Niji Corp. Research may be sponsored by or conducted in collaboration with research partners, which may include educational institutions, academic researchers, pharmaceutical companies, non-profit organizations, and other public or non-public entities (such as the federal government). We may disclose aggregated Brain Health Exercise Data and Supplementary Data with these research partners. We will obtain your explicit consent before disclosing any of your individual-level Brain Health Exercise Data and Supplementary Data to any third parties for research purposes.  For example, if we have obtained your explicit consent, we may share your individual-level Brain Health Exercise Data and Supplementary Data with research collaborators, research sponsors, or other third parties with whom we conduct research or for their research purposes. 
          </p>

          <p>
            You may withdraw your consent to participate in Niji Corp. Research at any time. However, please note that even if you withdraw your consent: (i) your Brain Health Exercise Data and Supplementary Data that were collected prior to your withdrawal may continue to be used, retained, and analyzed within the scope of the original Consent Document; (ii) we are unable to retract data that have already been shared with external researchers; and (iii) we may continue to use and retain your Brain Health Exercise Data and Supplementary Data pursuant to our Privacy Policy. 
          </p>

          <p>
            You understand and agree that we have no obligation to compensate you for any research, products, or services that we develop using your Brain Health Exercise Data or Supplementary Data or any other information you submit or post to the Services, or for sharing your Brain Health Exercise Data or Supplementary Data with any research partners in accordance with our Privacy Policy and these Terms of Service. 
          </p>
        </div>

        <div>
          <b>Links from the Services</b>

          <p>
            If the Services may contain links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to or from the Services, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.
          </p>
        </div>

        <div>
          <b>Disclaimer of Warranties</b>

          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF ANY SERVICES.
          </p>

          <p>
            YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER NIJI CORP. NOR ANY PERSON ASSOCIATED WITH NJI CORP. MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER NIJI CORP. NOR ANYONE ASSOCIATED WITH NIJI CORP. REPRESENTS OR WARRANTS THAT THE SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES OR THE SERVER(S) THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Services will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our Services for any reconstruction of any lost data.
          </p>

          <p>
           TO THE FULLEST EXTENT PROVIDED BY LAW, NIJI CORP. HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>

        <div>
          <b>Limitation on Liability</b>

          <p>
            TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL NIJI CORP., ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>

        <div>
          <b>Indemnification</b>

          <p>
            You agree to defend, indemnify, and hold harmless Niji Corp., its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Service or your use of the Services, including, but not limited to, your User Contributions, any use of the Services’ content, services, and products other than as expressly authorized in these Terms of Service, or your use of any information obtained from the Services.
          </p>
        </div>

        <div>
          <b>Termination</b>

          <p>
            These Terms of Service will remain in effect until terminated by you or us as set forth in this section. You can terminate these Terms of Service by contacting us to delete your Site account. Please note that a request to terminate your Site account will terminate your access to and use of the Services and your participation in Niji Corp. Research. 
          </p>

          <p>
            We may ask you to verify your identity before we can act on an account deletion request, and we may be required to retain a record of your request and your account information in accordance with our Privacy Policy and/or to the extent required by applicable laws. We may terminate these Terms of Service if: (i) in our sole discretion, we believe you have violated these Terms of Service; (ii) we have determined that providing the Services to you is no longer safe, practicable, legal, commercially viable, or in Niji Corp.’s best interests; or (iii) we are required to do so by law. 
          </p>

          <p>
            Upon termination of these Terms of Service, the following provisions, any representations and warranties, and any other provisions which by their nature are intended to survive will survive and remain in full force and effect: Disclaimer of Warranties, Limitation of Liability, Indemnification, Termination, Governing Law and Jurisdiction, Arbitration, Limitation on Time to File Claims, Waiver and Severability, and Entire Agreement. 
          </p>
        </div>

        <div>
          <b>Governing Law and Jurisdiction</b>

          <p>
            All matters relating to the Services and these Terms of Service, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), will be governed by and construed in accordance with the internal laws of the State of Delaware without giving effect to any choice or conflict of law provision or rule. Any legal suit, action, or proceeding arising out of, or related to, these Terms of Service or the Services must be instituted exclusively in the federal or state courts located in New Castle County, Delaware, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Service in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
          </p>
        </div>

        <div>
          <b>Arbitration</b>

          <p>
            At our sole discretion, we may require you to submit any disputes arising from these Terms of Service or use of the Services, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Delaware law.
          </p>
        </div>

        <div>
          <b>Limitation on Time to File Claims</b>
          
          <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF SERVICE OR THE SERVICES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
        </div>

        <div>
          <b>Waiver and Severability</b>

          <p>No waiver by Niji Corp. of any term or condition set out in these Terms of Service shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of Niji Corp. to assert a right or provision under these Terms of Service shall not constitute a waiver of such right or provision. If any provision of these Terms of Service is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Service will continue in full force and effect. </p>
        </div>

        <div>
          <b>Entire Agreement</b>

          <p>The Terms of Service and our Privacy Policy constitute the sole and entire agreement between you and Niji Corp. regarding the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Services. </p>
        </div>

        <div>
          <b>Contact Us</b>

          <p>Feedback, comments, requests for technical support, and other communications relating to the Services should be directed to: info@nijineuro.com.</p>
        </div>

      </div>
    </div>
    
    <div><CopyRightSection /></div>
    </>
  );
};

export default TermsOfService;

/*  


*/
