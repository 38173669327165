import React from 'react';

const AboutTheGame = ({ setIsNextDisabled }) => (
  <div className="game-instruction-container">
    <div className="game-instruction-content">
      <div className="game-instruction-text">
      You are about to perform a brain health game. It will take less than 10 minutes and
      it is very important that you play this game in a quiet and private area where you 
      will not be distracted. 
      </div>
      <div className="game-instruction-text">
        Once you begin, it is important that you play the game until the end. Are you ready 
        to start?
      </div>
    </div>
  </div>
);

export default AboutTheGame;
