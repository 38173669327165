import React, { useEffect } from 'react';
import {
  Switch, Route, Redirect, useHistory,useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import api from '../../api';
import SignUp from '../../container/SignUp';
import SignIn from '../../container/SignIn';
import ResetPassword from '../../container/ResetPassword';
import SetPassword from '../../container/SetPassword';
import Quiz from '../../container/Quiz';
import Loader from '../../container/Loader';
import Dashboard from '../../container/Dashboard';
import CommingSoon from '../../container/CommingSoon';
import LandingPage from '../../container/LandingPage';
import { RootState, persistor } from '../../redux';
import UserActions from '../../redux/user/actions';
import Game from '../../container/Game';
import PastGames from '../../container/PastGames';
import Verify from '../../container/Verify';
import useNotifications from '../../hooks/useNotifications';
import nijiLogo from '../../assets/images/Logo.png';
import PrivacyPolicy from '../../container/PrivacyPolicy';
import TermsOfService from '../../container/TermsOfService';
import AuthActions from '../../redux/auth/actions';
const AuthContext = React.createContext({ isAuthenticated: false, quizPassed: false });

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthContext.Consumer>
        {({ isAuthenticated }) => (isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        ))}
      </AuthContext.Consumer>
    )}
  />
);

// eslint-disable-next-line @typescript-eslint/naming-convention
const UnauthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AuthContext.Consumer>
        {({ isAuthenticated }) => (!isAuthenticated? (
          <Component {...props} />
        ) : ( 
          <Redirect to="/dashboard" /> 
        ))}
      </AuthContext.Consumer>
    )}
  />
);

const Routes: React.FunctionComponent = () => {
  useNotifications();
  const dispatch = useDispatch();
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  if (profileQuery.data === undefined) {
    // persistor.purge()
    //   .then(() => {
    //     // window.location.reload();
    //   });
  }
  const auth = useSelector((state: RootState) => state.auth);
  const user = useSelector((state: RootState) => state.user);
  const history = useHistory();

  const isAuthenticated = Boolean(auth.token && auth.expiration > Date.now());
  const logoutAction = () => {
    dispatch(AuthActions.logoutUser());
  };

  useEffect(() => {
    const load = async () => {
      try {
        const profile = await profileQuery.refetch();
        dispatch(UserActions.saveUser(profile.data));
        
        if (!profile.data.passedQuiz) {
          history.push('/dashboard');
        }
      } catch (err) {
        console.log('tell..');
        console.warn(err);
      }
    };
    if(isAuthenticated && window.location.href.includes("reset")){
      logoutAction();
    }else if (isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  if (isAuthenticated && (!profileQuery.data || profileQuery.status === 'loading')) {
    return <Loader image={nijiLogo} />;
  }

  
  return (
    <AuthContext.Provider value={{ isAuthenticated, quizPassed: user.passedQuiz }}>
      <Switch>
        <UnauthRoute exact path="/" component={LandingPage} />
        <UnauthRoute exact path="/niji-qa" component={CommingSoon} />
        <UnauthRoute exact path="/signup" component={SignUp} />
        <UnauthRoute exact path="/login" component={SignIn} />
        <UnauthRoute exact path="/reset" component={ResetPassword} />
        <UnauthRoute exact path="/reset/:token" component={SetPassword} allowLoginUser={true} />
        <UnauthRoute exact path="/verify/:token" component={Verify} />
        <UnauthRoute exact path="/privacy-policy" component={PrivacyPolicy} />
        <UnauthRoute exact path="/terms-of-service" component={TermsOfService} />
        <AuthRoute exact path="/dashboard" component={Dashboard} />
        <AuthRoute exact path="/quiz" component={Quiz} />
        <AuthRoute exact path="/game" component={Game} />
        <AuthRoute exact path="/past-games" component={PastGames} />
        <Redirect to="/" />
      </Switch>
    </AuthContext.Provider>
  );
};

export default Routes;
