import React from 'react';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';

import { H1, P } from '../../../../components/Typography';
import Spacer from '../../../../components/Spacer';

import Brain from '../../../../assets/images/Brain-Award.svg';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkContainer = styled.a`
  margin-top: 80px;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const LinkButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #82D8A5;
  font-size: 22px;
  width: 250px;
  height: 84px;
  border-radius: 54px;
  cursor: pointer;
`;

const Finish: React.FunctionComponent = () => (
  <Row>
    <Col xs={6} sm={8} offset={{ xs: 3, sm: 2 }}>
      <H1 size={45} align="center" colorKey="white">Congratulations!</H1>
      <Spacer top={20} bottom={55}>
        <P size={20} align="center" colorKey="placeholder">
          You completed the first step of our journey to a healthier brain, and we have every reason to celebrate!
        </P>
      </Spacer>
      <ImageContainer>
        <img src={Brain} alt="Congratulation" />
      </ImageContainer>
      <div style={{ display : "flex", justifyContent :"space-around"}}>
          <LinkContainer href="/dashboard">
            <LinkButton>Dashboard</LinkButton>
          </LinkContainer>
          <LinkContainer href="/game">
            <LinkButton>Next Phase</LinkButton>
          </LinkContainer>
      </div>
      
    </Col>
  </Row>
);

export default Finish;
