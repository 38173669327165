import React, { useEffect } from "react";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
import CopyRightSection from "../../components/Footer/copyrightSection"
import HeaderV2 from '../../components/HeaderV2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const pages = ['Home', 'About Us', 'Solutions','Insights','Contact','Partner with us'];

const PrivacyPolicy = () => {
  const history = useHistory();
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
  <>
    <HeaderV2 />
    <div className="main">
      <p className="path">
      <b><a style={{color :"#000000"}} href={process.env.REACT_APP_APPLICATION_URL} className="link">Home</a></b> / Privacy Policy
      </p>

      <h1>Niji Corp. Privacy Policy</h1>

      <div className="content">
        <div>
          <p>Last Modified: 01/Feb/2022</p>

          <p>Introduction</p>

          <p>
            Niji Corp. (“Niji Corp.”, “us”, or “we”) respect your privacy and
            are committed to protecting it through our compliance with this
            policy.
          </p>

          <p>
            This policy describes the types of information we may collect from
            you or that you may provide to us when you visit our website
            www.nijineuro.com (the “Site”), participate in Niji Corp. research
            opportunities or projects (“Niji Corp. Research”), or when you
            participate in or complete any of the tests, surveys, or other brain
            health exercises (“Brain Health Exercises”) that we make available
            to you (collectively, the “Services”). It also describes our
            practices for collecting, using, maintaining, protecting, and
            disclosing that information. This policy applies to information we
            collect: (i) on or via the Services; or (ii) via email, text, and
            other electronic messages between you and these Services. It does
            not apply to information collected by us offline or through any
            other means, including on any other website operated by us or any
            third party, or through any application or content (including
            advertising) that may link to or be accessible from or on the
            Services.
          </p>

          <p>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Services. By accessing or using these Services, you agree
            to this Privacy Policy and our <Link to="/terms-of-service" >Terms of 
            Service</Link>. This policy may change from time to time (see Changes to
            Our Privacy Policy). Your continued use of the Services after we
            make changes is deemed to be acceptance of those changes, so please
            check this policy periodically for updates.
          </p>
        </div>

        <div>
          <b>Children Under the Age of 18</b>

          <p>
            Our Services are not intended for children under 18 years of age. No
            one under age 18 may provide any information to the Services. We do
            not knowingly collect personal information from children under 18.
            If you are under 18, do not use or provide any information to the
            Services. If we learn we have collected or received personal
            information from a child under 18, we will delete that information.
            If you believe we might have any information from or about a child
            under 18, please contact us at: privacy@nijineuro.com.
          </p>
        </div>

        <div>
          <b>Information We Collect About You and How We Collect It</b>

          <p>
            We collect several types of information from and about users of our
            Services, including information that could be used to identify you,
            either alone or in combination with other information (“personal
            information”). We collect the following types of personal
            information:
          </p>

          <ul>
            <li>
              Identifiers, such as your name and email address. We generally
              collect this information when you register for an account on the
              Services or respond to a survey, offer, or other communication
              from us.
            </li>
            <li>
              Brain Health Exercise Data, including the results, measurements,
              and other cognitive data collected from Brain Health Exercises you
              complete, participate in, or interact with;
            </li>

            <li>
              Supplementary Data, including information that you report to us in
              connection with Brain Health Exercises, such as your date of
              birth, sex/gender, race/ethnicity, education history, handedness,
              height and weight, medical illness information, medication
              information, health information (such as blood pressure,
              cholesterol level), lifestyle information (such as whether you
              smoke or exercise), cognitive impairment information, and family
              health history;
            </li>
            <li>
              Sensitive information, such as information about your health,
              medical conditions, family health history, demographic
              information, Brain Health Exercise Data, race, or sex/gender;
            </li>
            <li>
              Geolocation information, such as your precise or approximate
              location;
            </li>
            <li>Audio and video information;</li>
            <li>
              Inferences and derived data, including inferences and information
              derived from other personal information or Brain Health Exercise
              Data; and/or
            </li>
            <li>
              Information about your internet connection, the equipment you use
              to access our Services, and usage details.
            </li>
          </ul>
        </div>

        <p>
          We collect this information directly from you when you provide it to
          us, or automatically as you navigate through the Site. Information
          collected automatically may include usage details, IP addresses, and
          information collected through cookies, web beacons, and other tracking
          technologies.
        </p>

        <div>
          <p><i>Information you provide to us</i></p>

          <ul>
            <li>
              The information you provide directly to us includes: <br />
              Information that you provide by completing forms, questionnaires,
              or surveys, or completing Brain Health Exercises and Supplementary
              Data on our Services. This includes information provided at the
              time of registering an account on the Site (“Account Registration
              Information”), including your name and email address. We may also
              ask you for information when you participate in a promotion or
              activity sponsored by us or our research partners, or when you
              report a problem with our Services.
            </li>

            <li>
              Records and copies of your correspondence (including email
              addresses) if you contact us.
            </li>

            <li>
              Your responses to surveys that we might ask you to complete to
              improve our Services.
            </li>
          </ul>
        </div>
        <div>
          <p><i>Information We Collect Through Automatic Data Collection Technologies</i> </p>
          <p>
              As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
          </p>
          <ul>
            <li>
              Details of your visits to our Services, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Services.
            </li>
            <li>Information about your computer, tablet, mobile device, or hardware used to access the software and internet connection, including your IP address, operating system, and browser type.</li>
          </ul>
          <p>
              The information we collect automatically may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Services and to deliver a better and more personalized service, including by enabling us to estimate our audience size and usage patterns; store information about your preferences, allowing us to customize our Services according to your individual interests; or recognize you when you return to our Services.
          </p>
          <p>The technologies we use for this automatic data collection may include:</p>
          <ul>
            <li>
            <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services. 
            </li>
            <li>
              <b>Flash Cookies.</b> Certain features of our Services may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Services. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.
            </li>
            <li>
              <b>Web Beacons.</b> Pages of our Site and/or our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Niji Corp., for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity). 
            </li>
          </ul>
        </div>
        <div>
          <b>
            How We Use Your Information
          </b>
          <p>
            We use information that we collect about you or that you provide to us, including any personal information:
          </p>
          <ul>
            <li>
            To provide, improve, and develop our Services and make their contents available to you. This includes allowing you to register for an account on the Site, offering Brain Health Exercises to you, or conducting data analysis to improve our Services or develop new services.  
            </li>
            <li>To provide you with information, products, or services that you request from us, such as customer service.</li>
            <li>To notify you about changes to our Services or any products or services we offer or provide through it.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered between you and us, including compensating you for your participation in testing or research studies (if applicable) or ensuring your compliance with our Terms of Service. </li>
            <li>For Niji Corp. Research purposes. If you have completed an appropriate Consent Document, we may use aggregated or individual-level Brain Health Exercise Data and/or Supplementary Data for Niji Corp. research purposes, which may include research intended for publication in peer-reviewed journals and/or government-funded research. If described in the applicable Consent Document, we may also disclose your individual-level Brain Health Exercise Data and/or Supplementary Data to designated third parties, such as research collaborators (e.g., academic institutions or other research organizations), research sponsors, non-profit organizations, pharmaceutical companies, or other third parties. </li>
            <li>To notify you of future research opportunities. Occasionally, third parties (e.g., research institutions and organizations, pharmaceutical companies, or other research groups) may contact us with information about potential research opportunities which we believe may be of interest to you. If you have given your consent to participate in Niji Corp. Research, we may contact you to notify you of the research opportunity and provide you with a means to indicate your interest in participating. We do not provide these third parties with your individual-level Brain Health Exercise Data or Supplementary Data unless you have given your explicit consent. If you participate in a research study conducted by a third party (“Non-Niji Research”), you will need to provide additional informed consent for that research study. </li>
            <li>To fulfill any other purpose for which you provide it, in any other way we may describe when you provide the information, or for any other purpose with your consent. </li>
            
          </ul>
        </div>
        <div>
          <b>
            Disclosure of Your Information
          </b>
          <p>We may disclose personal information that we collect or you provide as described in this Privacy Policy:</p>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all Niji Corp.’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Niji Corp. about our Services users is among the assets transferred.</li>
            <li>To third parties so they can contact you about external research opportunities. If you have opted-in to receiving communications from third parties about external research opportunities that may be relevant to you, we may disclose your contact information (e.g., name and email address) to third parties for their direct marketing purposes. We do not disclose your individual-level Brain Health Exercise Data and/or Supplementary Data to these third parties unless you have given your explicit consent to that disclosure (e.g., via a Consent Document). </li>
            <li>To third parties for their research purposes only if you have provided your explicit informed consent to these disclosures in an applicable Consent Document.  These third parties are contractually obligated to keep your information confidential and use it only for the purposes for which we disclose it to them. For more information about how you can withdraw your consent after giving it, see Choices About How We Use and Disclose Your Information. Please note that even if you withdraw your consent, we may be unable to retract information that has already been used or disclosed prior to your withdrawal.</li>
            <li>To fulfill the purpose for which you provide it, for any other purpose disclosed by us when you provide the information, and with your consent.</li>
          </ul>
        </div>
        <div>
          <p>
          We may also disclose your personal information to comply with any court order, law, or legal process, including to respond to any government or regulatory request; to enforce or apply our <a href="/terms-of-service">Terms of Service</a> and other agreements, including for billing and collection purposes; and if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of us, our customers, other users, or other third parties. 
          </p>
          <p>
          We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. 
          </p>
        </div>

        <div>
          <b>
            Choices About How We Use and Disclose Your Information
          </b>
          <p>
            <b>Site Account Information.</b> In some cases, you can review and change your Site Account Information (e.g., your login email address or password) by logging into the Services and visiting your account profile page. You may also send us an email at privacy@nijineuro.com to request access to, correct, or delete certain personal information that you have provided to us. In some cases, we may not be able to delete your personal information except by also deleting your user account, in which case, you will lose access to the Site upon deletion of your personal information. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.
          </p>
          <p> <b>Direct Marketing Communications.</b> If you do not want us to contact you about third-party research opportunities, please contact us at privacy@nijineuro.com to opt-out of these communications</p>
          <p><b>Research Partner Communications.</b> If you no longer want us to share your contact information with our research partners for their direct marketing purposes, please contact us at privacy@nijineuro.com to opt-out.  </p>
          <p><b>Withdraw Consent for Research Purposes.</b> If you have previously completed a Consent Document, you can withdraw your consent to continued participation in Niji Corp. Research. However, please note that we are unable to delete information that has already been used in research studies, published research, or shared with third parties within the scope of the applicable Consent Document. If you withdraw consent, we may continue to use Brain Health Exercise Data and/or Supplementary Data in accordance with this Privacy Policy. Also, we may keep a copy of your information to the extent required by applicable laws, to retain a record of your deletion request, or to comply with our legal obligations. </p>
          <p><b>California Privacy Rights.</b> California residents may have additional personal information rights and choices. Please see the Privacy Notice for California Residents below. </p>
          <p><b>Nevada Privacy Rights.</b> Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may submit a request to this designated address: privacy@nijineuro.com. However, please know we do not currently sell data triggering that statute’s opt-out requirements.</p>
        </div>

        <div>
          <b>Data Security</b>
          <p>
            We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Services, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Services.
          </p>


          <b>
            Do Not Track
          </b>
          <p>
            We are committed to providing you with meaningful choices about the information we collect on the Site, and that is why we provide the access and control choices set forth in this Privacy Policy. However, we do not recognize or respond to browser initiated Do Not Track (“DNT”) signals, in part because no common industry standard for DNT has been adopted by industry groups, technology companies, or regulators, nor is there a consistent standard of interpreting user intent.
          </p>

          <b>Changes to Our Privacy Policy</b>
          <p>It is our policy to post any changes we make to our Privacy Policy on this page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the email address specified in your account and/or through a notice on the Services home page. The date this Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Services and this Privacy Policy to check for any changes.</p>

          <b>Contact Information</b>
          <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: Niji Corp.</p>
          <div>45 Prospect St. </div>
          <div>Cambridge MA 02139-2402 </div>
          <div>Email: privacy@nijineuro.com </div>
        </div>

        <div>
          <div style={{ display : "flex", justifyContent :"center"}}>
            <b>Privacy Notice for California Residents</b>
          </div>
          <div>
            <p><b>Effective Date:</b> 01/Feb/2022</p>
          </div>
          <div>
            <p>
              This <b>Privacy Notice for California Residents </b>supplements the information contained in Niji Corp.’s Privacy Policy and applies solely to all visitors, users, and others who reside in the State of California (“consumers” or “you”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Policy.
            </p>
            <i>Information We Collect</i>
            <p>We collect information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device <b>(“personal information”)</b>. Personal information does not include publicly available information from government records or deidentified or aggregated consumer information.</p>
            <p>
              We have collected the following categories of personal information from consumers within the last twelve (12) months:
            </p>
            <table className="privacy-policy-table" cellSpacing={0} >
              <tr>
                <th><b>Category of Personal Information</b></th>
                <th><b>Examples of Personal Information</b></th>
                <th><b>Categories of Third Parties with Whom We May Share Personal Information</b></th>
              </tr>
              <tr>
                <td>Identifiers</td>
                <td>Real name, IP address, or email address</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</td>
                <td>Physical characteristics or description, or medical or health-related information</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Protected classification characteristics under California or federal law</td>
                <td>Age (40 years or older) or date of birth, race, medical condition, physical or mental disability, sex (including gender, gender identity, and gender expression), pregnancy or childbirth and related medical conditions</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Biometric information</td>
                <td>Physiological, behavioral, cognitive, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying physical patterns, and sleep, health, or exercise data</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Internet or other similar network activity</td>
                <td>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement</td>
                <td>Service providers; government entities</td>
              </tr>
              <tr>
                <td>Geolocation data</td>
                <td>Physical location </td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Sensory data</td>
                <td>Audio, electronic, visual, thermal, olfactory, or similar information</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Professional or employment-related information</td>
                <td>Current or past job history</td>
                <td>Service providers; government entities; research collaborators and partners</td>
              </tr>
              <tr>
                <td>Inferences drawn from other personal information </td>
                <td>A profile reflecting a consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes</td>
                <td>Service providers; government entities</td>
              </tr>
            </table>
            
            <div>
              <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
              <ul>
                <li>Directly from you. For example, from forms or activities you complete or products and services you use.</li>
                <li>Indirectly from you. For example, from observing your actions on our Site or use of the Services.</li>
              </ul>

              <p>In some cases, we may combine the personal information we have about you with other publicly available data, such as census data. </p>
            </div>
            <div>
              <p><b>Use of Personal Information</b></p>
              <p>We may use or disclose the personal information we collect for one or more of the purposes described below: </p>
              <ul>
                <li>Performing services, including to provide, support, personalize, and develop our Services, Site, and other products, and services (including Site analytics), or to provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor. </li>
                <li>Auditing related to a current interaction with a consumer and concurrent transactions.</li>
                <li>Detecting, protecting against, and responding to security incidents or other illegal activity, and prosecuting those responsible for the activity. </li>
                <li>Debugging to identify and repair errors that impair functionality.</li>
                <li>Short-term transient use that does not involve disclosing personal information to another third party and is not used to build a profile about a consumer or alter an individual’s experience outside of the current interaction.</li>
                <li>For testing, research, analysis, and product development, including to develop and improve our Site, the Services, and our other products and services.</li>
                <li>Verifying or maintaining the quality or safety of the Services, and to improve, upgrade, or enhance the Services. </li>
                <li> To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</li>
                <li>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</li>
                <li>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred.</li>
                <li>If you have completed an appropriate Informed Consent Document, we may use aggregated or individual-level Brain Health Exercise Data or Supplementary Data for Niji Corp. research purposes. </li>
                <li>To notify you of future research opportunities. If you have given your consent to participate in Niji Corp. Research, we may contact you to notify you of third-party research opportunities and provide you with a means to indicate your interest in participating.</li>
              </ul>
              <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
            </div>

            <div>
              <p><b>Sharing Personal Information</b></p>
              <p>We may share your personal information by disclosing it to a third party for a business purpose. We only make these business purpose disclosures under written contracts that describe the purposes, require the recipient to keep the personal information confidential, and prohibit using the disclosed information for any purpose except performing the contract. In the preceding twelve (12) months, Niji Corp. may have disclosed personal information for a business purpose to the categories of third parties indicated in the chart above.</p>
              <p>We do not sell personal information. For more on your personal information sale rights, see Personal Information Sales Opt-Out and Opt-In Rights.</p>

              <b>Your Rights and Choices</b>
              <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>

              <b>Right to Know and Data Portability</b>
              <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months (the “right to know”). Once we receive your request and confirm your identity (see Exercising Your Rights to Know or Delete), we will disclose to you:</p>

              <ul>
                <li>The categories of personal information we collected about you.</li>
                <li>The categories of sources for the personal information we collected about you.</li>
                <li>Our business or commercial purpose for collecting or selling that personal information.</li>
                <li>The categories of third parties with whom we share that personal information.</li>
                <li>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing: (i) sales, identifying the personal information categories that each category of recipient purchased; and (ii) disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</li>
                <li>The specific pieces of personal information we collected about you (also called a data portability request).</li>
              </ul>

              <p>
                <b>Right to Delete</b>
              </p>
              <p>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions (the “right to delete”). Once we receive your request and confirm your identity (see Exercising Your Rights to Know or Delete), we will review your request to see if an exception allowing us to retain the information applies. We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
              <ul>
                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.</li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law. </li>
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent. </li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                <li>Comply with a legal obligation. </li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
              </ul>
              <p>We will delete or deidentify personal information not subject to one of these exceptions from our records and will direct our service providers to take similar action.</p>

              <p>
                <b>Exercising Your Rights to Know or Delete</b>

              </p>
              <p>To exercise your rights to know or delete described above, please submit a request by either:</p>
              <ul>
                <li><div>
                      Writing to us at:<br />
                        Niji Corp.<br />
                        45 Prospect St. <br />
                        Cambridge MA 02139-2402
                    </div>
                </li>
                <li>Emailing us at: privacy@nijineuro.com </li>
              </ul>
              <p>Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal information. </p>
              <p>You may only submit a request to know twice within a 12-month period. Your request to know or delete must provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative, which may include providing us with government-issued identification, verifying your ownership of your Niji Corp. or other external account, and/or (if applicable) providing us with documentation confirming your authorization to act on behalf of the data subject. You will also need to describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. You do not need to create an account with us to submit a request to know or delete. We will only use personal information provided in the request to verify the requestor’s identity or authority to make it. </p>
              <b>Response Timing and Format</b>
              <p>We will confirm receipt of your request within ten (10) business days. If you do not receive confirmation within the 10-day timeframe, email us at privacy@nijineuro.com. We endeavor to substantively respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing. We will deliver our written response by mail or electronically. Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance. We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>

              <p>
                <b>Personal Information Sales Opt-Out and Opt-In Rights</b>
              </p>
              <p>We do not sell any personal information. That said, if you are age 16 or older, the CCPA gives you the right to direct us to not sell your personal information at any time (the “right to opt-out”). We do not sell the personal information of consumers we know are less than 16 years old. Consumers who opt-in to personal information sales may opt-out of future sales at any time. You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request.

              </p>
              <p><b>Non-Discrimination</b></p>
              <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not deny you goods or services; charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties; provide you a different level or quality of goods or services; or suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>

              <p>
                <b>Notice of Financial Incentives</b>
              </p>
              <p>The CCPA permits us to offer you certain financial incentives that can result in different prices, rates, or quality levels. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time. </p>
              <p>For example, from time to time, Niji Corp. may offer you the opportunity to provide Brain Health Exercise Data and Supplementary Data to us by completing a test, survey, or other activity in exchange for a financial incentive. The value of the financial incentive for any incentivized offer is our good faith estimate of the value of the personal information you provide to us to qualify for the incentive, which may vary based on the internal resources required to collect and maintain the data. </p>
              <p>We generally collect the following types of personal information in connection with incentivized offers:</p>
              <ul>
                <li>Identifiers</li>
                <li>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e))</li>
                <li>Protected classification characteristics under California or federal law</li>
                <li>Biometric information </li>
                <li>Internet or other similar network activity</li>
                <li>Geolocation data</li>
                <li>Sensory data</li>
                <li>Professional or employment-related information</li>
                <li>Inferences drawn from other personal information</li>
              </ul>

              <p>By participating in an incentivized offer, you are opting into the terms of the offer (which include our Terms of Service and any additional offering details communicated to you with the offer). You may withdraw from the incentivized offer at any time by contacting us at privacy@nijineuro.com and/or by directing us to delete your personal information, as described in this Privacy Notice. </p>

              <b>Other California Privacy Rights</b>
              <p>California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Site that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to privacy@nijineuro.com. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CopyRightSection />
    </>
  );
};

export default PrivacyPolicy;
