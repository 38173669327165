import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div<{ visible: boolean }>`
  transition: .3s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  z-index : 1;
  ${({ visible }) => (visible ? `
    opacity: 1;
    visibility: visible;
  ` : '')}
`;

const ModalContent = styled.div`
  width: 600px;
  padding: 25px;
  border-radius: 6px;
  background-color: white;
`;

interface ModalProps {
  visible: boolean;
}

const Modal: React.FunctionComponent<ModalProps> = ({ visible, children }) => (
  <Overlay visible={visible}>
    <ModalContent>
      {children}
    </ModalContent>
  </Overlay>
);

export default Modal;
