import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ open, setOpen , handleClose, dataForPopup, selectedSection}) {
  
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{"display":"flex","justifyContent":"space-between"}}>
          {( typeof(dataForPopup[selectedSection])!== 'undefined' && typeof(dataForPopup[selectedSection]['heading'])!== 'undefined')?dataForPopup[selectedSection]['heading']:""}
          <CloseIcon className='close-btn' onClick={()=>{handleClose()}}/>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, "display":"flex","justifyContent":"center","alignItems":"flex-start" }}>
            
            {( typeof(dataForPopup[selectedSection])!== 'undefined' && typeof(dataForPopup[selectedSection]['imageUrl'])!== 'undefined')? (<img src={dataForPopup[selectedSection]['imageUrl']} style={{ height: "80px", padding:"15px"}} />):""}
            {( typeof(dataForPopup[selectedSection])!== 'undefined' && typeof(dataForPopup[selectedSection]['data'])!== 'undefined')?(<p className="insightspopuptext">{dataForPopup[selectedSection]['data']}</p>):""}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
