import React, { FormEvent, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMutation } from "react-query";
import Spacer from "../../components/Spacer";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { H1, P } from "../../components/Typography";
import TextLink from "../../components/TextLink";
import api from "../../api";
import { CheckEmailData, RegisterData } from "../../api/auth";
import useErrors from "../../utils/useErrors";
import { ErrorResponse, Response } from "../../typings/response";

import emailIcon from "../../assets/icons/email.svg";
import profileIcon from "../../assets/icons/profile.svg";
import passwordIcon from "../../assets/icons/password.svg";
import warning from "../../assets/images/warning.svg";
import brainBg from "../../assets/images/brain-bg.svg";
import Header from "../../components/Header";
import Page from "../../components/Page";
import { smallerThan } from "../../utils/grid";
import { colors, fontsize } from "../../config/styles";
import "./style.css";

const StyledPage = styled(Page)`
  background-image: url(${brainBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -250px center;

  ${smallerThan("md")} {
    background-image: none;
  }
`;

const SuccessMessage = styled.p`
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: ${colors.mainGreen};
  color: white;
  border-radius: 6px;
`;

const ErrorMessage = styled.p`
  color: ${colors.error};
  font-size: ${fontsize.inputError};
`;

const Main: React.FunctionComponent = () => {
  const [register, { error, status }] = useMutation<
    Response<{ token: string }>,
    RegisterData,
    ErrorResponse
  >(api.auth.register);
  const [checkEmail, { error: checkEmailError, status: checkEmailStatus }] =
    useMutation<Response<{ success: boolean }>, CheckEmailData, ErrorResponse>(
      api.auth.checkEmail
    );

  const [email, setEmail] = useState("");
  const [passwordLenghtError, setPasswordLenghtError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [success, setSuccess] = useState(false);

  const [emailChecked, setEmailChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [ageChecked, setAgeChecked] = useState(false);
  const errors = useErrors(error?.response || checkEmailError?.response);

  const validateEmail = (testEmail: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(testEmail.toLowerCase());
  };

  const onButtonClick = async (e) => {
    e.preventDefault();
    console.log(password.length);
    try {
      const response = await register({
        firstName,
        middleInitial,
        lastName,
        email,
        password,
        passwordRepeat,
      });

      if (response.status === 201) {
        setSuccess(true);
        //clear the form : 
        setFirstName('');
        setMiddleInitial('');
        setLastName('');
        setPassword('');
        setPasswordRepeat('');

      }
    } catch (err) {
      console.warn(err);
    }
  };

  const checkEmailClick = async (e) => {
    e.preventDefault();
    try {
      const response = await checkEmail({
        email,
      });

      if (response.data.success) {
        setEmailChecked(true);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleEmailInput = (e: FormEvent<HTMLInputElement>) => {
    if (validateEmail(e.currentTarget.value)) {
      setEmail(e.currentTarget.value);
    } else {
      setEmail("");
    }
  };

  const handleInputChange =
    (setter: (value: string) => void) => (e: FormEvent<HTMLInputElement>) => {
      setter(e.currentTarget.value);
    };

  const buttonDisabled = emailChecked
    ? !email ||
      !firstName ||
      !lastName ||
      !password ||
      !passwordRepeat || 
      success
    : !email;

  const isEmailTaken =
    checkEmailError?.response.data.errors.some(
      (localError) => localError.email && localError.email.taken
    ) ||
    error?.response.data.errors.some(
      (localError) => localError.email && localError.email.taken
    );

  const accountExistsMessage = (
    <ErrorMessage>
      There is already an account registered with that email address.{" "}
      <Link to="/login">Click here</Link> to login
    </ErrorMessage>
  );

  return (
    <StyledPage>
      <Header mode="signin" />
      <Container>
        <Row>
          <Col md={8} lg={5} xl={6} offset={{ md: 2, lg: 7, xl: 6 }}>
            <form>
              <H1 align="center" size={45} >
                {(emailChecked && success) ? "Get Started!" : "Get Started!" }
              </H1>
              <Spacer top={15}>
                    {!emailChecked && <Spacer>
                      <div className="onlyBetaUsers">
                          <div className="warningIcon">
                              <img src={warning} alt="warning icon" />
                          </div>

                          <p>
                          We are in beta testing mode. If you would like to be on our list of testers, sign up here.
                          </p>
                        </div>
                      </Spacer> }
              </Spacer>
              {(emailChecked && success) && <Spacer top={35}><SuccessMessage className="verfication-message">Verification link was sent to your email ({email})
                  address</SuccessMessage></Spacer>}
              {!success && <Spacer top={35}>
                <Input
                  id="username"
                  error={isEmailTaken ? accountExistsMessage : errors.email}
                  placeholder="E-mail"
                  onChange={handleEmailInput}
                  icon={emailIcon}
                  disabled={success}
                />
              </Spacer>}
              
              {emailChecked && !success && (
                <>
                  <Spacer top={15}>
                    <Input
                      error={errors.firstName}
                      placeholder="First Name"
                      value={firstName}
                      onChange={handleInputChange(setFirstName)}
                      icon={profileIcon}
                      disabled={success}
                    />
                  </Spacer>
                  <Spacer top={15}>
                    <Input
                      error={errors.middleInitial}
                      placeholder="Middle Initial (optional)"
                      onChange={handleInputChange(setMiddleInitial)}
                      icon={profileIcon}
                      disabled={success}
                      value={middleInitial}
                    />
                  </Spacer>
                  <Spacer top={15}>
                    <Input
                      error={errors.lastName}
                      placeholder="Last Name"
                      onChange={handleInputChange(setLastName)}
                      icon={profileIcon}
                      disabled={success}
                      value={lastName}
                    />
                  </Spacer>
                  <Spacer top={15}>
                    <Input
                      id="password"
                      type="password"
                      error={errors.password}
                      placeholder="New Password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (
                          e.target.value.length == 1 ||
                          e.target.value.length < 6
                        ) {
                          var tempSpaceToUniqueTheError = "";
                          var tempError =
                            "Password Must Be Longer Than or Equal To 6 Characters";
                          setPasswordLenghtError(tempError);
                        }
                        if (e.target.value.length == 6) {
                          setPasswordLenghtError("");
                        }
                      }}
                      icon={passwordIcon}
                      disabled={success}
                      value={password}
                    />
                    {passwordLenghtError.length > 1 ? (
                      <ErrorMessage>
                        Password Must Be Longer Than or Equal To 6 Characters
                      </ErrorMessage>
                    ) : (
                      ""
                    )}
                  </Spacer>
                  <Spacer top={15}>
                    <Input
                      type="password"
                      error={errors.passwordRepeat}
                      placeholder="Retype New Password"
                      onChange={handleInputChange(setPasswordRepeat)}
                      icon={passwordIcon}
                      disabled={success}
                      value={passwordRepeat}
                    />
                  </Spacer>
                </>
              )}
              <Spacer top={45} bottom={45}>
                <Row>
                  {!emailChecked && (
                    <Col sm={8} offset={{ sm: 2 }}>
                      <Button
                        size={25}
                        disabled={!email || !termsChecked || !ageChecked}
                        onClick={checkEmailClick}
                        loading={checkEmailStatus === "loading"}
                      >
                        Continue
                      </Button>
                    </Col>

                  )}
                  {emailChecked &&  !success && (
                    <Col sm={8} offset={{ sm: 2 }}>
                      <Button
                        size={25}
                        disabled={buttonDisabled}
                        onClick={onButtonClick}
                        loading={status === "loading"}
                      >
                        Sign up
                      </Button>
                    </Col>
                  )}
                </Row>
              </Spacer>
              {!emailChecked && (
                <Spacer className="termsAndConditions"> 
                  <input className="checkBox" type="checkbox" onChange={() => {setTermsChecked(!termsChecked)}}/>
                  <P size={18}>
                    By signing up, I agree to Niji’s{" "}
                    <Link to="/terms-of-service" style={{textDecoration: "none"}}>Terms of Service</Link> and {" "}
                    <Link to="/privacy-policy"  style={{textDecoration: "none"}}>Privacy Policy</Link>
                  </P>
                </Spacer>
              )}
              {!emailChecked && (
                <Spacer className="above18"> 
                  <input className="checkBox" type="checkbox" onChange={() => {setAgeChecked(!ageChecked)}}/>
                  <P size={18}>
                    I confirm that I am 18 years or older to use this website.
                  </P>
                </Spacer>
              )}
              
            </form>
          </Col>
        </Row>
      </Container>
    </StyledPage>
  );
};

export default Main;
