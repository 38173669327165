import React, { useEffect, useState } from 'react';

const Tutorial = ({ feedbackColor, setIsNextDisabled , nextStep ,takeToStep}) => {
  const [lightBox, setLightBox] = useState(false);
  const [lightOff, setLightOff] = useState(false);
  const [umounted, setUmounted] = useState(false);

  

  useEffect(() => {
    setIsNextDisabled(!lightOff);
  }, [lightOff, setIsNextDisabled]);

  useEffect(() => {
    const identifyPressKey = ({ key }) => {
      const pressedKey = key.toUpperCase();
  
      if (!umounted && pressedKey === 'C' && !lightOff) {
        // document.removeEventListener('keydown', identifyPressKey);
        setLightOff(true);
        takeToStep(4);
      }
    };
    // console.log("useeffrect for c ");
    setLightBox(true);
    document.addEventListener('keydown', identifyPressKey);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      setUmounted(true);
      console.log("removing of c");
      document.removeEventListener('keydown', identifyPressKey);
    }
  }, []);

  return (
    <div className="game-instruction-container">
      <div className="game-instruction-content">
        <div className="game-container-tutorial">
          <div
            className={`box box-small ${
              !lightOff && lightBox ? 'box--selected' : ''
            } ${feedbackColor ? `box--selected--${feedbackColor}` : ''}`}
          >
            <span className="box-text--small">
              {!lightOff && lightBox ? 'C' : ''}
            </span>
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
        </div>
        <div className="game-instruction-text">
        When the left light comes on, your job is to turn it off by 
        pressing the letter C on your keyboard. <br/> Press C now.
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
