import React, { useEffect, useState } from 'react';

const Tutorial = ({ feedbackColor ,  nextStep }) => {
  
  useEffect(() => {
    // setIsNextDisabled(true);
    
  }, []);
  return (
    <div className="game-instruction-container">
      <div className="game-instruction-content">
        <div className="game-container-tutorial">
          <div
            className={`box box-small box--selected ${
              feedbackColor ? `box--selected--${feedbackColor}` : ''
            }`}
          >
            <span className="box-text--small" />
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
          <div className="box box-small">
            <span className="box-text--small" />
          </div>
        </div>
        <div className="game-instruction-text">
          When a light comes on, your job is to turn it off as quickly as possible by pressing a 
          key on your keyboard.
          
        </div>
      </div>
    </div>
  )
};

export default Tutorial;
