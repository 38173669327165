import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledFlash = styled.div`
  @keyframes flashBackground {
    from {
      background-color: transparent;
      display: block;
    }
    to {
      background-color: rgba(255, 0, 0, 0.5);
      display: none;
    }
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation-name: flashBackground;
  animation-duration: 0.1s;
`;

const StyledFlashMessage = styled.div<Props>`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
  color: #FFF;

  @keyframes flashMessage {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  //animation-name: flashMessage;
  animation-duration: ${({ messageDurationSeconds }) => (messageDurationSeconds - 0.5)}s;
`;

interface Props {
  messageDurationSeconds: number;
  screenFlashed: boolean;
  setScreenFlashed: (value: boolean) => void;
}

const Flasher: React.FunctionComponent<Props> = (props) => {
  const { screenFlashed, setScreenFlashed, messageDurationSeconds } = props;
  const [flashVisible, setFlashVisible] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const flashRef = useRef<any>(null);
  const messageRef = useRef<any>(null);

  if (screenFlashed && !flashRef.current && !messageRef.current) {

  }

  useEffect(() => {
    if (screenFlashed) {
      setFlashVisible(true);
      setMessageVisible(true);
      flashRef.current = setTimeout(() => {
        setFlashVisible(false);
        setScreenFlashed(false);
        flashRef.current = null;
      }, 300);
      if (messageRef.current) {
        clearTimeout(messageRef.current);
      }
      messageRef.current = setTimeout(() => {
        setMessageVisible(false);
        messageRef.current = null;
      }, (messageDurationSeconds * 1000) + 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenFlashed]);

  return (
    <>
      {flashVisible && <StyledFlash />}
      {messageVisible && (
        <StyledFlashMessage {...props}>
          Check your hand position.
        </StyledFlashMessage>
      )}
    </>
  );
};

export default Flasher;
