import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { colors } from '../../config/styles';

const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ArrowsContainer = styled.div<{ small?: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 35px;
  transform: translateX(50%);

  ${({ small }) => (small ? `
    right: 13px;
` : '')}
  .arrow {
    font-size: 1.8rem;
    opacity: 0.5;
    cursor: pointer;
  }
  .arrow:hover {
    opacity: 1;
  }
  .arrow:first-child {
    margin-bottom: -5px;
  }
`;

const StyledSelect = styled.select<{ withPlaceholder: boolean, small?: boolean }>`
  transition: .1s ease-in-out;
  border: 1px solid ${colors.border};
  height: 82px;
  width: 100%;
  border-radius: 6px;
  color: ${({ withPlaceholder }) => (withPlaceholder ? colors.placeholder : colors.text)};
  font: 400 25px Roboto;
  padding: 0 70px 0 25px;
  background-color: white;
  text-overflow: ellipsis;

  ${({ small }) => (small ? `
    height: 100%;
    border: none;
    padding: 0;
  ` : '')}

  &:focus {
    border-color: ${colors.mainGreen};
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

interface Props {
  value?: string;
  onChange: (value: ChangeEvent<HTMLSelectElement> | string) => void;
  placeholder?: string;
  children?: string[];
  small?: boolean;
}

const Select: React.FunctionComponent<Props> = ({
  value = '', onChange, placeholder, children, small,
}) => {
  const onUpArrowClick = () => {
    const selectedIndex = children.findIndex((item) => item === value);
    if (selectedIndex > 0) {
      onChange(children[selectedIndex - 1]);
    }
  };

  const onDownArrowClick = () => {
    const selectedIndex = children.findIndex((item) => item === value);
    if (selectedIndex < children.length - 1) {
      onChange(children[selectedIndex + 1]);
    }
  };
  return (
    <SelectContainer>
      <StyledSelect withPlaceholder={value === ''} value={value} onChange={onChange} small={small}>
        <option value="" disabled style={{ color: colors.placeholder, overflowWrap :"break-word" }}>{placeholder}</option>
        {children.map((child) => <option key={child} value={child}>{child}</option>)}
      </StyledSelect>
      <ArrowsContainer small={small}>
        <FaCaretUp className="arrow" onClick={onUpArrowClick} />
        <FaCaretDown className="arrow" onClick={onDownArrowClick} />
      </ArrowsContainer>
    </SelectContainer>
  );
};

export default Select;
