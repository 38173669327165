import React, { useEffect , useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import Loader from 'react-spinners/SyncLoader';
import api from '../../api';
import AuthActions from '../../redux/auth/actions';
import HeaderV2 from '../../components/HeaderV2';
import CopyRightSection from "../../components/Footer/copyrightSection"
import "./style.css";
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo_v2.svg';
import Spinner from '../../assets/icons/spinner.svg';
import nijiLogo from '../../assets/images/Logo.png';
import LoaderForApp from '../Loader';
import styled, { keyframes } from 'styled-components';
import { colors } from '../../config/styles';

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const spinning = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Image = styled.img`
  animation-name: ${spinning};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

type LoaderProps = {
  text?: string;
  image?: string;
};

const Text = styled.p`
  color: white;
  max-width: 300px;
  text-align: center;
  margin-top: 30px;
`;



const Verify: React.FunctionComponent = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [verify] = useMutation(api.auth.verifyEmail);
  const [userVerificatoinStatus ,setUserVerificatoinStatus ] = useState("");


  useEffect(() => {
    const handleToken = async () => {
      try {
        const response = await verify(token);
        setUserVerificatoinStatus("verified");
        setTimeout(() => {
          dispatch(AuthActions.authorizeUser(response.data.token));
          history.push('/dashboard');
        }, 4000);
      } catch (_) {
        setUserVerificatoinStatus("Somethingwentwrong");
        setTimeout(() => {
          history.push('/login');
        }, 4000);
      }
    };

    if (token) {
      // eslint-disable-next-line no-void
      void handleToken();
    }
  }, [token, history, verify, dispatch]);
  if(userVerificatoinStatus == ""){
    return <div className="fullscreenmessage"> 
          <a href={process.env.REACT_APP_APPLICATION_URL} className="logoOnTop">
              <img src={Logo} />
          </a>
          <div className="displayCenterMesage">
              <Image src={Spinner} alt="Spinner"/>
          </div>
        </div>;
  }else if(userVerificatoinStatus == "verified"){
    return <div className="fullscreenmessage"> 
              <a href={process.env.REACT_APP_APPLICATION_URL} className="logoOnTop">
                  <img src={Logo} />
              </a>
              <div className="displayCenterMesage">
                  <Image src={Spinner} alt="Spinner"/>
                  <div >
                    <div style={{"fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"29px","lineHeight":"53px", "textAlign" :"center" , "marginTop"  :"35px"}}>
                        Email Verified
                    </div>
                    <div style={{"fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"53px", "textAlign" :"center" ,}}>
                        We are redirecting  you to Dashboard
                    </div>
                  </div>
              </div>
            </div>;
  }else{
    return <div className="fullscreenmessage"> 
              <a href={process.env.REACT_APP_APPLICATION_URL} className="logoOnTop">
                  <img src={Logo} />
              </a>
              <div className="displayCenterMesage">
                  <Image src={Spinner} alt="Spinner"/>
                  <div >
                    <div style={{"fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"29px","lineHeight":"53px", "textAlign" :"center" , "marginTop"  :"35px"}}>
                        Something Went wrong
                    </div>
                    <div style={{"fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"53px", "textAlign" :"center" ,}}>
                        We are redirecting  you to Login
                    </div>
                  </div>
              </div>
            </div>;
  }
};

export default Verify;
