import React, { useEffect, useState } from 'react';
import Image from '../../../../assets/images/image_computer.png';

const Tutorial = ({ setIsNextDisabled , nextStep, takeToStep}) => {
  const [umounted, setUmounted] = useState(false);

  


  useEffect(() => {
    const identifyPressKey = ({ key }) => {
      if(!umounted){
        const pressedKey = key.toUpperCase();
        // document.removeEventListener('keydown', identifyPressKey);
        setIsNextDisabled(false);
        takeToStep(3);
      }
    };

    document.addEventListener('keydown', identifyPressKey);
    setIsNextDisabled(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      setUmounted(true);
      console.log("removing of any key of image");
      document.removeEventListener('keydown', identifyPressKey);
    }
  }, []);

  return (
    <div className="game-instruction-content--with-image">
      <div className="game-instruction-text">
        Please arrange your index and middle fingers on the C, V, N, and M keys on the keyboard exactly as shown below.
        <br />
        Place your fingers on the keys now and press any of those four keys to continue.
      </div>
      <img src={Image} alt="instructions" className="instructions-image" />
    </div>
  )
};

export default Tutorial;
