import React from 'react';
import { useRef} from 'react';
import './style.css';
import whiteLogo from '../../assets/icons/logo-white.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Header from './header';
import BannerSection from './bannerSection';
import Aboutus from './aboutus';
import Services from './services';
import Insights from './insights';
import Contactus from './contactus';
import CopyrightSection from '../../components/Footer/copyrightSection';
import Feedback from '../../components/Feedback';
const pages = ['Home', 'About Us', 'Solutions','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const LandingPage=()=>{
    const homeSection = useRef(null);
    const aboutUsSection = useRef(null);
    const servicesSection = useRef(null);
    const insightsSection = useRef(null);
    const contactUsSection = useRef(null);

    const executeScroll = (sectionName: string) => {
      var element = document.getElementById('homeSection');
      

      if(sectionName== "Home"){
        element = document.getElementById('homeSection');
      }else if(sectionName== "About Us"){
        element = document.getElementById('aboutusSection');
      }else if(sectionName== "Solutions"){
        element = document.getElementById('serviceSection');
        
      }else if(sectionName== "Insights"){
        element = document.getElementById('insightsSection');
        
      }else if(sectionName== "Contact"){
        element = document.getElementById('contactusSection');
        
      }else if(sectionName== "Partner with us"){
        element = document.getElementById('contactusSection');
        
      }else{
        element = document.getElementById('homeSection');
        
      }
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });

    }    

    return  <><div>
                <Header executeScroll={executeScroll} 
                        homeSection={homeSection}
                        pages={pages}
                />
                <div ref={homeSection} id="homeSection">
                  <BannerSection executeScroll={executeScroll}/>
                </div>
                <div ref={aboutUsSection} id="aboutusSection">
                  <Aboutus />
                </div>
                <div ref={servicesSection} id="serviceSection">
                  <Services />

                </div>
                <div ref={insightsSection} id="insightsSection">
                  <Insights />
                </div>
                <div ref={contactUsSection} id="contactusSection">
                  <Contactus />
                </div>
                <CopyrightSection />
              </div>
              </>
}
export default LandingPage;