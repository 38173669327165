import React, { useState, useEffect } from 'react';

interface Trial {
  round: number;
  letter: string;
  responses: { pressedKey: string }[];
}

const StartPage = ({ setIsNextDisabled, feedbackColor, setPracticeResults: savePractice, nextStep , flashScreen, takeToStep}) => {
  const keys = ['C', 'V', 'N', 'M'];

  const [practiceResults, setPracticeResults] = useState<Trial[]>([]);
  const [keysOrder] = useState([...keys].sort(() => 0.5 - Math.random()));
  const [lightBox, setLightBox] = useState(null);
  const [lightOff, setLightOff] = useState(false);

  const identifyPressKeyStartpage = ({ key }) => {
    const pressedKey = key.toUpperCase();
    let currentLight = null;
    let practiceResultsLocal = null;

    setLightBox((prev) => {
      currentLight = prev;
      return prev;
    });

    setPracticeResults((prev) => {
      practiceResultsLocal = prev;
      return prev;
    });

    const currentLightIndex = keysOrder.findIndex((val) => val === currentLight);

    if (practiceResultsLocal != null && practiceResultsLocal[currentLightIndex]) {
      const copy = [...practiceResultsLocal];
      copy[currentLightIndex].responses.push({ pressedKey });
      setPracticeResults(copy);
    } else {
      setPracticeResults((prev) => [...prev, {
        round: currentLightIndex + 1,
        letter: currentLight,
        responses: [{ pressedKey }],
      }]);
    }

    if (pressedKey === currentLight) {
      if (currentLightIndex >= keys.length - 1) {
        setLightBox(null);
        setLightOff(true);
        document.removeEventListener('keydown', identifyPressKeyStartpage);
      } else {
        setLightBox(null);
        setTimeout(() => {
          setLightBox(keysOrder[currentLightIndex + 1]);
        }, 500);
      }
    }else{
      // flashScreen();
    }
  };

  useEffect(() => {
    setIsNextDisabled(!lightOff);

    if (lightOff) {
      savePractice(practiceResults);
      //click the next button : 
      takeToStep(8);
    }
  }, [lightOff, setIsNextDisabled, practiceResults, savePractice]);

  useEffect(() => {
    setTimeout(() => {
      setLightBox(keysOrder[0]);
      document.addEventListener('keydown', identifyPressKeyStartpage);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      document.removeEventListener('keydown', identifyPressKeyStartpage);
    }
  }, []);

  return (
    <div className="game-instruction-container">
      <div className="game-instruction-content">
        <span className="game-instruction-text">
        Now let’s press each key once to practice. When a light 
        comes on, turn it off as quickly as possible. 
        <br />
        You have to press the correct key for the
        light to turn off.
        </span>
        <div className="game-container-tutorial">
          {keys.map((key) => (
            <div
              key={key}
              className={`box box-small ${
                !lightOff && lightBox === key
                  ? `box--selected ${
                    feedbackColor ? `box--selected--${feedbackColor}` : ''
                  }`
                  : ''
              } `}
            >
              <span className="box-text--small">{key}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartPage;
