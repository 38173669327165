import React, {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { colors , fontsize } from '../../config/styles';
import usePrevious from '../../utils/usePrevious';
import { InputVariants } from '../../typings/questions';
import Select from '../Select';
import UnitInput from '../UnitInput';
import 'react-datepicker/dist/react-datepicker.css';

interface BaseProps {
  type?: InputVariants | string;
  onChange: (event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | string) => void;
  value?: string;
  error?: string | JSX.Element;
  icon?: string;
  children?: string[];
  innerRef?: any;
}

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input<BaseProps>`
  transition: .1s ease-in-out;
  border: 1px solid ${({ error }): string => (error ? colors.error : colors.border)};
  height: 82px;
  width: calc(100% - 50px);
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  color: ${colors.text};
  font-size: 25px;
  padding: 0 25px;
  
  ${({ icon }) => (icon ? 'padding-left: 60px;' : '')}
  
  &:focus {
    border-color: ${colors.mainGreen};
  }
  
  &::placeholder {
    color: ${colors.placeholder}
  }
`;

const InputError = styled.p`
  color: ${colors.error};
  text-transform: capitalize;
  font-size: ${fontsize.inputError};
  margin-top: 5px;

  a {
    color: ${colors.mainGreen};
  }
`;

const InputImage = styled.img`
  position: absolute;
  left: 30px;
  transform: translateX(-50%);
`;

const Input: React.FunctionComponent<BaseProps & React.HTMLProps<HTMLInputElement>> = (props) => {
  const {
    id, error, placeholder, onChange, value, type, icon, innerRef, disabled, onKeyPress,
  } = props;

  const prevError = usePrevious(error);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (error && !prevError) {
      setShouldDisplayError(true);
    }
  }, [error, prevError, setShouldDisplayError]);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setShouldDisplayError(false);
    onChange(e);
  };

  let inputValue = value;
  const dateType = focused ? 'date' : 'text';

  if (type === 'date' && dateType === 'text' && value) {
    const [year, month, day] = value.split('-');
    inputValue = `${month}/${day}/${year}`;
  }

  return (
    <InputContainer>
      <InputWrapper>
        {icon && <InputImage src={icon} alt={placeholder} />}
        <StyledInput
          id={id}
          error={shouldDisplayError ? error : ''}
          placeholder={placeholder}
          onChange={handleChange}
          value={inputValue}
          type={type === 'date' ? dateType : type}
          icon={icon}
          ref={innerRef}
          onFocus={() => setFocused(true)}
          disabled={disabled}
          onBlur={() => setFocused(false)}
          onKeyPress={onKeyPress}
        />
      </InputWrapper>
      {shouldDisplayError && <InputError>{error}</InputError>}
    </InputContainer>
  );
};

const DateInputWrapper = styled.div<{error?: boolean; icon?: string;}>`
  transition: .1s ease-in-out;
  border: 1px solid ${({ error }): string => (error ? colors.error : colors.border)};
  height: 82px;
  width: calc(100% - 50px);
  border-radius: 6px;
  font-family: Roboto;
  font-weight: 400;
  color: ${colors.text};
  font-size: 17px;
  padding: 0 25px;
  display: flex;
  
  ${({ icon }) => (icon ? 'padding-left: 60px;' : '')}
  
  &:focus {
    border-color: ${colors.mainGreen};
  }
  
  &::placeholder {
    color: ${colors.placeholder}
  }

  & > *:nth-child(1) {
    flex-grow: 1;
    border-right: 1px solid #d5d7db;
  }
  
  & > *:nth-child(2) {
    flex-grow: 1;
    padding-left: 15px;
    border-right: 1px solid #d5d7db;
  }

  & > *:nth-child(3) {
    padding-left: 15px;
    flex-grow: 2;
  }
`;

const DateInputContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 5px;
    font-size: 14px;
    color: ${colors.placeholder} !important;
  }
`;

interface DateInputProps {
  initialValue?: string;
  placeholder: string;
  onChange: (date?: string) => void;
  name: string;
}

const DateInput: React.FunctionComponent<DateInputProps> = React.memo(({ initialValue, placeholder, onChange, name }) => {
  const [day, setDay] = useState<string | undefined>();
  const [month, setMonth] = useState<string | undefined>();
  const [year, setYear] = useState<string | undefined>();

  const getHandler = (handler: (value: any) => void) => (event: ChangeEvent<HTMLSelectElement>) => {
    if (typeof event === 'string') {
      handler(event);
    } else {
      handler(event.target.value);
    }
  };

  const error = day && month && year && !moment(`${month}/${day}/${year}`, 'MM/DD/YYYY').isValid();

  useEffect(() => {
    if (initialValue) {
      const [monthPart, dayPart, yearPart] = initialValue.split('/');
      setDay(dayPart);
      setMonth(monthPart);
      setYear(yearPart);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!error) {
      onChange(`${month}/${day}/${year}`);
    } else {
      onChange();
    }
  }, [day, month, year, error, onChange]);

  return (
    <DateInputContainer>
      <span>{placeholder}</span>
      <DateInputWrapper error={error}>
        <Select
          small
          placeholder="MM"
          value={month}
          onChange={getHandler(setMonth)}
        >
          {new Array(12).fill(null).map((_, index) => (index + 1).toString().padStart(2, '0'))}
        </Select>
        <Select
          small
          placeholder="DD"
          value={day}
          onChange={getHandler(setDay)}
        >
          {new Array(31).fill(null).map((_, index) => (index + 1).toString().padStart(2, '0'))}
        </Select>
        <Select
          small
          placeholder="YYYY"
          value={year}
          onChange={getHandler(setYear)}
        >
          {new Array(120).fill(null).map((_, index) => (moment().get('year') - 120 + index + 1).toString())}
        </Select>
      </DateInputWrapper>
    </DateInputContainer>
  );
}, () => false);

const InputManager: React.FunctionComponent<BaseProps & React.HTMLProps<HTMLInputElement>> = ({
  type,
  ...rest
}) => {
  switch (type) {
    case InputVariants.DATE:
      return (
        <DateInput
          name={rest.name}
          // @ts-ignore
          onChange={(date) => rest.value !== date && rest.onChange({ currentTarget: { value: date } })}
          placeholder={rest.placeholder}
          initialValue={rest.value}
        />
      );
    case InputVariants.HEIGHT:
      return (
        <UnitInput
          {...rest}
          units={[
            { label: 'FT', mask: [/\d/, '’', /\d/, '’’'], validLength: 5 },
            { label: 'CM ', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
          ]}
        />
      );
    case InputVariants.SELECT:
      return <Select {...rest} />;
    case InputVariants.TEXT:
      return <Input {...rest} type={type} />;
    case InputVariants.WEIGHT:
      return (
        <UnitInput
          {...rest}
          units={[
            { label: 'LBS', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
            { label: 'KG', mask: [/\d/, /\d/, /\d/], minValidLength: 2 },
          ]}
        />
      );
    default:
      return <Input {...rest} type={type} />;
  }
};

export default InputManager;
