import React from 'react';
import './style.css';
import whiteLogo from '../../assets/icons/logo-white.svg';
import {
  Switch, Route, Redirect, useHistory,
} from 'react-router-dom';
// React.useEffect(() => {
//     const url = window.location.href.split('/');
//     if (url.includes('niji-qa')) {
//       localStorage.setItem('qa-mode', 'on');
//     }
//   }, []);
const CommingSoon=()=>{
    const url = window.location.href.split('/');
    if (url.includes('niji-qa')) {
      localStorage.setItem('qa-mode', 'on');
      return <Redirect to="/login" />
    }

    return <div className="comming-soon-container">
        <img src={whiteLogo} alt="Nijineuro App" />
        <p><b>Welcome to Niji Neuro.</b><br/>

            Please view this website on a desktop or laptop. <br />

            Mobile friendly site is coming soon</p>
    </div>
}
export default CommingSoon;