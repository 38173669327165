import React, { useEffect, useState } from "react";
import "./style.css";
import whiteLogo from "../../assets/icons/logo-white.svg";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Header from "./header";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import contactUSIcon from "../../assets/images/landingpage/contact-us-icon.svg";
import goto from "../../assets/images/landingpage/goto.svg";
import successLogo from "../../assets/images/landingpage/contact-form-success.svg";
import FailureLogo from "../../assets/images/landingpage/contact-failure.svg";
import cross from "../../assets/images/landingpage/cross.svg";
import line from "../../assets/images/landingpage/line.svg";
import phone from "../../assets/images/landingpage/phone.svg";
import contactNumber from "../../assets/images/landingpage/Contact - Phone.svg";
import mail from "../../assets/images/landingpage/Contact - eMail.svg";
import location from "../../assets/images/landingpage/Contact - Pin.svg";
import facebook from "../../assets/images/landingpage/Contact - Facebook.svg";
import twitter from "../../assets/images/landingpage/Subtract.svg";
import LogoV2 from "../../assets/images/landingpage/Contact - Map logo.svg";
import curvShape from "../../assets/images/landingpage/courveShape.svg";
import { InputContainer } from "../../components/Input";
import ValidationTextFields from "../../components/TextFieldValidation";
import { TextareaAutosize, TextField } from "@mui/material";
import Modal from '@mui/material/Modal';
import Button from "../../components/Button";
import Dialog from '@mui/material/Dialog';
import { useMutation } from "react-query";
import { ErrorResponse, Response } from "../../typings/response";
import { CheckEmailData, RegisterData ,contactUsData } from "../../api/auth";
import api from "../../api";
const pages = [
  "Home",
  "About Us",
  "Service",
  "Insights",
  "Contact",
  "Partner with us",
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ContactUs = () => {
  const [formData, setFormData] = useState({ userName: "", userEmail: "", userMessage: "" });
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false)
  const [msgError, setMsgError] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const [status, setStatus] = useState("initial");
  const isLoading = status === 'loading';

  const [contactUsSaveAPI, { error, }] = useMutation<
    Response<{ code : number , status : boolean}>,
    contactUsData,
    ErrorResponse
  >(api.auth.contactUsSave);



  const validateEmail = (testEmail: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(testEmail.toLowerCase());
  };



  const handleChange = (e) => {
    const inputName = e.target.name;

    setFormData({
      ...formData,
      [inputName]: e.target.value,
    });

    if (inputName === "userEmail") {
      if (validateEmail(e.target.value)) {
        setEmailError(false);
      }
      else {
        setEmailError(true);
      }
    }
  };




  const saveMessage = async () => {
    try {
      setStatus("loading")
      let res = await contactUsSaveAPI(formData);
      console.log("Saving message success", res);
      (res.status) ? setStatus("success") : setStatus("failed")
    } catch (e) {
      console.warn("Error in saving the message", e)
    }
  }





  const handleSubmit = () => {
    if (validateEmail(formData.userEmail)) {
      setEmailError(false);
      saveMessage();
    }
    else {
      setEmailError(true);
    }
  };





  const inputs = [
    {
      label: "Name",
      isError: nameError,
      helperText: "Name should be at least more than 3 letters",
      inputName: "userName",
    },
    {
      label: "Email",
      isError: emailError,
      helperText: "Please enter a valid email",
      inputName: "userEmail",
    },
    // {
    //   label: "Message",
    //   isError: msgError,
    //   helperText: "Message should be at least 200 characters",
    //   inputName: "userMessage",
    // },
  ];

  useEffect(() => {
    if(status === "success") setTimeout(() => {setShowContactForm(false); setStatus("initial")}, 3000);
  
  })




  return (
    <div
      className="contact-stying-nobackground"
      style={{ position: "relative", height: "639px" }}
    >
      <Container
        maxWidth="lg"
        className="contectUsTextSection"
        sx={{
          padding: "4rem 3rem",
          margin: "0px",
          width: "50%",
          position: "absolute",
          bottom: "0",
          left: 0,
        }}
      >
        <Grid container spacing={2}>
          <img src={curvShape} className="curveShapscss" />
          <Grid item xs={12}>
            <div className="contact-d-flex">
              <img src={contactUSIcon} />
              <h2 className="contact-title">Contact</h2>
            </div>
          </Grid>
          <div className="contact-centent-position">
            <h2
              style={{
                width: "470px",
                left: "653px",
                top: "270px",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "32px",
                lineHeight: "40px",
              }}
            >
              Partner with us
            </h2>
            <div className="partner-content">
              Niji is seeking partners motivated to pursue outside-the-box
              approaches for the prevention and treatment of Alzheimer's and
              Cerebrovascular disease. Reach out to us!
            </div>
            <div className="niji-corp-title">Niji Corp.</div>

            <div>
              <Grid item xs={12}>
                <div className="contact-details-d-flex">
                  <img src={location} />
                  <div className="contact-details">
                    45 Prospect St., Cambridge, MA 02139-2402
                  </div>
                </div>
              </Grid>
            </div>

            <div className="writeToUs" onClick={() => { setShowContactForm(true) }}>
              <p className="writeToUs-text">Write to Us</p>
              <div className="writeToUs-arrow">
                <img src={goto} />
              </div>
            </div>

            <Grid item xs={12}>
              <div className="contact-social-d-flex">
                {/* <img  className="soical-spacing"  src={facebook}/> */}
                <a target="_blank" href="https://twitter.com/nijineuro">
                  {" "}
                  <img src={twitter} />
                </a>
              </div>
              {/* <div style={{ visibility: "hidden" }}>
                <Grid item xs={12}>
                  <div className="contact-details-d-flex">
                    <img src={contactNumber} />
                    <a href="tel:+15082630267" className="contact-details">
                      +1 508 263 0267
                    </a>
                  </div>
                </Grid>
              </div> */}
              <div style={{ visibility: "hidden" }}>
                <Grid item xs={12}>
                  <div className="contact-details-d-flex">
                    <img src={mail} />
                    <a
                      href="mailto: info@nijineuro.com"
                      className="contact-details"
                    >
                      info@nijineuro.com
                    </a>
                  </div>
                </Grid>
              </div>
            </Grid>
          </div>
        </Grid>
      </Container>
      <div
        className="contactusMapSection"
        style={{ width: "53%", position: "absolute", right: "0", bottom: "0" }}
      >
        <div className="google-map-code" style={{ position: "relative" }}>
          <img
            src={LogoV2}
            style={{
              position: "absolute",
              top: "50%",
              right: "41%",
              width: "11%",
            }}
          />
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2947.8752706688056!2d-71.10586558454348!3d42.366497379186406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x639f6c8c0f9e0dae!2zNDLCsDIxJzU5LjQiTiA3McKwMDYnMTMuMiJX!5e0!3m2!1sen!2sin!4v1644904527291!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0, width: "100%", height: "639px" }}
            aria-hidden="false"
          ></iframe>
        </div>
      </div>
      

      
      <div className="dialog-container">
        <Dialog
          open={showContactForm}
          onClose={()=>{setShowContactForm(false)}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{borderRadius: "5px"}}
        >
            <div className="contact-form-container">
              <div className="contact-form">

              <div className="cross" >
                <img src={cross} onClick={() => { setShowContactForm(false) }}/>
              </div>

              {status === "success" ?
                <div className="post-success">
                  <div>
                    <img src={successLogo} />
                  </div>

                  <div>
                    <p>Thank you for contacting us. We will get in touch with you shortly</p>
                  </div>

                  <div className="done" onClick={()=>{setShowContactForm(false)}}> DONE </div>

                </div> 
                : status === "failed" ? 
                  <div className = "post-success"> 
                    <div>
                      <img src={FailureLogo} />
                    </div>

                    <div>
                      <p>Oops <br/> Something went wrong <br/> Please try again</p>
                    </div>

                    <div className="failed done" onClick={()=>{setShowContactForm(false)}}> Retry </div>
                  </div>
                :
                <div>
                  <div className="contact-form-heading">
                    Let us know how we can help.
                  </div>

                  <div className="contact-input">
                    {inputs.map((ele, ind) =>
                      ele.isError ? (
                        <div key={ind}>
                          <TextField
                            error
                            id="filled-error-helper-text"
                            helperText={ele.helperText}
                            label={ele.label}
                            variant="filled"
                            onChange={handleChange}
                            sx={{
                              width: "100%",
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                                backgroundColor: "#ffffff",
                              },
                              '& .MuiFilledInput-input.MuiInputBase-input': {
                                backgroundColor: "#ffffff",
                                border: "1px solid #DCE0F4",
                                borderRadius: "0.5rem",
                                width: "100%",
                              },
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::before': {
                                border: "none !important",
                              },
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::after': {
                                border: "none !important",
                              },
                            }}
                            name={ele.inputName}
                          />
                        </div>
                      ) : (
                        <div key={ind}>
                          <TextField
                            id="filled-error-helper-text"
                            label={ele.label}
                            variant="filled"
                            onChange={handleChange}
                            sx={{
                              width: "100%",
                              color: "white",
                              background: "#ffffff",
                              backgroundColor: "#ffffff",
                              borderRadius: "5px",
                              border: "0px !important",
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                                backgroundColor: "#ffffff",
                                border: "0px"
                              },
                              '& .MuiFilledInput-input.MuiInputBase-input': {
                                backgroundColor: "#ffffff",
                                borderRadius: "0.5rem",
                                border: "1px solid #DCE0F4",
                              },
                              '& .MuiFormControl-root.MuiTextField-root': {
                                border: "0px"
                              },
                              '& .MuiFormControl-root.MuiTextField-root::before': {
                                border: "none !important",
                              },
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::before': {
                                border: "none !important",
                              },
                              '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::after': {
                                border: "none !important",
                              },
                              
                            }}
                            name={ele.inputName}
                            className="contact-input-field"
                          />
                        </div>
                      )
                    )}
     
                    <div key={"ind"}>
                      <TextareaAutosize
                        id="filled-error-helper-text"
                        placeholder="Message"
                        onChange={handleChange}
                        minRows={6}
                        maxRows={6}
                        style={{
                          width: "95%",
                          background: "#ffffff",
                          backgroundColor: "#ffffff",
                          borderRadius: "5px",
                          padding: "10px",
                          border: "1px solid #DCE0F4",
                          resize: "none",
                          fontFamily: "Roboto",
                          fontSize: "1rem",
                          lineHeight: "1.4375em",
                          letterSpacing: "0.00938em",
                          color: ""

                          // border: "0px !important",
                          // '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
                          //   backgroundColor: "#ffffff",
                          //   border: "0px"
                          // },
                          // '& .MuiFilledInput-input.MuiInputBase-input': {
                          //   backgroundColor: "#ffffff",
                          //   borderRadius: "0.5rem",
                          //   border: "1px solid #DCE0F4",
                          //   height :"60px"
                          // },
                          // '& .MuiFilledInput-input.MuiInputBase-input::before': {
                          //   border: "none !important",
                          // },
                          // '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::before': {
                          //   border: "none !important",
                          // },
                          // '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl::after': {
                          //   border: "none !important",
                          // },
                          // '& .MuiFormControl-root.MuiTextField-root': {
                          //   border: "0px"
                          // },
                          // '& .MuiFormControl-root.MuiTextField-root:before': {
                          //   border: "0px"
                          // },
                          // '& .MuiFormControl-root.MuiTextField-root::before': {
                          //   border: "none !important",
                          // },
                          
                        }}
                        name={"userMessage"}
                        className="contact-input-field"
                      />
                    </div>
                  </div>

                  <div className="contact-submit" >
                    <Button loading={isLoading} small={true} disabled={formData.userName.length < 1 || formData.userMessage.length < 1 || emailError} onClick={handleSubmit}  >
                      SUBMIT
                    </Button>
                  </div>

                  <div className="or">
                    <img src={line} />
                    <p>OR</p>
                    <img src={line} />
                  </div>

                  <div className="phone">
                    <p>We are reachable on phone :</p>
                    <div>
                      <img src={phone} style={{ margin: "0px 5px" }} />
                      +1 508 263 0267
                    </div>
                  </div>
                </div>}
              </div>
            </div>
        </Dialog>
      </div>
      
    </div>
  );
};
export default ContactUs;
