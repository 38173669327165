import React from 'react';

const Confirmation = ({ setIsNextDisabled }) => (
  <div className="game-instruction-container">
    <div className="game-instruction-content">
      <div className="game-instruction-text">
      Now, it’s time to play the full game. This will take about 10 minutes. Keep going until you reach the end.
      <br/>Make sure you keep your fingers on the correct keys. If you don’t press the key fast enough, the light will move to a new position.
      </div>
    </div>
  </div>
);

export default Confirmation;
