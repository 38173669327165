import { createGlobalStyle } from 'styled-components';

import RobotoThin from '../../assets/fonts/Roboto-Thin.ttf';
import RobotoLight from '../../assets/fonts/Roboto-Light.ttf';
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import RobotoMedium from '../../assets/fonts/Roboto-Medium.ttf';
import RobotoBold from '../../assets/fonts/Roboto-Bold.ttf';
import RobotoBlack from '../../assets/fonts/Roboto-Black.ttf';

const FontsInject = createGlobalStyle`
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoThin});
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight});
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoRegular});
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium});
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold});
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBlack});
    font-weight: 900;
    font-style: normal;
  }
`;

export default FontsInject;
