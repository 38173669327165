import React from 'react';
import './style.css';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import aboutusHero from '../../assets/images/landingpage/AboutUs-Hero.svg';
import titleImg from '../../assets/images/landingpage/AboutUs-title-design.svg';
import circle from '../../assets/images/landingpage/circle.svg';
import abtDesign from '../../assets/images/landingpage/about-design.svg';
import pEnd from '../../assets/images/landingpage/p-end.svg';
import waves from '../../assets/images/landingpage/waves.svg';

const pages = ['Home', 'About Us', 'Service','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const AboutUs=()=>{
    
    

    return <div className='about-us'>

              
             
        <Container maxWidth="xl">
              
                <Grid className="aboutUs-d-flex">
                <img src={titleImg}/>
                <img className='design-position' src={abtDesign} /> 
                <h2 className="aboutUs-title">About us</h2>
                </Grid>
              


                <Grid className='m-top' container spacing={0}>
                <Grid xs={6}>
               
                    <div className='about-img' >
                      <img src={aboutusHero} alt="Neuroimaging Clinical Trials" title="Neuroimaging Clinical Trials"/> 
                     
                    </div>
                </Grid>
                  <Grid item xs={6}>
                 
                 
                    <div>
                    
                        <h3 className='about-text'>
                        Motivated by amazing advances in neuroscience 
                        
                        </h3>
                        <img className='circle-position' src={circle}/>
                        <div className='about-sub-text'>
                            Every day, scientists worldwide are making significant discoveries about the brain and the positive and negative factors that impact brain health. Niji emerged from a desire to translate cutting-edge brain research into accessible technologies to support rapid discovery of novel therapeutics for neurological conditions with a focus on Alzheimer’s disease and Cerebrovascular disease.
                        </div>
                        <img className='para-end' src={pEnd} /> 
                    </div>
                   
                  </Grid>
                  
                  
                </Grid>
              
              </Container>
              <img className='wave-position' src={waves}/>
          </div> 
}
export default AboutUs;