import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import api from '../api';
import { RootState } from '../redux';

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = `${base64String}${padding}`
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const useNotifications = (): void => {
  const [updateSubscription] = useMutation(api.auth.updateSubscription);
  const auth = useSelector((state: RootState) => state.auth);
  const isAuthenticated = Boolean(auth.token && auth.expiration > Date.now());

  const initNotifications = useCallback(async () => {
    const permission = await window.Notification.requestPermission();

    if (permission !== 'granted') {
      return;
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      const workerRegistration = await navigator.serviceWorker.register('sw.js');
      let subscription = await workerRegistration.pushManager.getSubscription();

      if (!subscription) {
        subscription = await workerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array('BITSlX-ME6drHQIahbQzm0a9BYuh5nm0_D2Y-Srzg4JuGM-KGkzKQORLho--JDfLV-i-kJs1BVUJ3VOnCrcfL28'),
        });
      }

      await updateSubscription(subscription);
    }
  }, [updateSubscription]);

  useEffect(() => {
    if (isAuthenticated) {
      initNotifications();
    }
  }, [isAuthenticated, initNotifications]);
};

export default useNotifications;
