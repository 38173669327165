export const AUTHORIZE_USER = 'auth/AUTHORIZE_USER';
export const LOGOUT_USER = 'auth/LOGOUT_USER';

export interface AuthorizeAction {
  type: typeof AUTHORIZE_USER;
  payload: {
    expiration: number;
    token: string;
  };
}

export interface LogoutAction {
  type: typeof LOGOUT_USER;
}

export type AuthActionType = AuthorizeAction | LogoutAction;
