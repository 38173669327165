import React, { useEffect, useState } from 'react';

const PracticeRoundConfirmation = ({ setIsNextDisabled, nextStep }) => {

  const [umounted, setUmounted] = useState(false);
  const identifyPressKey = ({ key }) => {
    if(!umounted){
      const pressedKey = key.toUpperCase();
      document.removeEventListener('keydown', identifyPressKey);
      nextStep();
    }
  };


  useEffect(() => {
    setTimeout(() => {
      document.addEventListener('keydown', identifyPressKey);
    }, 800);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () =>{
      setUmounted(true);
      document.removeEventListener('keydown', identifyPressKey);
    }
  }, []);
  return (<div className="game-instruction-container">
            <div className="game-instruction-content">
              <div className="game-instruction-text">
                Good job! Now we will try a practice round. This time there will be no letters on the screen. When a light comes on, turn it off as quickly as you can with the corresponding C, V, N, or M key without pressing the wrong key!
              </div>
            </div>
          </div>)
};

export default PracticeRoundConfirmation;
