import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import FontsInject from './components/FontsInject';
import configureGrid from './utils/grid';

import Routes from './components/Routes';
import { store, persistor } from './redux';
import CommingSoon from './container/CommingSoon';

configureGrid();

const GlobalStyle = createGlobalStyle`
  * {
    outline: none;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: 'Roboto' !important;
  }
  
  input[type="date"] {
      display:block;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      min-height: 1.2em;
      background-color: white;
  }
`;

const App: React.FunctionComponent = () => {
  const [authorized, setAuthorized] = React.useState(localStorage.getItem('qa-mode'));

  // React.useEffect(() => {
  //   const url = window.location.href.split('/');
  //   if (url.includes('niji-qa')) {
  //     localStorage.setItem('qa-mode', 'on');
  //     setAuthorized('on');
  //   }
  // }, []);

  // if (!authorized) {
  //   return <CommingSoon />;
  // }
  if(typeof(window) !== 'undefined' &&  window.innerWidth < 600){
    return <CommingSoon />;
  }else{
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FontsInject />
          <GlobalStyle />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    );
  }
  
};

export default App;
