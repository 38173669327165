import React from 'react';
import { Link } from "react-router-dom";
import './style.css';
import whiteLogo from '../../assets/icons/logo-white.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoV2 from '../../assets/images/logo_v2.svg';
// const pages = ['Home' ,'About Us', 'Service','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

// React.useEffect(() => {
//     const url = window.location.href.split('/');
//     if (url.includes('niji-qa')) {
//       localStorage.setItem('qa-mode', 'on');
//     }
//   }, []);
const HeaderSection=({executeScroll ,homeSection : any = null, pages})=>{
    
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    

    return      <div>
                <AppBar className="header-style">
                  <Container maxWidth="xl" className='header-pad'>
                    <Toolbar disableGutters className="header-hight"sx={{ display : "flex" , justifyContent :"flex-end" }}>
                      <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                      >
                        <a href={process.env.REACT_APP_APPLICATION_URL}>
                          <img src={LogoV2} alt="Niji in bloom" title="Niji in bloom"/>
                        </a>
                      </Typography>

                      <Box sx={{ flexGrow: 3, display: { xs: 'flex', md: 'none' }, justifyContent :"flex-end" }}>
                        <IconButton
                          size="large"
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleOpenNavMenu}
                          color="inherit"
                          
                        >
                          <MenuIcon />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorElNav}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          open={Boolean(anchorElNav)}
                          onClose={handleCloseNavMenu}
                          sx={{
                            display: { xs: 'block', md: 'none' },
                          }}
                        >
                          <MenuItem key="Home" onClick={()=> {handleCloseNavMenu();executeScroll("home"); }}>
                              <Typography textAlign="center">Home </Typography>
                            </MenuItem>
                          {pages.map((page) => (
                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                              <Typography textAlign="center">{page} </Typography>
                            </MenuItem>
                          ))}
                        </Menu>
                      </Box>
                      
                      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent :"flex-end"}}>
                        {pages.map((page) => (
                          <Button
                          className='header-padding'
                            key={page}
                            onClick={() => { handleCloseNavMenu();executeScroll(page)}}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                          >
                            {page} 
                          </Button>
                        ))}
                        <Link className='loginButton' to="/login">
                           
                        Member Login
                            
                          </Link>
                      </Box>

                      
                    </Toolbar>
                  </Container>
                </AppBar>
                </div>
}
export default HeaderSection;