import React, { FormEvent, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';

import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Spacer from '../../components/Spacer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { H1, P } from '../../components/Typography';
import TextLink from '../../components/TextLink';
import api from '../../api';
import { LoginData } from '../../api/auth';
import useErrors from '../../utils/useErrors';
import { ErrorResponse, Response } from '../../typings/response';
import AuthActions from '../../redux/auth/actions';

import emailIcon from '../../assets/icons/email.svg';
import passwordIcon from '../../assets/icons/password.svg';
import brainBg from '../../assets/images/brain-bg.svg';
import Header from '../../components/Header';
import Page from '../../components/Page';
import { smallerThan } from '../../utils/grid';
import { colors ,fontsize} from '../../config/styles';
const StyledPage = styled(Page)`
  background-image: url(${brainBg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: -250px center;
  
  ${smallerThan('md')} {
     background-image: none;  
  }
`;


const Main: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const [mutate, { error, status }] = useMutation<
  Response<{ token: string }>,
  LoginData,
  ErrorResponse
  >(api.auth.login);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const errors = useErrors(error?.response);

  const buttonDisabled = !email || !password;

  const submitForm = async () => {
    if (buttonDisabled) return;

    try {
      const response = await mutate({
        email,
        password,
      });

      dispatch(AuthActions.authorizeUser(response.data.token));
    } catch (err) {
      console.warn(err);
    }
  };

  const handleInputChange = (
    setter: (value: string) => void,
  ) => (e: FormEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      await submitForm();
    }
  };

  return (
    <StyledPage>
      <Header mode="signup" />
      <Container>
        <Row>
          <Col md={8} lg={5} xl={6} offset={{ md: 2, lg: 7, xl: 6 }}>
            <H1 align="center" size={45}>Sign in to Niji</H1>
            <Spacer top={15}>
              <P size={25} align="center">Welcome back. Enter your details below.</P>
            </Spacer>
            <Spacer top={35}>
              <Input
                error={errors.email}
                placeholder="E-mail"
                onChange={handleInputChange(setEmail)}
                icon={emailIcon}
                onKeyPress={handleKeyPress}
              />
            </Spacer>
            <Spacer top={15}>
              <Input
                type="password"
                error={errors.password}
                placeholder="Password"
                onChange={handleInputChange(setPassword)}
                icon={passwordIcon}
                onKeyPress={handleKeyPress}
              />
            </Spacer>
            <Spacer top={25}>
              <P size={16} colorKey="error" align="center"><TextLink to="/reset">Forgot your password?</TextLink></P>
            </Spacer>
            <Spacer top={45} bottom={45}>
              <Row>
                <Col sm={8} offset={{ sm: 2 }}>
                  <Button
                    loading={status === 'loading'}
                    disabled={buttonDisabled}
                    onClick={submitForm}
                  >
                    Sign in
                  </Button>
                </Col>
              </Row>
            </Spacer>
          </Col>
        </Row>
      </Container>
    </StyledPage>
  );
};

export default Main;
