import { setConfiguration } from 'react-grid-system';
import { useEffect, useState } from 'react';

export enum SIZES {
  xs = 540,
  sm = 750,
  md = 960,
  lg = 1170,
}

const handleSize = (size: string | number): string => (typeof size === 'number'
  ? `${size}px`
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  : `${SIZES[size]}px`);

export const biggerThan = (key: string | number): string => `@media (min-width: ${handleSize(key)})`;

export const smallerThan = (key: string | number): string => `@media (max-width: ${handleSize(key)})`;

export const between = (more: string | number, less: string | number): string => `@media (min-width: ${handleSize(more)}) and (max-width: ${handleSize(less)})`;

export const useWidth = (): number => {
  const [width, setWidth] = useState(window.innerWidth);

  const handler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return width;
};

export default (): void => {
  setConfiguration({
    breakpoints: [SIZES.xs + 1, SIZES.sm + 1, SIZES.md + 1, SIZES.lg + 1],
    containerWidths: [SIZES.xs - 30, SIZES.sm - 30, SIZES.md - 30, SIZES.lg - 30],
  });
};
