import React, { FormEvent, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useParams , useHistory } from 'react-router-dom';

import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import Spacer from '../../components/Spacer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { H1 } from '../../components/Typography';
import api from '../../api';
import { SetPasswordData } from '../../api/auth';
import useErrors from '../../utils/useErrors';
import { ErrorResponse, Response } from '../../typings/response';

import passwordIcon from '../../assets/icons/password.svg';
import Header from '../../components/Header';
import Page from '../../components/Page';
import AuthActions from '../../redux/auth/actions';
import styled from 'styled-components';
import { colors ,fontsize} from '../../config/styles';
import Stack from '@mui/material/Stack';
import MaterialButton from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const ErrorMessage = styled.p`
  color: ${colors.error};
  font-size :  ${fontsize.inputError};
`;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Main: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const params = useParams<{ token: string }>();
  const [mutate, { error, status }] = useMutation<
  Response<{ token: string , status : boolean}>,
  SetPasswordData,
  ErrorResponse
  >(api.auth.setPassword);

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordLenghtError, setPasswordLenghtError] = useState('');
  const [open, setOpen] = React.useState(false);
  const errors = useErrors(error?.response);
  const history = useHistory();
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const onButtonClick = async () => {
    try {
      const response = await mutate({
        token: params.token,
        password,
        passwordRepeat,
      });
      
      if(response && response?.status ){
        setOpen(true);
        setTimeout(() => {
          history.push('/login');
        },2000)
      }
      // dispatch(AuthActions.authorizeUser(response.data.token));
    } catch (err) {
      console.warn(err);
    }
  };

  const handleInputChange = (
    setter: (value: string) => void,
  ) => (e: FormEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
  };

  const buttonDisabled = (password.length == 0 && passwordRepeat.length == 0 && password== passwordRepeat  )?true:false;

  return (
    <Page>
      <Header forceBlackLogo />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'right' }}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Your password is updated.
        </Alert>
      </Snackbar>
      <Container>
        <Row>
          <Col sm={12} md={8} lg={6} offset={{ md: 2, lg: 3 }}>
            <H1 align="center">Create New Password</H1>
            <Spacer top={35}>
              <Input
                type="password"
                error={errors.password}
                placeholder="Password"
                onChange={(e)=> {
                  setPassword(e.target.value);
                  if(e.target.value.length == 1 || e.target.value.length < 6){
                    var tempError =  "Password Must Be Longer Than or Equal To 6 Characters";
                    setPasswordLenghtError(tempError);
                  }
                  if(e.target.value.length == 6){
                    setPasswordLenghtError("");
                  }
                }}
                icon={passwordIcon}
              />
              {(passwordLenghtError.length > 1)?(
                    <ErrorMessage>Password Must Be Longer Than or Equal To 6 Characters</ErrorMessage>):""}
            </Spacer>
            <Spacer top={35}>
              <Input
                type="password"
                error={errors.passwordRepeat}
                placeholder="Repeat Password"
                onChange={handleInputChange(setPasswordRepeat)}
                icon={passwordIcon}
              />
            </Spacer>
            <Spacer top={45} bottom={45}>
              <Row>
                <Col sm={8} offset={{ sm: 2 }}>
                  <Button
                    loading={status === 'loading'}
                    disabled={buttonDisabled} 
                    onClick={onButtonClick}
                  >
                    Submit New Password
                  </Button>
                </Col>
              </Row>
            </Spacer>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default Main;
