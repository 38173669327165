import styled from 'styled-components';
import purpleWaveBackground from '../../../assets/images/wave-background-purple.png';

const StyledPage = styled.div`
  background: ${({ theme }) => {
    if (theme.gameColor === 'blue') {
      return 'linear-gradient(180deg, #5ebccb 0%, #38719e 100%)';
    }

    if (theme.gameColor === 'orange') {
      return 'linear-gradient(180deg, #f48b5d 0%, #e97c75 100%)';
    }

    return `url(${purpleWaveBackground}), linear-gradient(180deg, #51479e 0%, #282265 100%)`;
  }};
  background-size: contain;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
`;

export default StyledPage;
