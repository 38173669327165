import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { GameResultsTable } from '../Game/components/GameEnd';
import StyledPage from '../Game/components/StyledPage';
import './style.css';
import api from '../../api';
import Loader from '../Loader';
import nijiLogo from '../../assets/images/Logo.png';
import Feedback from '../../components/Feedback';
const GameList = ({
  /* eslint-disable */
  id,
  game,
  date,
  gameColor,
  timeToRespond,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const formattedDate = moment(date).format('LLLL');

  return (
    <div
      className={`game-result-item ${
        gameColor ? `game-result-item--${gameColor} ` : ''
      }`}
    >
      <div className="game-result-item--header">
        <div className="game-result-item--header-date">{formattedDate}</div>
        <button
          type="button"
          className="game-result-item--header-toggle"
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? 'hide details' : 'show details'}
        </button>
      </div>

      {isOpen && (
        <>
          <GameResultsTable
            gameResults={game}
            timeToRespond={timeToRespond}
            gameColor={gameColor}
          />
        </>
      )}
    </div>
  );
};

// TODO: Total Sessions vs previous game
// TODO: Rounds Per Session
const GameResume = ({ data, gameColor }) => {
  const boxClassName = `game-resume-container--item ${
    gameColor ? `game-resume-container--item-${gameColor}` : ''
  }`;

  return (
    <div className="game-resume-container">
      <div className={boxClassName}>
        <div>sessions</div>
        <div className="game-resume-container--item-result">
          {data.totalSessions}
        </div>
      </div>

      <div className={boxClassName}>
        <div>trials</div>
        <div className="game-resume-container--item-result">
          {data.totalRounds}
        </div>
      </div>

      <div className={boxClassName}>
        <div>total time</div>
        <div className="game-resume-container--item-result">
          {`${data.totalTime / 1000} s`}
        </div>
      </div>

      <div className={boxClassName}>
        <div>Average time per session</div>
        <div className="game-resume-container--item-result">
          {`${(data.averageTimePerSession / 1000).toFixed(2)} s`}
        </div>
      </div>

      <div className={boxClassName}>
        <div>Average time per trial</div>
        <div className="game-resume-container--item-result">
          {`${(data.averageTimePerRound / 1000).toFixed(2)} s`}
        </div>
      </div>

      <div className={boxClassName}>
        <div>total correct trials</div>
        <div className="game-resume-container--item-result">
          {data.totalRoundsCorrect}
        </div>
      </div>

      <div className={boxClassName}>
        <div>total incorrect trials</div>
        <div className="game-resume-container--item-result">
          {data.totalRoundsIncorrect}
        </div>
      </div>
    </div>
  );
};

const CustomizedDot = (props) => {
  const {
    cx, cy, payload, dataKey,
  } = props;

  const status = payload[`${dataKey}-correct`];

  if (status === 'pending') {
    return (
      <circle
        r="5"
        stroke="#FFD600"
        strokeWidth="1"
        fill="#FFC11E"
        cx={cx}
        cy={cy}
      />
    );
  }

  if (status === 'wrong') {
    return (
      <circle
        r="5"
        stroke="#E0230D"
        strokeWidth="1"
        fill="#FF5440"
        cx={cx}
        cy={cy}
      />
    );
  }

  return (
    <circle
      r="5"
      stroke="#4a9a16"
      strokeWidth="1"
      fill="#A5EB78"
      cx={cx}
      cy={cy}
    />
  );
};

const GameResumeCharts = ({ data, gameColor, timeToRespond }) => {
  const format = 'ddd, MMM D, YY hh:mm a';

  const roundsResults = {};

  const keys = data.map((game, i) => {
    const date = moment(game.date).format(format);

    return `${i + 1} session (${date})`;
  });
  console.log(keys)

  data.forEach((session: { id: string; game: any[] }, i) => {
    const { id, game } = session;
    // @ts-ignore
    const date = moment(session.date).format(format);

    const key = `${i + 1} session (${date})`;

    game?.forEach(({ round, gameLetter, responses }) => {
      let isAnswerRight = 'pending';
      let timeCountDown = timeToRespond / 1000;

      responses?.map((response) => {
        const { pressedKey, currentCountDown } = response;

        if (pressedKey === gameLetter && currentCountDown <= timeToRespond) {
          isAnswerRight = 'right';
        } else {
          isAnswerRight = 'wrong';
        }

        timeCountDown = currentCountDown / 1000;
      });

      roundsResults[round] = {
        ...roundsResults[round],
        [key]: timeCountDown,
        [`${key}-correct`]: isAnswerRight,
      };
    });
  });

  const chartValues = [];
  let timeAccumulator = 0;
  Object.keys(roundsResults).forEach((key) => {
    let test2 = (typeof(roundsResults[key][keys[1]]) === 'undefined')?0:roundsResults[key][keys[1]];
    var avg = 0;
    if((typeof(roundsResults[key][keys[1]]) === 'undefined')){
      avg = roundsResults[key][keys[0]];
    }else{
      avg = ( (roundsResults[key][keys[0]] + test2) / 2 );
    }
    if(isNaN(avg)){
      avg = 0;
    }
    
    timeAccumulator += avg;
    chartValues.push({
      round: key,
      timeAccumulator: timeAccumulator.toFixed(2),
      ...roundsResults[key],
    });
  });
  console.log({ chartValues })

  return (
    <div
      className={`game-chart ${gameColor ? `game-chart--${gameColor} ` : ''}`}
    >
      <div className="game-chart--header">chart details</div>

      <div
        className={`game-chart--body ${
          gameColor ? `game-chart--body--${gameColor} ` : ''
        }`}
      >
        <LineChart width={800} height={350} data={chartValues}>
          <CartesianGrid strokeDasharray="1 10" horizontal={false} />
          <XAxis type="category" dataKey="timeAccumulator" />
          <YAxis
            padding={{
              top: 20,
              bottom: 20,
            }}
          />
          <Tooltip />
          {keys.map((key) => (
            <Line
              type="monotone"
              dataKey={key}
              stroke="#81d4fa"
              isAnimationActive={false}
              dot={<CustomizedDot />}
            />
          ))}
        </LineChart>
      </div>
    </div>
  );
};

const generateGamesResume = (gameHistory, timeToRespond) => {
  const {
    totalRounds,
    totalTime,
    totalRoundsCorrect,
    totalRoundsIncorrect,
  } = gameHistory.reduce(
    (acc, gameProps) => {
      const { game } = gameProps;
      acc.totalRounds += game.length;

      const roundsResults = game.reduce(
        (acc, currentGame) => {
          let isAnswerRight = false;
          let maxTime = 0;

          currentGame?.responses?.map(({ pressedKey, currentCountDown }) => {
            maxTime = currentCountDown;
            if (
              pressedKey === currentGame.gameLetter
              && currentCountDown <= timeToRespond
            ) {
              isAnswerRight = true;
            }
          });
          if (isAnswerRight) {
            acc.totalCorrect += 1;
          } else {
            acc.totalIncorrect += 1;
          }
          acc.totalTime += maxTime;
          return acc;
        },
        {
          totalCorrect: 0,
          totalIncorrect: 0,
          totalTime: 0,
        },
      );

      acc.totalTime += roundsResults.totalTime;
      acc.totalRoundsCorrect += roundsResults.totalCorrect;
      acc.totalRoundsIncorrect += roundsResults.totalIncorrect;
      return acc;
    },
    {
      totalRounds: 0,
      totalTime: 0,
      totalRoundsCorrect: 0,
      totalRoundsIncorrect: 0,
    },
  );

  return {
    totalSessions: gameHistory.length,
    totalRounds,
    totalTime,
    averageTimePerRound: totalTime / totalRounds || 0,
    averageTimePerSession: totalTime / gameHistory.length || 0,
    totalRoundsCorrect,
    totalRoundsIncorrect,
  };
};

const PastGames = () => {
  const history = useHistory();
  const query = useQuery('passedGames', api.game.getPassedGames);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  const [show, setShow] = useState(true);
  const gameConfig = {
    gameColor: null,
    timeToRespond: 2000,
  };

  const navigate = (url) => {
    history.push(url);
  };

  if (query.status === 'loading') {
    return <Loader image={nijiLogo} />;
  }

  const gameHistory = query.data.data.map((game) => ({
    id: game._id,
    game: game.results,
    date: game.createdAt,
  }));

  const gameHistoryGroup = gameHistory
    .reverse()
    .map(({ id, game, date }) => (
      <GameList
        id={id}
        game={game}
        date={date}
        key={id}
        gameColor={gameConfig.gameColor}
        timeToRespond={gameConfig.timeToRespond}
      />
    ));

  const result = generateGamesResume(gameHistory, gameConfig.timeToRespond);
  
  return (
    <StyledPage
      theme={{ gameColor: gameConfig.gameColor }}
      className="game-main-page game-results-page full-height"
    >
      <div onClick={() => navigate('dashboard')} className="go-to-dashboard-button">
        back to dashboard
      </div>
      <GameResume data={result} gameColor={gameConfig.gameColor} />
      <GameResumeCharts
        data={gameHistory}
        gameColor={gameConfig.gameColor}
        timeToRespond={gameConfig.timeToRespond}
      />
      <div className="game-body">{gameHistoryGroup}</div>
      <Feedback profileQuery={profileQuery} show={show}/>
    </StyledPage>
  );
};

export default PastGames;
