import React, { useMemo, useState , useEffect } from 'react';
import {
  StartPage,
  AboutTheGame,
  Tutorial1,
  Tutorial2,
  Tutorial3,
  Tutorial4,
  Tutorial5,
  Tutorial6,
  Tutorial7,
  Confirmation,
  PracticeRound,
  PracticeRoundConfirmation,
} from './steps';
import StyledPage from './StyledPage';
import Header from '../../../components/Header';
import { useHistory } from 'react-router-dom';
import Feedback from '../../../components/Feedback';
import { useQuery } from 'react-query';
import api from '../../../api';

const steps = [
  { content: AboutTheGame,
    nextButtonEnable : true
   },
  { content: Tutorial1,
    nextButtonEnable : true
   },
  { content: Tutorial6,
    nextButtonEnable : false,
    keyPressButton : "any"
   },
  { content: Tutorial2,
    nextButtonEnable : false,
    keyPressButton : "C" },
  { content: Tutorial3,
    nextButtonEnable : false,
    keyPressButton : "V"
   },
  { content: Tutorial4 ,
    nextButtonEnable : false,
    keyPressButton : "N"
  },
  { content: Tutorial5,
    nextButtonEnable : false,
    keyPressButton : "M"
   },
  { content: StartPage,
    nextButtonEnable : false,
    keyPressButton : "NONE" },
  { content: PracticeRoundConfirmation,
    nextButtonEnable : false,
    keyPressButton : "any"
   },
  { content: Tutorial7,
    nextButtonEnable : false,
    keyPressButton : "any"
   },
  { content: PracticeRound,
    nextButtonEnable : false,
    keyPressButton : "NONE"
   },
  { content: Confirmation,
    nextButtonEnable : true,
    keyPressButton : "NONE"
   }
  
];

const InstructionButtons = ({
  step,
  goBack,
  startGame,
  nextStep,
  isNextDisabled,
  gotoDasbhoard,
  flashScreen,
  showConsentModal
}) => (
  <div className="instructions-buttons">
    {step >= 1 ? (
      <div
        className="instruction-button instruction-button--purple"
        onClick={goBack}
        style={{ borderRadius: "4rem"}}
      >
        Back
      </div>
    ) : (
      <div
        className="instruction-button instruction-button--purple"
        onClick={gotoDasbhoard}
        style={{ borderRadius: "4rem"}}
      >
        Dashboard
      </div>
    )}

    {(step >= steps.length - 1 )? (
      <div
        className={`instruction-button instruction-button--${
          isNextDisabled ? 'disabled' : 'green'
        }`}
        onClick={isNextDisabled ? null : showConsentModal}
        style={{ borderRadius: "4rem"}}
      >
        Begin
      </div>
    ) : (
      <div
        className={`instruction-button instruction-button--${
          isNextDisabled ? 'disabled' : 'green'
        }`}
        onClick={isNextDisabled ? null : nextStep}
        style={{ borderRadius: "4rem"}}
      >
        Next
      </div>
    )}
  </div>
);

const InstructionSteps = ({ steps, step }) => (
  <div className="instruction-steps">
    {steps.map((val, i) => (
      <div
        key={i}
        className={`instruction-step ${
          i === step ? 'instruction-step--selected' : ''
        }`}
      />
    ))}
  </div>
);

const GameInstructions = ({ gameConfig, startGame, setPracticeResults,flashScreen , showConsentModal}) => {
  const [step, setStep] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const StepComponent = useMemo(() => steps[step].content, [step]);
  const [showFeedback, setShowFeedback] = useState(true);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });


  const history = useHistory();
  const nextStepForward = () => {
    setStep((prev) => prev + 1)
  } 
  const takeToStep = (nthStep) => {
    setStep(nthStep);
  } 
  const gotoDasbhoard = () => {
    history.push("/dashboard")
  } 

  const goOneStepBack = () => {
    setIsNextDisabled(false);
    // let currentStep = step
    setStep(step-1);
  }
  

  console.log("current step : ",step);
  return (
    <StyledPage
      theme={{ gameColor: gameConfig.gameColor }}
      className="game-main-page"
    >
      <Feedback show={showFeedback} profileQuery={profileQuery}/>

      <Header showBackButton={false} />
      <div className="instructions-container instructions-container--height">
      
        <StepComponent
          feedbackColor={gameConfig.feedbackColor}
          setIsNextDisabled={setIsNextDisabled}
          nextStep={nextStepForward}
          // @ts-ignore
          setPracticeResults={setPracticeResults}
          flashScreen={() => {flashScreen()}}
          takeToStep={takeToStep}
        />
        <div className="instructions-bottom">
          <InstructionButtons
            step={step}
            startGame={startGame}
            nextStep={() => setStep((prev) => prev + 1)}
            goBack={() => {goOneStepBack()}}
            isNextDisabled={isNextDisabled}
            gotoDasbhoard={gotoDasbhoard}
            flashScreen={() => {flashScreen()}}
            
            showConsentModal={() => {showConsentModal()}}
          />
          <InstructionSteps steps={steps} step={step} />
        </div>
      </div>
    </StyledPage>
  );
};

export default GameInstructions;
