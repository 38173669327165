import produce from 'immer';

import * as Types from './actionTypes';

export interface State {
  id: string | null;
  email: string | null;
  name: string | null;
  passedQuiz: boolean | null;
}

const initialState: State = {
  id: null,
  email: null,
  name: null,
  passedQuiz: null,
};

export default (
  state: State = initialState,
  action: Types.UserActionType,
): State => produce(state, (draft: State) => {
  switch (action.type) {
    case Types.SAVE_USER:
      draft.id = action.payload.id;
      draft.email = action.payload.email;
      // @ts-ignore
      draft.name = action.payload.name;
      draft.passedQuiz = action.payload.passedQuiz;
      break;
    case Types.REMOVE_USER:
      draft.id = null;
      draft.email = null;
      draft.name = null;
      draft.passedQuiz = null;
      break;
    case Types.SET_QUIZ_PASSED:
      draft.passedQuiz = action.payload;
      break;
    default:
      break;
  }
});
