import jwt from 'jsonwebtoken';
import * as Types from './actionTypes';

interface JWTResult {
  payload: JWTPayload;
}

interface JWTPayload {
  exp: number;
}

const authorizeUser = (token: string): Types.AuthorizeAction => {
  const decoded = jwt.decode(token, { complete: true }) as JWTResult;

  return {
    type: Types.AUTHORIZE_USER,
    payload: {
      expiration: decoded.payload.exp * 1000,
      token,
    },
  };
};

const logoutUser = (): Types.LogoutAction => ({
  type: Types.LOGOUT_USER,
});

export default {
  authorizeUser,
  logoutUser,
};
