/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import {
  browserName,
  browserVersion,
  osVersion,
  osName,
} from 'react-device-detect';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { useMutation, useQuery } from 'react-query';
import StyledPage from './StyledPage';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as YellowCheckIcon } from '../../../assets/icons/check-yellow.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import api from '../../../api';
import Loader from '../../Loader';
import Logo from '../../../assets/images/Logo.png';
import Feedback from '../../../components/Feedback';

export const GameResultsTable = ({ gameResults, timeToRespond, gameColor }) => {
  const tableBodyClassName = `tbody-item ${
    gameColor ? `tbody-item--${gameColor}` : ''
  }`;

  const tableHeaderClassName = `table-header-item ${
    gameColor ? `table-header-item--${gameColor}` : ''
  }`;

  const {
    totalCorrect,
    totalIncorrect,
    totalTime,
    totalRounds,
  } = gameResults.reduce(
    (acc, game) => {
      let isAnswerRight = false;
      let maxTime = 0;

      game?.responses?.map(({ pressedKey, currentCountDown }) => {
        maxTime = currentCountDown;
        if (
          pressedKey === game.gameLetter
          && currentCountDown <= timeToRespond
        ) {
          isAnswerRight = true;
        }
      });

      if (isAnswerRight) {
        acc.totalCorrect += 1;
      } else {
        acc.totalIncorrect += 1;
      }

      acc.totalTime += maxTime;

      return acc;
    },
    {
      totalCorrect: 0,
      totalIncorrect: 0,
      totalTime: 0,
      totalRounds: gameResults.length,
    },
  );

  return (
    <>
      <table className="result-table">
        <thead>
          <tr>
            <th className={tableHeaderClassName}>Trial</th>
            <th className={tableHeaderClassName}>Trial letter</th>
            <th className={tableHeaderClassName}>Responses</th>
            <th className={tableHeaderClassName} />
          </tr>
        </thead>
        <tbody>
          {gameResults?.map(({ round, gameLetter, responses }) => {
            const className = `tbody-item ${
              round % 2 > 0
                ? `tbody-item-darker${gameColor ? `--${gameColor}` : ''}`
                : ''
            }
          ${gameColor ? `tbody-item--${gameColor}` : ''}`;

            let rightAnswer = 0;

            return (
              <tr>
                <th className={className}>
                  {round}
                  {' '}
                  trial
                </th>
                <th className={className}>{gameLetter}</th>
                <th className={className}>
                  {responses?.map((response, i) => {
                    const { pressedKey, currentCountDown } = response;
                    const isAnswerRight = pressedKey === gameLetter;

                    if (currentCountDown <= timeToRespond) {
                      if (isAnswerRight) {
                        rightAnswer = 1;
                      }

                      if (isAnswerRight && i > 0) {
                        rightAnswer = 2;
                      }
                    }

                    return (
                      <div className="answer-row">
                        {`pressed letter ${pressedKey} in ${
                          currentCountDown / 1000
                        }s`}
                      </div>
                    );
                  })}
                </th>
                <th className={className}>
                  {rightAnswer > 0 ? (
                    rightAnswer > 1 ? (
                      <YellowCheckIcon className="answer-right-icon" />
                    ) : (
                      <CheckIcon className="answer-right-icon" />
                    )
                  ) : (
                    <CancelIcon className="answer-right-icon" />
                  )}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="result-table">
        <thead>
          <tr>
            <th
              className={`${tableHeaderClassName} table-header-item--total-header ${
                gameColor ? `table-header-item--total-header-${gameColor}` : ''
              }`}
            >
              total trials
            </th>
            <th
              className={`${tableHeaderClassName} table-header-item--total-header ${
                gameColor ? `table-header-item--total-header-${gameColor}` : ''
              }`}
            >
              total correct trials
            </th>
            <th
              className={`${tableHeaderClassName} table-header-item--total-header ${
                gameColor ? `table-header-item--total-header-${gameColor}` : ''
              }`}
            >
              total incorrect trials
            </th>
            <th
              className={`${tableHeaderClassName} table-header-item--total-header ${
                gameColor ? `table-header-item--total-header-${gameColor}` : ''
              }`}
            >
              total time
            </th>
            <th
              className={`${tableHeaderClassName} table-header-item--total-header ${
                gameColor ? `table-header-item--total-header-${gameColor}` : ''
              }`}
            >
              Average Time per trial
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              className={`${tableBodyClassName} tbody-item--total ${
                gameColor ? `tbody-item--total-${gameColor}` : ''
              }`}
            >
              {totalRounds}
            </th>
            <th
              className={`${tableBodyClassName} tbody-item--total ${
                gameColor ? `tbody-item--total-${gameColor}` : ''
              }`}
            >
              {`${totalCorrect} (${((totalCorrect * 100) / totalRounds).toFixed(2)}%)`}
            </th>
            <th
              className={`${tableBodyClassName} tbody-item--total ${
                gameColor ? `tbody-item--total-${gameColor}` : ''
              }`}
            >
              {`${totalIncorrect} (${((totalIncorrect * 100) / totalRounds).toFixed(2)}%)`}
            </th>
            <th
              className={`${tableBodyClassName} tbody-item--total ${
                gameColor ? `tbody-item--total-${gameColor}` : ''
              }`}
            >
              {(totalTime / 1000).toFixed(2)}
              s
            </th>
            <th
              className={`${tableBodyClassName} tbody-item--total ${
                gameColor ? `tbody-item--total-${gameColor}` : ''
              }`}
            >
              {(totalTime / totalRounds / 1000).toFixed(2)}
              s
            </th>
          </tr>
        </tbody>
      </table>
    </>
  );
};

const CustomizedDot = (props) => {
  const {
    cx, cy, payload,
  } = props;

  if (payload.result === 'pending') {
    return (
      <circle
        r="5"
        stroke="#FFD600"
        strokeWidth="1"
        fill="#FFC11E"
        cx={cx}
        cy={cy}
      />
    );
  }

  if (payload.result === 'wrong') {
    return (
      <circle
        r="5"
        stroke="#E0230D"
        strokeWidth="1"
        fill="#FF5440"
        cx={cx}
        cy={cy}
      />
    );
  }

  return (
    <circle
      r="5"
      stroke="#4a9a16"
      strokeWidth="1"
      fill="#A5EB78"
      cx={cx}
      cy={cy}
    />
  );
};

const GameResultsChart = ({ gameResults, timeToRespond, gameColor }) => {
  let timeAccumulator = 0;
  const data = gameResults?.map(({ round, gameLetter, responses }) => {
    let isAnswerRight = false;
    let timeCountDown = timeToRespond / 1000;

    responses?.map((response) => {
      const { pressedKey, currentCountDown } = response;

      if (pressedKey === gameLetter && currentCountDown <= timeToRespond) {
        isAnswerRight = true;
      }

      timeCountDown = currentCountDown / 1000;
    });

    let result = isAnswerRight ? 'right' : 'wrong';

    if (!responses?.length) {
      result = 'pending';
    }
    timeAccumulator += timeCountDown;
    return {
      round,
      result,
      timeCountDown,
      timeAccumulator: timeAccumulator.toFixed(2),
    };
  });

  return (
    <div
      className={`game-chart ${gameColor ? `game-chart--${gameColor} ` : ''}`}
    >
      <div className="game-chart--header">NIJI REPORT</div>

      <div className="game-chart--body">
        <LineChart width={800} height={350} data={data}>
          <CartesianGrid strokeDasharray="1 10" horizontal={false} />
          <XAxis type="category" dataKey="timeAccumulator" />
          <YAxis
            padding={{
              top: 20,
              bottom: 20,
            }}
          />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="timeCountDown"
            stroke="#81d4fa"
            isAnimationActive={false}
            dot={<CustomizedDot />}
          />
        </LineChart>
      </div>
    </div>
  );
};

const ExpandableResults = ({ gameResults, timeToRespond, gameColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="game-body">
      <div
        className={`game-result-item ${
          gameColor ? `game-result-item--${gameColor} ` : ''
        }`}
      >
        <div className="game-result-item--header">
          <div className="game-result-item--header-date">Session details</div>
          <button
            type="button"
            className="game-result-item--header-toggle"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? 'hide details' : 'show details'}
          </button>
        </div>

        {isOpen && (
          <>
            <GameResultsTable
              gameResults={gameResults}
              timeToRespond={timeToRespond}
              gameColor={gameColor}
            />
          </>
        )}
      </div>
    </div>
  );
};

const GameEnd = ({
  gameConfig, resetTest, results, practiceData, video, location,
}) => {
  const [saveResults, saveResult] = useMutation(api.game.saveGameResults);
  const passedGamesCount = useQuery('passedGamesCount', api.game.getPassedGamesCount);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [saved, setSaved] = useState(false);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  useEffect(() => {
    if (['error', 'success'].includes(saveResult.status)) {
      setSaved(true);
      passedGamesCount.refetch({ force: true });
    }
  }, [saveResult.status]);

  useEffect(() => {
    if (deviceInfo && location && practiceData && video !== undefined) {
      saveResults({
        results,
        device: deviceInfo,
        practiceData,
        video,
        address: location,
      });
    }
  }, [deviceInfo, practiceData, results, saveResults, video, location]);

  const load = () => {
    const game = {
      browserName,
      browserVersion,
      osVersion,
      osName,
      browserLanguage: navigator.language,
    };

    setDeviceInfo(game);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (location) {
      localStorage.setItem(
        'niji-game-localization',
        `${location?.zipCode}, ${location?.state} - ${location?.country}`,
      );
    }
  }, [location]);

  if (!saved || passedGamesCount.status === 'loading') {
    return <Loader text="Thank you for completing the test. Please wait while we save your results, do not close or refresh this page. This might take up to 30 seconds..." image={Logo} />;
  }

  return (
    <StyledPage
      theme={{ gameColor: gameConfig.gameColor }}
      className="game-main-page full-height"
    >
      <div className="instructions-container instructions-container--start">
        <div className="game-finished-container">
          <div className="game-results-text">
            <p className="title">
              Congratulations!
              {passedGamesCount.data.data.count === 1 && ' You completed the brain health activity.'}
            </p>
            {passedGamesCount.data.data.count === 1 && (
              <p className="paragraph">
                Here is a chart of your performance.
                The horizontal line represents time from the
                beginning to end of the test. The vertical
                line represents the amount of time it took
                you to respond to the trial at that time.
                Green circles are trials where you pressed
                the correct button. Red circles where you
                pressed the incorrect button. Yellow
                circles represent trials where you
                didn’t press the button in time.
                When the circles are at higher
                locations in the chart, it took
                you longer to respond.
              </p>
            )}
          </div>

          <GameResultsChart
            gameResults={results || []}
            timeToRespond={gameConfig.timeToRespond}
            gameColor={gameConfig.gameColor}
          />

          <ExpandableResults
            gameResults={results || []}
            timeToRespond={gameConfig.timeToRespond}
            gameColor={gameConfig.gameColor}
          />

          {location && location.zipCode ? (
            <div
              className={`game-location ${
                gameConfig?.gameColor
                  ? `game-location--${gameConfig.gameColor} `
                  : ''
              }`}
            >
              {`Game played at ${location?.zipCode}, ${location?.state} - ${location?.country}`}
            </div>
          ) : null}
        </div>
        {passedGamesCount.data.data.count < 2 && (
          <div
            className="instruction-button instruction-button--green"
            onClick={passedGamesCount.data.data.count >= 2 ? () => {} : resetTest}
          >
            Repeat Test
          </div>
        )}
      </div>

      <div className="instructions-buttons">
        <a
          href="/past-games"
          className="instruction-button instruction-button--purple"
        >
          Finish Activity
        </a>
      </div>
      <Feedback profileQuery={profileQuery} show={true}/>
    </StyledPage>
  );
};

export default GameEnd;
