import produce from 'immer';

import * as Types from './actionTypes';

export interface State {
  expiration: number;
  token: string;
}

const initialState: State = {
  expiration: undefined,
  token: undefined,
};

export default (
  state: State = initialState,
  action: Types.AuthActionType,
): State => produce(state, (draft: State) => {
  switch (action.type) {
    case Types.AUTHORIZE_USER:
      draft.token = action.payload.token;
      draft.expiration = action.payload.expiration;
      break;
    case Types.LOGOUT_USER:
      draft.token = undefined;
      draft.expiration = undefined;
      break;
    default:
      break;
  }
});
