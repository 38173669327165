import React from 'react';
import './style.css';
import whiteLogo from '../../assets/icons/logo-white.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Header from './header';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import clinicalTrialImage from '../../assets/images/landingpage/Services - Clinical Trials-graphic.svg';
import NeuropsychologicalAssessment from '../../assets/images/landingpage/NeuropsychologicalAssessment  - Graphic.svg';
import Neuroimaging from '../../assets/images/landingpage/Neuroimaging  - Graphic.svg';
import DigitalHealth from '../../assets/images/landingpage/Digital Health  - Graphic.svg';
import ServicesClinicalTialsIcon from '../../assets/images/landingpage/Services - Clinical Tials Icon.svg';
import NeuropsychologicalAssessmentIcon from '../../assets/images/landingpage/NeuropsychologicalAssessment - Icon.svg';
import NeuroimagingIcon from '../../assets/images/landingpage/Neuroimaging  - Icon.svg';
import DigitalHealthIcon from '../../assets/images/landingpage/Digital Health  - Icon.svg';
import ServicesSeparator from '../../assets/images/landingpage/Services - Separator.svg';
import titleImg from '../../assets/images/landingpage/AboutUs-title-design.svg';
import singleLeaf from '../../assets/images/landingpage/singleLeaf.svg';
import serviceDesign from '../../assets/images/landingpage/service-design.svg';
import crossLeaf from '../../assets/images/landingpage/crossleaf.svg';
import serivceDesignBottom from '../../assets/images/landingpage/servicedesignbtm.svg';
import crossLine from '../../assets/images/landingpage/crosslines.svg';
import vCrossLine from '../../assets/images/landingpage/LinePattern.svg';
const pages = ['Home', 'About Us', 'Service','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Services=()=>{
    
    

    return <div className="serviceSection" style={{ backgroundColor : "#FFFFFF", padding : "2rem  0rem"}}>

    <Container className='position-rel' maxWidth="xl">     
          
                <Grid spacing={2}>
                  <Grid item xs={12}>
                    <div className="aboutUs-d-flex">
                        <img src={titleImg}/>
                        <h2 className="aboutUs-title">SOLUTIONS</h2>
                        <img className='leaf-position' src={singleLeaf}/>
                    </div>

                  </Grid>
                </Grid>
          
            <Container maxWidth="lg">     
                <Grid container className="SecondLevelContainer"  spacing={0}>
              
                <Grid className="align-center" item xs={5}>
                    <div className="service-d-flex">

                    <img className='icon-spacing' src={NeuroimagingIcon} />
                        <h3 className="neuroimagingText">
                          Neuroimaging
                          <div className="underlineForNeuroimaging"></div>
                      </h3>  
                     
                    </div>
                    
                        <div className="descriptionOfservices" style={{"width":"403px","height":"196px","left":"653px","top":"374px","fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"28px","marginLeft":"70px"}}>
                          Consultation and support for imaging studies, including clinical trials, on image acquisition, harmonization across scanner platforms, data quality assessment, and data processing.
                        </div>
                        
                   
                  </Grid>
                  <Grid item xs={7}>
                    <div>
                      <img  src={Neuroimaging}  alt="Clinical Neuroscience Research" title="Clinical Neuroscience Research"/>
                    </div>
                  </Grid>
                
                  <Grid className='separator-spacing' item xs={12}>
                  <img className="img-size" src={ServicesSeparator} />
                </Grid>



                  <Grid item xs={6}>
                    <div className='img-position'>
                      <img src={NeuropsychologicalAssessment} alt="Neuropsychological and cognitive assessment tools" title="Neuropsychological and cognitive assessment tools"/>
                    </div>
                </Grid>
             
                    <Grid className="align-center" item xs={6}>
                    <div className="service-d-flex">

                    <img className='icon-spacing' src={NeuropsychologicalAssessmentIcon}/>
                    <img  className='design-position' src={serviceDesign}/>
                        <h3 className="neruopsychologicalText">
                        Neuropsychological Assessment
                          <div className="underlineForNeuropsychologicalAssessment"></div>
                      </h3>  
                     
                    </div>
                    
                        <div className="descriptionOfservices" style={{"width":"403px","height":"196px","left":"653px","top":"374px","fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"28px","marginLeft":"70px"}}>
                        Consultation and support including selection of appropriate tests, digital neuropsychology, scoring, data analysis, use of scores as trial outcome measures, and interpretation of results.
                        
                        </div>
                        <img className='crossline-styling' src={crossLine}/>
                        
                   
                  </Grid>


                  <Grid  className='separator-spacing' item xs={12}>
                    <img className="img-size" src={ServicesSeparator} />
                    <img className="vCrossLine-position" src={vCrossLine} />
                  </Grid>

                


                  <Grid item xs={5}>
                    <div className="service-d-flex">

                    <img className='icon-spacing' src={DigitalHealthIcon}/>
                        <h3 className="digitalHealthText">
                        Digital Health 
                          <div className="underlineForDigitalHeath"></div>
                      </h3>  
                     
                    </div>
                    <img className='leaf-position' src={singleLeaf}/>
                    <img className='left-leaf-position' src={singleLeaf}/>
                        <div className="descriptionOfservices" style={{"width":"319px","height":"196px","left":"653px","top":"374px","fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"28px","marginLeft":"70px"}}>
                        Consultation and support including remote tracking of participant cognition and health.
                        </div>
                        
                   
                  </Grid>

                  <Grid item xs={7}>  
                    <div>
                      <img src={DigitalHealth} alt="Neuropsychology Clinical Trials" title="Neuropsychology Clinical Trials"/>
                    </div>
                  </Grid>

                  <Grid className='separator-spacing' item xs={12}>
                    <img className="img-size" src={ServicesSeparator} />
                  </Grid>
               
                  <Grid item xs={6}>
                    <div className='align-center-dev'>
                      <img  src={clinicalTrialImage} />
                     
                    </div>
                    <img className='serviceButtomdesign'  src={serivceDesignBottom} />
                </Grid>

                  <Grid className="align-center" item xs={6}>
                    <div className="service-d-flex">

                    <img className='icon-spacing' src={ServicesClinicalTialsIcon}/>
                        <h3 className="ClinicalTrials">
                        <img className='cross-position' src={crossLeaf}/>
                        Clinical Trials
                          <div className="underlineForClicaltrials"></div>
                      </h3>  
                     
                    </div>
                    
                        <div className="descriptionOfservices" style={{"width":"403px","height":"196px","left":"653px","top":"374px","fontFamily":"Roboto","fontStyle":"normal","fontWeight":"normal","fontSize":"18px","lineHeight":"28px","marginLeft":"70px"}}>
                        Consultation and support including study design, participant inclusion, exclusion, and enrollment, group matching and randomization, and selection of outcome measures.
                        </div>
                        
                   
                  </Grid>
                </Grid>
              </Container>
              </Container>

          </div> 
}
export default Services;