import React from 'react';
import './style.css';
import whiteLogo from '../../assets/icons/logo-white.svg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Header from './header';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import bannersideImage from '../../assets/images/landingpage/Hero-Brain 1.svg';
const pages = ['Home', 'About Us', 'Service','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const BannerSection=( { executeScroll })=>{
    
    

    return <div className="banner-background" >
              <Container maxWidth="lg">
                <Grid container spacing={1} style={{ padding :"4rem 0rem"}}>
                  <Grid className="layout-set" item xs={6}>
                    <div className="banner-title">
                        <div>
                          Brain. Health. Everyday. 
                        </div>
                        <div className="bannner-sub-title">
                            At <span> Niji Neuroscience </span> we aim to translate cutting-edge science to practical and
                            actionable technologies in support of clinical research and therapeutic clinical trials, as well as in the promotion of brain health.
                        </div>
                        <div className='banner-cta' onClick={()=> {executeScroll("Solutions")}} style={{ cursor :"pointer"}}>
                          Explore
                        </div>
                    </div>
                  </Grid>
                  <Grid item xs={6} style={{ display : "flex", justifyContent :"flex-end"  }}>
                    <div>
                      <img src={bannersideImage} alt="Brain Health Everyday" title="Brain Health Everyday"/>
                    </div>
                  </Grid>
                  
                </Grid>
              </Container>
          </div> 
}
export default BannerSection;