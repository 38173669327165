/* eslint-disable no-underscore-dangle */
import { AxiosPromise } from 'axios';
import client from '../core';

const getPassedGames = (): AxiosPromise<any> => client.get('game/results');

const getPassedGamesCount = (): AxiosPromise<any> => client.get('game/count');

const saveGameResults = async (data) => {
  const saveResponse = await client.post('game', {
    ...data,
    video: undefined,
  });

  if (data.video) {
    const fd = new FormData();
    fd.append('file', data.video, data.video.name);

    await client.put(`game/${saveResponse.data._id as string}/video`, fd, {
      headers: {
        // @ts-ignore
        'Content-Type': `multipart/form-data; boundary=${fd._boundary as string}`,
      },
    });
  }
};

export default {
  getPassedGames,
  getPassedGamesCount,
  saveGameResults,
};
