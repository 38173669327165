import styled from 'styled-components';

import { colors } from '../../config/styles';

interface HeadingProps {
  size?: number;
  align?: string;
  colorKey?: keyof typeof colors;
}

export const H1 = styled.h1<HeadingProps>`
  font-size: ${({ size }) => `${size || 36}px`};
  line-height: 42px;
  font-weight: 900;
  color: ${({ colorKey }) => (colorKey ? colors[colorKey] : colors.text)};
  text-align: ${({ align }) => (align || 'left')}
`;

interface ParagraphProps {
  size?: number;
  colorKey?: keyof typeof colors;
  weight?: number;
  align?: string;
}
// console.log(size);

export const P = styled.p<ParagraphProps>`
  font-size: ${({ size }) => `${size || 16}px`};
  color: ${({ colorKey }) => (colorKey ? colors[colorKey] : colors.text)};
  font-weight: ${({ weight }) => (weight || 400)};
  text-align: ${({ align }) => (align || 'left')};
`;
