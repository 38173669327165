import React, { FormEvent, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';

import { useMutation } from 'react-query';
import Spacer from '../../components/Spacer';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { H1, P } from '../../components/Typography';
import TextLink from '../../components/TextLink';
import api from '../../api';
import { ResetPasswordData } from '../../api/auth';
import useErrors from '../../utils/useErrors';
import { ErrorResponse, Response } from '../../typings/response';

import emailIcon from '../../assets/icons/email.svg';
import Header from '../../components/Header';
import Page from '../../components/Page';
import styled from 'styled-components';
import { colors ,fontsize} from '../../config/styles';
import { persistor, RootState } from '../../redux';
import { useSelector } from 'react-redux';
const EmailSendMessage = styled.p`
  color: ${colors.mainGreenDark};
  font-size :  ${fontsize.inputSuccess};
`;
const Main: React.FunctionComponent = () => {
  const [mutate, { error, status }] = useMutation<
  Response<{ success: boolean }>,
  ResetPasswordData,
  ErrorResponse
  >(api.auth.resetPassword);

  const [email, setEmail] = useState('');

  const [codeSent, setCodeSent] = useState(false);
  const errors = useErrors(error?.response);

  const auth = useSelector((state: RootState) => state.auth);
  const isAuthenticated = Boolean(auth.token && auth.expiration > Date.now());

  const onButtonClick = async () => {
    try {
      const response = await mutate({
        email,
      });

      if (response.data.success) {
        setCodeSent(true);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const handleInputChange = (
    setter: (value: string) => void,
  ) => (e: FormEvent<HTMLInputElement>) => {
    setter(e.currentTarget.value);
  };

  const logoutAction = () => {
    persistor.purge()
      .then(() => {
        window.location.reload();
      });
  };

  if(isAuthenticated) logoutAction();

  const buttonDisabled = !email;
  const resentEmailMessage = (
                                  <EmailSendMessage>
                                    Reset link has been sent to your email.
                                  </EmailSendMessage>
                                );
  return (
    <Page>
      <Header forceBlackLogo />
      <Container>
        <Row>
          <Col sm={12} md={8} lg={6} offset={{ md: 2, lg: 3 }}>
            <H1 align="center">Forgot your password?</H1>
            <Spacer top={15}>
              <P size={20} align="center">We’ll help you reset it and get back on track.</P>
            </Spacer>
            <Spacer top={35}>
              <Input
                error={errors.email}
                placeholder="E-mail"
                onChange={handleInputChange(setEmail)}
                icon={emailIcon}
              />
              {(codeSent) ? resentEmailMessage : ''}
            </Spacer>
            <Spacer top={45} bottom={45}>
              <Row>
                <Col sm={8} offset={{ sm: 2 }}>
                  <Button
                    loading={status === 'loading'}
                    disabled={buttonDisabled}
                    onClick={codeSent ? undefined : onButtonClick}
                  >
                    {codeSent ? 'Email Sent' : 'Reset Password'}
                  </Button>
                </Col>
              </Row>
            </Spacer>
            <P align="center" size={22}>
              <TextLink to="/login">Go Back</TextLink>
            </P>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

export default Main;
