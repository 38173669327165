import { AxiosPromise } from 'axios';
import client from '../core';
import { Question } from '../../typings/questions';

const getQuizQuestions = (): AxiosPromise<Question[]> => client.get('quiz');

const getQuizResults = (): AxiosPromise<any> => client.get('quiz/results');

const saveQuizResults = (data: Record<string, string | string[] | boolean>): AxiosPromise<any> => client.post('quiz', data);

export default {
  getQuizQuestions,
  getQuizResults,
  saveQuizResults,
};
