import { AxiosPromise } from 'axios';
import client from '../core';
import { User } from '../../typings/api';

export interface LoginData {
  email: string;
  password: string;
}

const login = (data: LoginData): AxiosPromise<any> => client.post('auth/login', data);

export interface RegisterData {
  firstName: string;
  middleInitial: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeat: string;
}

const register = (data: RegisterData): AxiosPromise<any> => client.post('auth/register', data);

export interface CheckEmailData {
  email: string;
}

const checkEmail = (data: RegisterData): AxiosPromise<any> => client.post('auth/check-email', data);

export interface ResetPasswordData {
  email: string;
}

const resetPassword = (data: ResetPasswordData): AxiosPromise<any> => client.post('auth/reset-password', data);

export interface SetPasswordData {
  token: string;
  password: string;
  passwordRepeat: string;
}
export interface contactUsData {
  userEmail: string;
  userMessage: string;
  userName: string;
}
export interface UserFeedbackData {
  userId : string ;
  comment: string ;
  url: string ;
  status: boolean ;
  module : string;
  useremail: string;
}

const setPassword = (data: SetPasswordData): AxiosPromise<any> => client.post('auth/set-password', data);

const sendUserFeedback = (data: UserFeedbackData): AxiosPromise<any> => client.post('userfeedback', data);

const getProfile = (): AxiosPromise<User> => client.get('auth/profile');

const verifyEmail = (token: string): AxiosPromise<any> => client.put('auth/verify', { token });

const updateSubscription = (subscription: PushSubscription): AxiosPromise => client.put('auth/subscription', { subscription });

const contactUsSave = (data: contactUsData): AxiosPromise<any> => client.post('contactus/save', data);

export default {
  login,
  register,
  checkEmail,
  resetPassword,
  setPassword,
  getProfile,
  verifyEmail,
  updateSubscription,
  sendUserFeedback,
  contactUsSave,
};
