import { Button, Snackbar } from "@mui/material";
import React, { useState } from "react";
import feedbackIcon from "../../assets/images/share-feedback.svg";
import SuccessLogo from "../../assets/images/feedback-success.svg";
import Slide from '@mui/material/Slide';
import "./style.css";
import ButtonComponent from "../Button";
import { useMutation } from 'react-query';
import { UserFeedbackData } from "../../api/auth" ;
import { ErrorResponse, Response } from '../../typings/response';
import api from '../../api';


const Feedback = ({profileQuery, show}) => {
    const [showFeedBackBtn, setShowFeedBackBtn] = useState(true);
    const [showFeedBackForm, setShowFeedBackForm] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const [text, setText] = useState("");
    const [status, setStatus] = useState("initial");
    let isLoading = status === "loading";
    const [userFeedbackAPI, { error,  }] = useMutation<
                                                        Response<{ code : number, status : boolean }>,
                                                        UserFeedbackData,
                                                        ErrorResponse
                                                        >(api.auth.sendUserFeedback);

    const handleFeedback = (e) => {
        setCharCount(e.target.value.length)
        setText(e.target.value);
    }

    const handleClose = () => {
        setShowFeedBackForm(false);
        setShowFeedBackBtn(true);
        setCharCount(0);
        setText("");
        setStatus("initial");
    }
   
    const saveFeedback = async () => {
        try {
           setStatus("loading");

           const currLocation = window.location.href.split('/');
           const module = currLocation[currLocation.length - 1];     

           const formData = {
                userId : profileQuery.data.data._id,
                comment: text,
                url: window.location.href,
                status: true,   
                module,
                useremail: profileQuery.data.data.email
            }

          let res = await userFeedbackAPI(formData);
          res.status? setStatus("success") : setStatus("failed");
    
        //   let response = await res.json();
    
        //   console.log("Saving message success", profileQuery.data);
        } catch (e) {
          console.warn("Error in saving the message", e);
          setStatus("failed");
        }
      }



    return show === true ? ( <>
            {showFeedBackBtn ? 
                <div className="feedback-btn" onClick={() => {setShowFeedBackBtn(false); setShowFeedBackForm(true)}}>  
                    <img src={feedbackIcon}  />
                    <div>Share feedback</div>
                </div>
            : showFeedBackForm ?
                <div className="feedback-form">
                    {status === "success" ?
                    <>
                        <div className="cross-success" onClick={() => {handleClose()}}>
                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.4603 0.538442C13.7577 0.835815 13.7577 1.31795 13.4603 1.61533L1.61462 13.461C1.31725 13.7584 0.835108 13.7584 0.537736 13.461C0.240363 13.1637 0.240364 12.6815 0.537737 12.3842L12.3834 0.538442C12.6808 0.241069 13.1629 0.241069 13.4603 0.538442Z" fill="black"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M0.539684 0.538442C0.837057 0.241069 1.31919 0.241069 1.61657 0.538442L13.4623 12.3842C13.7596 12.6815 13.7596 13.1637 13.4623 13.461C13.1649 13.7584 12.6828 13.7584 12.3854 13.461L0.539684 1.61533C0.242311 1.31795 0.242311 0.835815 0.539684 0.538442Z" fill="black"/>
                            </svg>
                        </div>  
                            
                        <div className="submit-success">

                            <div className="success-logo">
                                <img src={SuccessLogo} />
                            </div>

                            <div className="success-message"> Thanks for your feedback </div>

                            <div className="done" onClick={()=>{handleClose()}}>Done</div>
                        </div>
                    </>
                    :status === "initial" || "loading" ?    
                        <div>
                        <div className="report-feedback">
                            <div className="text" style={{fontFamily: "Lato"}}>Report Feedback</div>
                            <div className="cross" onClick={() => {handleClose()}}>
                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.4603 0.538442C13.7577 0.835815 13.7577 1.31795 13.4603 1.61533L1.61462 13.461C1.31725 13.7584 0.835108 13.7584 0.537736 13.461C0.240363 13.1637 0.240364 12.6815 0.537737 12.3842L12.3834 0.538442C12.6808 0.241069 13.1629 0.241069 13.4603 0.538442Z" fill="black"/>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.539684 0.538442C0.837057 0.241069 1.31919 0.241069 1.61657 0.538442L13.4623 12.3842C13.7596 12.6815 13.7596 13.1637 13.4623 13.461C13.1649 13.7584 12.6828 13.7584 12.3854 13.461L0.539684 1.61533C0.242311 1.31795 0.242311 0.835815 0.539684 0.538442Z" fill="black"/>
                                </svg>
                            </div>  
                        </div>

                        <div className="text-box">
                            <textarea className="input" placeholder="You can type you feedback here..." onChange={handleFeedback} value={text}/>
                        </div>

                        <div className="char-count" >{charCount}/1000</div>

                        <ButtonComponent small={true} disabled={ charCount < 1} loading={isLoading} onClick={saveFeedback}><div className="feedback-form-submit">SUBMIT</div></ButtonComponent>
                        </div>
                    : status === "failed" ?
                        <div style={{width: "fitContent", margin: "auto", marginTop: "250px"}}>
                            Failed to report feedback. Please try again.
                        </div>
                    : null
                    }
                </div>
            : null
            }
        </>
    ) : null;
}

export default Feedback