import React from 'react';
import styled from 'styled-components';
import SyncLoader from 'react-spinners/SyncLoader';

import { colors } from '../../config/styles';

interface Props {
  size?: number;
  outlined?: boolean;
  disabled?: boolean;
  small?: boolean;
  secondary?: boolean;
  loading?: boolean;
  onClick?: (e) => void;
}

const Button = styled.button<Props>`
  transition:
    background-color .3s ease-in-out,
    border-color .3s ease-in-out,
    transform .1s,
    color .3s,
    opacity .3s;
  height: ${({ small }) => (small ? '44px' : '84px')};
  width: ${({ small }) => (small ? '180px' : '100%')};
  background-color: ${({ outlined, secondary }) => (
    (secondary && !outlined && colors.placeholder)
    || (outlined && colors.white)
    || colors.mainGreen
  )};
  border-radius: 42px;
  border:
    ${({ small }) => (small ? '1px' : '3px')}
    solid
    ${({ secondary }) => (secondary ? colors.placeholder : colors.mainGreen)};
  font-size: ${({ size }) => `${size || 20}px`};
  font-family: Roboto;
  font-weight: 500;
  
  ${({ disabled }) => (disabled ? `
    opacity: 0.1;
    pointer-events: none;
  ` : '')}

  color: ${({ outlined, secondary }) => (
    (secondary && colors.text)
    || (!outlined && colors.white)
    || colors.mainGreen
  )};
  
  &:hover {
    cursor: pointer;
    background-color: ${colors.mainGreenDark};
    border-color: ${colors.mainGreenDark};
    color: ${colors.white};
  }
  
  &:active {
    transform: scale(0.99);
  }
`;

const ButtonComponent: React.FunctionComponent<Props> = ({ loading, children, ...rest }) => (
  <Button {...rest}>
    {
      loading
        ? <SyncLoader size={10} color={colors.white} />
        : children
    }
  </Button>
);

export default ButtonComponent;
