import React from "react";
import { Link, useHistory } from "react-router-dom";
import Header from '../../container/LandingPage/header';
const pages = ['Home', 'About Us', 'Solutions','Insights','Contact','Partner with us'];

const HeaderV2 = () => {
  const history = useHistory();
  const executeScroll = (sectionName: string) => {

    var element = document.getElementById('homeSection');
    history.push('/');
    if(sectionName== "Home"){
      element = document.getElementById('homeSection');
    }else if(sectionName== "About Us"){
      element = document.getElementById('aboutusSection');
    }else if(sectionName== "Solutions"){
      element = document.getElementById('serviceSection');
      
    }else if(sectionName== "Insights"){
      element = document.getElementById('insightsSection');
      
    }else if(sectionName== "Contact"){
      element = document.getElementById('contactusSection');
      
    }else if(sectionName== "Partner with us"){
      element = document.getElementById('contactusSection');
      
    }else{
      element = document.getElementById('homeSection');
      
    }
    if(element != null){
      var headerOffset = 45;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
      
    }
    const timer = setTimeout(() => {
      var element = document.getElementById('homeSection');
      history.push('/');
      if(sectionName== "Home"){
        element = document.getElementById('homeSection');
      }else if(sectionName== "About Us"){
        element = document.getElementById('aboutusSection');
      }else if(sectionName== "Solutions"){
        element = document.getElementById('serviceSection');
        
      }else if(sectionName== "Insights"){
        element = document.getElementById('insightsSection');
        
      }else if(sectionName== "Contact"){
        element = document.getElementById('contactusSection');
        
      }else if(sectionName== "Partner with us"){
        element = document.getElementById('contactusSection');
        
      }else{
        element = document.getElementById('homeSection');
        
      }
      if(element != null){
        var headerOffset = 45;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
        
      }
    }, 1000);

  }

  return (
  <>
    <Header executeScroll={executeScroll} 
            pages={pages}
                />
    
  </>
  );
};

export default HeaderV2;
