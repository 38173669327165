import styled from 'styled-components';

interface Props {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const Spacer = styled.div<Props>`
  margin-top: ${({ top }) => top || 0}px;
  margin-bottom: ${({ bottom }) => bottom || 0}px;
  margin-left: ${({ left }) => left || 0}px;
  margin-right: ${({ right }) => right || 0}px;
`;

export default Spacer;
