import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import Game from './components/Game';
import GameInstructions from './components/GameInstructions';
import GameEnd from './components/GameEnd';
import './style.css';
import sequence from './sequence.json';
import Loader from '../Loader';
import Modal from '../../components/Modal';
import { H1, P } from '../../components/Typography';
import ButtonComponent from '../../components/Button';
import ButtonMaterial from '@mui/material/Button';
import Spacer from '../../components/Spacer';
import Flasher from './components/Flasher';
import api from '../../api';
import browserConsentWindow from '../../assets/images/niji-browser-consent.png';
import nijiLogo from '../../assets/images/Logo.png';

const GOOGLE_API = 'https://maps.googleapis.com/maps/api/geocode/json';
const GOOGLE_API_KEY = 'AIzaSyCfFvZfR7TFcBC5WuglWQgb4KOhdEez_4c';

const gameConfig = {
  // gameColor: 'blue', // Background color
  // feedbackColor: 'yellow', // Box color
  roundsAmount: sequence.length,
  // triesAmount: 2,
  timeToRespond: 2000,
  // timeToRespond: 0,
  timeBetweenRound: 800, // Time between responses
  boxesAmount: 4, // Number of boxes
  letterHint: false,
  possibleKeys: ['C', 'V', 'N', 'M'],
  // letterHint: true, // If the letter is shown on the box
  letterPerBox: sequence,
};

const userHasCamera = async () => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some((device) => device.kind === 'videoinput');
  } catch (_) {
    return false;
  }
};

enum CameraStatuses {
  Loading,
  Loaded,
  Ready,
  Error,
  NoDevices,
}

const MainGame = () => {
  const [recorder, setRecorder] = useState();
  const [gameStarted, setGameStarted] = useState(false);
  const [gameEnded, setGameEnded] = useState(false);
  const [gameResults, setGameResults] = useState([]);
  const [practiceData, setPracticeResults] = useState([]);
  const [cameraStatus, setCameraStatus] = useState(CameraStatuses.Loading);
  const [video, setVideo] = useState();
  const [screenFlashed, setScreenFlashed] = useState(false);
  const [hasPassedGame, setHasPassedGames] = useState(false);
  const [instructionsModalVisible, setInstructionsModalVisible] = useState(false);
  const [consentModalVisible, setConsentModalVisible] = useState(false);
  const [videoConsent, setVideoConsent] = useState(false);
  const [locationConsent, setLocationConsent] = useState(false);
  const [location, setLocation] = useState({
    country: null,
    state: null,
    zipCode: null,
    latitude: null,
    longitude: null,
  });
  const [showConsentInstructions, setShowConsentInstructions] = useState(false);
  const passedGamesCount = useQuery('passedGamesCount', api.game.getPassedGamesCount);
  const profileQuery = useQuery('profile', api.auth.getProfile, { manual: true });
  const consentTimeout = React.useRef<any>();

  const initLocationTracking = () => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const { latitude, longitude } = coords || {};
      if (latitude && longitude) {
        setLocation({
          ...location,
          latitude,
          longitude,
        });
        axios.get(
          `${GOOGLE_API}?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`,
        ).then((res) => {
          const addressParams = res?.data?.results?.[0]?.address_components;

          if (addressParams?.length) {
            const state = addressParams?.find((val) => val.types.includes('administrative_area_level_1')
            )?.long_name;
            const country = addressParams?.find((val) => val.types.includes('country')
            )?.long_name;
            const zipCode = addressParams?.find((val) => val.types.includes('postal_code')
            )?.long_name;

            const address = {
              ...location,
              country,
              state,
              zipCode,
            };
            setLocation(address);
          }
        }).catch((err) => {
          console.log('Error getting Address', err);
        });
      }
    }, (error) => {
      console.log(error);
    });
  };

  const initCamera = async () => {
    try {
      if (await userHasCamera()) {
        setCameraStatus(CameraStatuses.Loaded);
      } else {
        setCameraStatus(CameraStatuses.NoDevices);
      }
    } catch (error) {
      setCameraStatus(CameraStatuses.NoDevices);
    }
  };

  const initVideoRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // @ts-ignore
      setRecorder(new RecordRTCPromisesHandler(stream, { type: 'video' }));
      setCameraStatus(CameraStatuses.Ready);
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        setCameraStatus(CameraStatuses.Error);
      }
    }
  };

  const showConsentModal = () => {
    setConsentModalVisible(true);
  };

  const clearConsentTimeout = () => {
    clearTimeout(consentTimeout.current);
  };

  const consentInstructionsDoneHandle = () => {
    setConsentModalVisible(false);
    clearConsentTimeout();
    //start the game: 
    startGame();
  };

  const startConsentTimeout = () => {
    consentTimeout.current = setTimeout(() => {
      consentInstructionsDoneHandle();
    }, 180000); // 180000 = 3 minutes
  };

  const consentNextHandle = () => {
    if (videoConsent) {
      initVideoRecording();
    }
    if (locationConsent) {
      initLocationTracking();
    }
    if (videoConsent || locationConsent) {
      setShowConsentInstructions(true);
      startConsentTimeout();
    } else {
      setConsentModalVisible(false);
    }
  };

  const consentNextHandleV2 = () => {
    if (videoConsent) {
      initVideoRecording();
    }
    if (locationConsent) {
      initLocationTracking();
    }
    if (videoConsent || locationConsent) {
      setShowConsentInstructions(true);
      startConsentTimeout();
    } else {
      startGame();
      setConsentModalVisible(false);
    }
  };

  const flashScreen = () => {
    setScreenFlashed(true);
  };

  const resetTest = () => {
    window.location.reload();
  };

  const startGame = () => {
    setGameStarted(true);
    if (cameraStatus === CameraStatuses.Ready && recorder) {
      // @ts-ignore
      recorder?.startRecording();
    }
  };

  const endGame = async (results) => {
    setGameResults(results);
    setGameStarted(false);
    setGameEnded(true);
    if (cameraStatus === CameraStatuses.Ready && recorder) {
      // @ts-ignore
      await recorder.stopRecording();
      // @ts-ignore
      setVideo(await recorder.getBlob());
    } else {
      setVideo(null);
    }
  };

  const skipInstructions = () => {
    setInstructionsModalVisible(false);
    startGame();
  };

  useEffect(() => {
    // showConsentModal();
    initCamera();
    // initLocationTracking();
    consentNextHandle();
    // startGame();
  }, []);

  useEffect(() => {
    if (passedGamesCount.status === 'success' && passedGamesCount.data.data.count > 0) {
      setHasPassedGames(true);
      setInstructionsModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedGamesCount.status]);

  if (cameraStatus === CameraStatuses.Loading || passedGamesCount.status === 'loading') {
    return <Loader image={nijiLogo} />;
  }

  if (gameEnded) {
    return (
      <GameEnd
        gameConfig={gameConfig}
        resetTest={resetTest}
        results={gameResults}
        practiceData={practiceData}
        video={video}
        location={location}
      />
    );
  }
  if(!profileQuery.data.data.isActive){
    return <Redirect to="/dashboard" />;
  }
  if (passedGamesCount.status === 'success' && passedGamesCount.data.data.count >= 2) {
    return <Redirect to="/dashboard" />;
  }

  if (gameStarted) {
    return (
      <>
        <Game
          gameConfig={gameConfig}
          endGame={endGame}
          flashScreen={flashScreen}
        />
        <Flasher
          messageDurationSeconds={1}
          screenFlashed={screenFlashed}
          setScreenFlashed={setScreenFlashed}
        />
      </>
    );
  }

  return (
    <>
      <Modal visible={consentModalVisible}>
        {!showConsentInstructions && (
          <>
            <H1>Video and location permission</H1>
            <Spacer top={20} />
            <P>
              Having video and location will give us important information about your performance.
              Please select from the options below.
            </P>
            <Spacer top={30} />
            <div className="consent-container">
              <input type="checkbox" id="video-consent" name="video-consent" checked={videoConsent} onChange={() => setVideoConsent(!videoConsent)} />
              <label htmlFor="video-consent">
                <P>
                  Allow access to your
                  {' '}
                  <strong>camera.</strong>
                </P>
              </label>
            </div>
            <div className="consent-container">
              <input type="checkbox" id="location-consent" name="location-consent" checked={locationConsent} onChange={() => setLocationConsent(!locationConsent)} />
              <label htmlFor="location-consent">
                <P>
                  Allow access to your
                  {' '}
                  <strong>location.</strong>
                </P>
              </label>
            </div>
            <Spacer top={10} />
            <div className="consent-button-wrapper">
              <ButtonComponent small onClick={consentNextHandleV2}>Next</ButtonComponent>
            </div>
          </>
        )}
        {showConsentInstructions && (
          <>
            <H1>Acceptance instructions</H1>
            <Spacer top={20} />
            <P>
              Please follow the directions that appear to enable camera and/or location tracking.
            </P>
            <Spacer top={20} />
            <div className="consent-image-wrapper">
              <img src={browserConsentWindow} alt="Consent instructions" />
            </div>
            <Spacer top={20} />
            <div className="consent-button-wrapper">
              <ButtonComponent small onClick={consentInstructionsDoneHandle}>Done</ButtonComponent>
            </div>
          </>
        )}
      </Modal>
      {(hasPassedGame && !consentModalVisible) && (
        <Modal visible={instructionsModalVisible}>
          <H1>View instructions?</H1>
          <Spacer top={10} />
          <P>Would you like to view the instructions again?</P>
          <Spacer top={30} />
          <ButtonMaterial variant="contained"  size="large"  onClick={skipInstructions} style={{borderRadius :"4rem" , backgroundColor :"#8890BB" , padding: "0.5rem 4rem"}}>No</ButtonMaterial>
          <ButtonMaterial variant="contained"  size="large"  onClick={() => setInstructionsModalVisible(false)} style={{ marginLeft :"3rem", borderRadius :"4rem" ,backgroundColor :"#82D8A5" , padding: "0.5rem 4rem"}}>
            Yes
          </ButtonMaterial>
        </Modal>
      )}
      <GameInstructions
        gameConfig={gameConfig}
        startGame={startGame}
        setPracticeResults={setPracticeResults}
        flashScreen={()=>{setScreenFlashed(true);}}
        showConsentModal={showConsentModal}
      />
      <Flasher
        messageDurationSeconds={1}
        screenFlashed={screenFlashed}
        setScreenFlashed={setScreenFlashed}
      />
    </>
  );
};

export default MainGame;
