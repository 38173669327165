import React from 'react';
import './style.css';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import BrainOrangeColor from '../../assets/images/landingpage/brainImageing.svg';
import InsightsBackground from '../../assets/images/landingpage/insights_background.svg';
import EducationAlzheimer from '../../assets/images/landingpage/Education - Alzheimer.svg';
import EducationDigitalHealth from '../../assets/images/landingpage/Education - Digital Health.svg';
import EducationCerebrovascular from '../../assets/images/landingpage/Education - Cerebrovascular.svg';
import EducationTechnology from '../../assets/images/landingpage/Education - Technology.svg';
import ArrowLeft from '../../assets/images/landingpage/ArrowLeft.svg';
import InsightsText from '../../assets/images/landingpage/INSIGHTS.svg';
import LogoForInsights from '../../assets/images/landingpage/Education - Title Graphic.svg';
import titleImg from '../../assets/images/landingpage/AboutUs-title-design.svg';

import PopupDetails from './popupForDetails';

const pages = ['Home', 'About Us', 'Service','Insights','Contact','Partner with us'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Insights=()=>{
    const [open, setOpen] = React.useState(false);
    const [selectedSection, setSelectedSection] = React.useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dataForPopup = {
      "alzheimer"  : {
                    "data" :  'Alzheimer’s disease is a condition that causes the cells in the brain to die (referred to as ‘neurodegeneration’) resulting in problems with memory and other forms of thinking and behaviors. In the later stages of disease, the patient loses their ability to function independently, resulting in significant challenges for the patient and their family (a condition referred to as ‘dementia’). Dementia is not the simple result of ‘getting old’; it is a disorder that most commonly occurs in individuals that have certain ‘risk factors’, such as specific risk genes. Although the memory problems due to Alzheimer’s disease typically begin in adults 60 years and older, brain changes begin anywhere from 10 to over 30 years before a diagnosis of Alzheimer’s disease is made! There is currently no cure for Alzheimer’s disease. However, early detection of neurodegeneration will be key for any future successful therapy.',
                    "heading" : "What is Alzheimer’s disease?",
                    "imageUrl" : EducationAlzheimer
                  },
      "cerebrovascular": {
        "data" : 'Cerebrovascular disease refers to several conditions that result in disruption of the blood supply to the brain. The brain requires more energy than any other organ in the body, and this energy comes in the form of chemicals in blood. When the blood supply is disrupted, brain tissue is vulnerable to damage due to lack of oxygen and other nutrients. When the blood supply to the brain is significantly disrupted, for example, due to blockage of the blood vessels that penetrate the brain, a major event such as a stroke can occur. ‘Stroke’ refers to disruption or damage to brain tissue due to lack of blood supply. There are often no obvious ‘warning signs’ prior to a cerebrovascular event. However, even prior to an overt stroke, there is cumulative damage to the smallest blood vessels in the brain. This damage promotes more subtle disruptions in brain function that negatively impact daily thinking and detection of such changes can be important in preventing a future stroke.',
        "heading" : "What is cerebrovascular disease?",
        "imageUrl" : BrainOrangeColor
      },
      "digitalHealth" : {
        "data" : 'Digital health refers to a range of technologies that employ computer assisted procedures to facilitate healthcare and other medically related operations. Digital health technologies can range from online testing of thinking to health monitors (for example, pulse sensors), to medical imaging software. The use of powerful computer processing and artificial intelligence allows the detailed analysis of these different health measures to help in making a medical diagnosis, or to determine if an individual is at risk for a future event, such as dementia or stroke, so that medical intervention can be performed before the negative event occurs.',
        "heading" : "What is digital health?",
        "imageUrl" : EducationDigitalHealth
      },
      "brainImaging" :{
        "data" : "Brain imaging (also known as ‘neuroimaging’) refers to a range of technologies that allow clinicians and scientists to see ‘pictures’ of the living brain. These pictures can represent different aspects of brain structure (for example, the amount of tissue or the wires connecting different parts of the brain), as well as ‘movies’ of brain function and activity over time (such as the degree that two areas of the brain are ‘talking’ or sending signals back and forth to each other). In a hospital setting, brain imaging is typically used for ‘qualitative’ evaluation. That is, the doctor examines the image visually and this examination allows the doctor to detect major changes such as a tumor or large stroke. However, advanced image analysis can quantify much more subtle aspects of brain health and degeneration and can be used to detect brain changes as a ‘warning sign’ to better inform clinical care.",
        "heading" : "What is brain imaging?",
        "imageUrl" : EducationCerebrovascular
      },
      "findaCure" : {
        "data" : "A goal of current clinical trial research testing new therapies for Alzheimer’s disease and cerebrovascular disease is to prevent the development of symptoms in individuals with very early signs of disease. Neuroscience technology can help to detect individuals exhibiting the earliest disease-related changes in the brain and/or changes in behavior. These individuals, considered to be ‘at risk’, can then be selected to participate in research to test new pharmaceuticals, devices, lifestyle changes, and other interventions that will one day lead to the prevention of these major life altering conditions.",
        "heading" :"How does new technology help us find a cure?",
        "imageUrl" : EducationTechnology
      }
      
    };

    return <div className="insightsBackground" style={{ backgroundColor : "rgb(241 243 249)", padding : "2rem 0rem 5rem"}}>
                <PopupDetails open={open} 
                              setOpen={setOpen} 
                              handleClose={handleClose}
                              dataForPopup={dataForPopup}
                              selectedSection={selectedSection}
                               />
               <Container maxWidth="xl">  
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="aboutUs-d-flex">
                          <img src={titleImg}/>
                          <div className="aboutUs-title">INSIGHTS</div>
                      </div>
                    </Grid>
                  </Grid>

              <Container maxWidth="lg" className='insights-spacing' >     
                <Grid container  spacing={2}>

                <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345 }} className="alzheimerDiseaseSection">
                      
                      <CardMedia
                        className="cartImageSection"
                        component="img"
                        height="50"
                        width="auto"
                        image={EducationAlzheimer}
                        alt="Paella dish"
                        sx={{ width: "auto" }}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          <div className="headingForCard">What is Alzheimer’s disease?</div>
                          <div className="descriptionForCard">
                         Alzheimer’s disease is a condition that causes the cells in the brain to die (referred to as ‘neurodegeneration’)...
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing className="readMoretext" onClick={()=>{handleOpen(); setSelectedSection("alzheimer") }} >
                        <div className='read-icons'>
                          Read More
                          <img className='arrow-spacing' src={ArrowLeft}/>
                        </div>
                        
                        
                      </CardActions>
                      
                    </Card>
                  </Grid>



                <Grid item xs={4} >
                    <Card sx={{ maxWidth: 345 }} className="brainImageing">
                      
                      <CardMedia
                        className="cartImageSection"
                        component="img"
                        height="50"
                        width="auto"
                        image={BrainOrangeColor}
                        alt="Paella dish"
                        sx={{ width: "auto" }}
                      />
                      <CardContent className="text-padding">
                        <Typography variant="body2" color="text.secondary">
                          <div className="headingForCard">What is cerebrovascular disease?</div>
                          <div className="descriptionForCard">
                          Cerebrovascular disease refers to several conditions that result in disruption of the blood supply...
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing className="readMoretext" onClick={()=>{handleOpen(); setSelectedSection("cerebrovascular") }}>
                      <div className='read-icons'>
                          Read More
                          <img className='arrow-spacing' src={ArrowLeft}/>
                        </div>
                        
                      </CardActions>
                      
                    </Card>
                  </Grid>
               

                  <Grid item xs={4}>
                    <Card sx={{ maxWidth: 345 }} className="digitalHealthSection">
                      
                      <CardMedia
                        className="cartImageSection"
                        component="img"
                        height="50"
                        width="auto"
                        image={EducationDigitalHealth}
                        alt="Paella dish"
                        sx={{ width: "auto" }}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          <div className="headingForCard">What is digital health?</div>
                          <div className="descriptionForCard">
                          Digital health refers to a range of technologies that employ computer assisted... 
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing className="readMoretext" onClick={()=>{handleOpen(); setSelectedSection("digitalHealth") }}>
                      <div className='read-icons'>
                          Read More
                          <img className='arrow-spacing' src={ArrowLeft}/>
                        </div>
                      </CardActions>
                      
                    </Card>
                  </Grid>

                  <Grid item xs={6}>
                    <Card className="cerebrovascularSection">
                      
                      <CardMedia
                        className="cartImageSection"
                        component="img"
                        height="50"
                        width="auto"
                        image={EducationCerebrovascular}
                        alt="Paella dish"
                        sx={{ width: "auto" }}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          <div className="headingForCard">What is brain imaging?</div>
                          <div className="descriptionForCard">
                          Brain imaging (also known as ‘neuroimaging’) refers to a range of technologies that allow clinicians and scientists to see ‘pictures’ of the living brain.
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing className="readMoretext" onClick={()=>{handleOpen(); setSelectedSection("brainImaging") }}>
                      <div className='read-icons'>
                          Read More
                          <img  className='arrow-spacing' src={ArrowLeft}/>
                        </div>
                        
                      </CardActions>
                      
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className="technologyCureSection">
                      
                      <CardMedia
                        className="cartImageSection"
                        component="img"
                        height="50"
                        width="auto"
                        image={EducationTechnology}
                        alt="Paella dish"
                        sx={{ width: "auto" }}
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          <div className="headingForCard">How does new technology help us find a cure?</div>
                          <div className="descriptionForCard">
                          A goal of current clinical trial research testing new therapies for Alzheimer’s disease and cerebrovascular disease is to prevent the development ...
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions disableSpacing className="readMoretext" onClick={()=>{handleOpen(); setSelectedSection("findaCure") }}>
                      <div className='read-icons'>
                          Read More
                          <img className='arrow-spacing' src={ArrowLeft}/>
                        </div>
                        
                      </CardActions>
                      
                    </Card>
                  </Grid>
                  
                  
                </Grid>
              </Container>
              </Container>
          </div> 
}
export default Insights;