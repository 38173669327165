import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

import whiteLogo from '../../assets/images/logo_v2.svg';
import blackLogo from '../../assets/icons/logo-black.svg';
import { P } from '../Typography';
import Button from '../Button';
import { SIZES, smallerThan, useWidth } from '../../utils/grid';

const HeaderContainer = styled.div`
  width: calc(100vw - 120px);
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px;
  
  ${smallerThan('sm')} {
    padding: 60px 30px;
    width: calc(100vw - 60px);
  }
  
  ${smallerThan('xs')} {
    padding: 60px 15px;
    width: calc(100vw - 30px);
  }
`;

const HeaderContainerForBack = styled.div`
  width: calc(100vw - 120px);
  display: flex;
  align-items: left;
  justify-content: space-between;
  padding-left: 60px;
  position: absolute;
  
  ${smallerThan('sm')} {
    padding: 60px 30px;
    width: calc(100vw - 60px);
  }
  
  ${smallerThan('xs')} {
    padding: 60px 15px;
    width: calc(100vw - 30px);
  }
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img``;

const ExtendedP = styled(P)`
  margin-right: 25px;
  white-space: nowrap;
  
  ${smallerThan('sm')} {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  
  .back-icon {
    font-size: 2.5rem;
    color: #fff;
    margin-right: 10px;
  }
`;

interface Props {
  mode?: 'signin' | 'signup';
  forceBlackLogo?: boolean;
  forceWhiteLogo?: boolean;
  showBackButton?: boolean;
}

const Header: React.FunctionComponent<Props> = ({
  mode,
  forceBlackLogo,
  forceWhiteLogo,
  showBackButton = false,
}) => {
  const windowWidth = useWidth();

  return (
    <>
    <HeaderContainer>
    <a href={process.env.REACT_APP_APPLICATION_URL}>
        <LogoWrapper>
          
          <Logo src={(windowWidth <= SIZES.md || forceBlackLogo) && !forceWhiteLogo ? blackLogo : whiteLogo} alt="Nijineuro" />
        </LogoWrapper>
      </a>
      
      <HeaderActions>
        {mode === 'signin' && (
          <>
            <ExtendedP size={19}>Already have an account?</ExtendedP>
            <Link to="/login"><Button small secondary outlined size={25}>Sign In</Button></Link>
          </>
        )}
        {mode === 'signup' && (
          <>
            <ExtendedP size={19}>Don’t have an account?</ExtendedP>
            <Link to="/signup"><Button small secondary outlined size={19}>Sign Up NOW!</Button></Link>
          </>
        )}
      </HeaderActions>
    </HeaderContainer>
    <HeaderContainerForBack>
        <LogoWrapper>{showBackButton && <FaArrowLeft className="back-icon" />}
        </LogoWrapper>
    </HeaderContainerForBack>
    </>
  );
};

export default Header;
