import { Response } from '../typings/response';
import { Error } from '../typings/errors';

export default (response?: Response<{ [key: string]: unknown }>): { [key: string]: string } => {
  let mappedErrors = {};

  if (!response?.data.errors) return {};

  const errors = response.data.errors as Error[];

  errors.forEach((error) => {
    Object.keys(error).forEach((fieldName) => {
      mappedErrors = {
        ...mappedErrors,
        [fieldName]: Object.values(error[fieldName])[0],
      };
    });
  });

  return mappedErrors;
};
