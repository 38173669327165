import * as Types from './actionTypes';
import { User } from '../../typings/api';
import { formatUserName } from '../../utils/formatUserName';

const saveUser = (user: User): Types.SaveUserAction => ({
  type: Types.SAVE_USER,
  payload: {
    // eslint-disable-next-line no-underscore-dangle
    id: user._id,
    // @ts-ignore
    name: formatUserName(user),
    email: user.email,
    passedQuiz: user.passedQuiz,
  },
});

const removeUser = (): Types.RemoveUserAction => ({
  type: Types.REMOVE_USER,
});

const setQuizPassed = (value: boolean): Types.SetQuizPassedActionAction => ({
  type: Types.SET_QUIZ_PASSED,
  payload: value,
});

export default {
  saveUser,
  removeUser,
  setQuizPassed,
};
